export enum OrganizationPricingTabType {
  mappings = 'mappings',
  energyCommunity = 'energyCommunity',
  ppwModifiers = 'ppwModifiers',
  holdbacks = 'holdbacks',
}

export const organizationPricingTabMap: { [Key in OrganizationPricingTabType]?: string } = {
  [OrganizationPricingTabType.mappings]: 'Mappings',
  [OrganizationPricingTabType.energyCommunity]: 'Energy Community',
  [OrganizationPricingTabType.ppwModifiers]: 'PPW Modifiers',
  [OrganizationPricingTabType.holdbacks]: 'Holdbacks',
} as const;
