import { MarketSchema } from '../components/Markets/AddMarketModal';
import { api } from './api';

export const utilitiesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getUtilities: build.query<any[], Record<any, any>>({
      query: (queryParams) => ({
        url: `/utilities${
          queryParams &&
          '?' +
            new URLSearchParams({
              ...queryParams,
            })
        }`,
      }),
      providesTags: () => [{ type: 'Utilities', id: 'LIST' }],
    }),
    createUtility: build.mutation<any, { marketData: MarketSchema & { status: 'active' } }>({
      query: ({ marketData }) => ({ url: `/utilities`, method: 'POST', body: marketData }),
      invalidatesTags: () => [{ type: 'Utilities', id: 'LIST' }],
    }),
  }),
});

export const { useGetUtilitiesQuery, useLazyGetUtilitiesQuery, useCreateUtilityMutation } = utilitiesApi;
