import { ObjectId } from 'mongodb';

export enum PanelOrientation {
  landscape = 'landscape',
  portrait = 'portrait',
  combination = 'combination',
}

export enum MountingType {
  ground = 'ground',
  roof = 'roof',
  combination = 'combination',
}

export interface MonthlyProduction {
  month: number;
  productionKwh: number;
}

export interface MonthlySolarAccess {
  month: number;
  solarAccess: number;
}

export interface SystemAsset {
  manufacturer: string;
  model: string;
  count: number;
}

export interface SystemArray {
  panelManufacturer: string;
  panelModel: string;
  panelCount: number;
  panelWattage: number;
  orientation: PanelOrientation;
  tilt: number;
  azimuth: number;
  annualTSRF: number;
  size: number;
  monthlySolarAccess: {
    month: number;
    accessPercent: number;
  }[];
  inverter: string;
}

export interface ToleranceException {
  note: string;
  exceptionGivenBy: string
}

/** @deprecated */
interface SystemDesignDocumentDeprecated {
  /** @deprecated */
  inverterManufacturer: string;
  /** @deprecated */
  inverterModel: string;
  /** @deprecated */
  inverterCount: number;
  /** @deprecated */
  mountingModel: string;
  /** @deprecated */
  totalMountingCount: number;
  /** @deprecated */
  storageManufacturer: string;
  /** @deprecated */
  storageModel: string;
  /** @deprecated */
  totalStorageCount: number;
}

export interface SystemDesign extends SystemDesignDocumentDeprecated {
  accountId: ObjectId;
  isCurrent: boolean;
  isApproved?: boolean;
  systemFirstYearProductionKwh: number;
  systemSizeKw: number;
  inverters: SystemAsset[];
  panelManufacturer: string;
  panelModel: string;
  totalPanelCount: number;
  arrays: SystemArray[];
  firstYearMonthlyProduction: MonthlyProduction[];
  mountingType: string;
  storage: SystemAsset[];
  totalStorageCapacityKwh: number;
  inverterManufacturer: string;
  inverterModel: string;
  inverterCount: number;
  firstYearMonthlyProductionKwh: Array<MonthlyProduction>;
  mountingManufacturer: string;
  dateCreated: Date;
  toleranceException?: ToleranceException;
}
