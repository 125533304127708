import { Box, Icon } from '@palmetto/palmetto-components';
import { FlagProps } from './Flag';
import { useContext, useMemo } from 'react';
import { QueueFlagsContext } from './QueueFlagsContext';
import { useGetDocumentsQuery } from '../../services/documents';
import { FlagDocumentTypes } from 'types';
import { useParams } from 'react-router-dom';

interface FlagBoxProps extends FlagProps {
  displayWithNoQueue?: boolean;
  isLast?: boolean;
}

export const FlagInfo = ({ dataKey, displayWithNoQueue = false, fileName }: FlagBoxProps) => {
  const { id = '' } = useParams<{ id: string }>();
  const { getFlag, shouldHide } = useContext(QueueFlagsContext);
  const currentFlag = getFlag(dataKey, fileName, false);
  const uploadedFlagDocuments = useGetDocumentsQuery({
    accountId: id,
    documentTypeFilter: [FlagDocumentTypes.flag],
  });

  const uploadedDocument = useMemo(() => {
    return uploadedFlagDocuments?.data?.find((d) => d.id === currentFlag?.fileUpload);
  }, [currentFlag?.fileUpload, uploadedFlagDocuments?.data]);

  return currentFlag || !shouldHide || displayWithNoQueue ? (
    <>
      {currentFlag?.reason && <Box fontWeight="medium">{currentFlag.reason}</Box>}
      {currentFlag?.note && (
        <Box fontWeight="light">
          <span style={{ whiteSpace: 'pre-wrap' }}>{`Note: ${currentFlag.note}`}</span>
        </Box>
      )}
      {uploadedDocument?.files?.[0]?.viewUrls?.[0]?.url && (
        <Box fontWeight="light" direction="row" gap="xs">
          <Icon name="paperclip" color="body-secondary" aria-label="attachment" size="sm" />
          <a target="_blank" rel="noreferrer noopener" href={uploadedDocument.files[0].viewUrls[0].url}>
            {uploadedDocument.files[0].originalName}
          </a>
        </Box>
      )}
    </>
  ) : null;
};
