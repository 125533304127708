export enum MilestoneType {
  pending = 'pending',
  noticeToProceed = 'noticeToProceed',
  install = 'install',
  activation = 'activation',
  /**
   * @deprecated
   */
  noticeToProceedIssued = 'noticeToProceedIssued',

  /**
   * @deprecated
   */
  installComplete = 'installComplete',
  /**
   * @deprecated
   */
  installPackageComplete = 'installPackageComplete',

  /**
   * @deprecated
   */
  systemActivated = 'systemActivated',
  /**
   * @deprecated
   */
  systemActivation = 'systemActivation',
}

export enum AccountMilestoneStatus {
  completed = 'completed',
  inProgress = 'inProgress',
  pending = 'pending',
  rejected = 'rejected',
  submitted = 'submitted',
}

export enum AccountRequirementStatus {
  completed = 'completed',
  inProgress = 'inProgress',
  pending = 'pending',
  rejected = 'rejected',
  submitted = 'submitted',
  error = 'error',
}

export const milestoneTypeToEmailStringMap: { [value in MilestoneType]?: 'Install' | 'Activation' } = {
  [MilestoneType.install]: 'Install',
  [MilestoneType.activation]: 'Activation',
};

export enum MilestonePackageActions {
  create = 'create',
  edit = 'edit',
  review = 'review',
}
