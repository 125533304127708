import { ChangeEvent, FC } from 'react';
import { Box, CheckboxInput } from '@palmetto/palmetto-components';

export enum DataFilterType {
  Select = 'Select',
}

export interface DropdownOption {
  value: string;
  label: string;
  group?: string;
}

interface SearchableCheckboxFilterListProps {
  selectOptions: DropdownOption[];
  filterType: DataFilterType;
  selectedValues?: string[];
  searchValue?: string;
  handleChange: (change: ChangeEvent<HTMLInputElement>) => void;
}

export const SearchableCheckboxFilterList: FC<SearchableCheckboxFilterListProps> = ({
  selectOptions,
  filterType,
  selectedValues,
  searchValue,
  handleChange,
}) => {
  let filteredOptions = selectOptions;
  let content;

  if (searchValue) {
    filteredOptions = filteredOptions.filter(
      (option) =>
        option.label.toLowerCase().includes(searchValue.toLowerCase()) ||
        option.value.toLowerCase().includes(searchValue.toLowerCase()),
    );
  }
  if (filterType === DataFilterType.Select) {
    content = filteredOptions.map((option) => (
      <CheckboxInput
        id={option.value}
        label={option.label}
        key={option.value}
        onChange={handleChange}
        isChecked={!!selectedValues?.find((item) => item === option.value)}
      />
    ));
  }

  return (
    <Box display="block" childGap="xs">
      {content}
    </Box>
  );
};
