import React, { useCallback, useState } from 'react';
import { Badge, BadgeVariant, Box, Button, toast } from '@palmetto/palmetto-components';
import {
  useSendContractMutation,
  useVoidContractMutation,
  useCheckDisclosureStatusMutation,
} from '../../services/contract';
import { ContractListData } from '../../types/Contracts';
import RequirePermissions from '../auth/requirePermissions';
import { ContractDocumentProviders, ContractRecipient, ContractStatus } from 'types';
import { convertIsoToTimeSimple, convertIsoToDateMed } from '../../components/Activity/ActivityList';
import { useVoidQuoteMutation } from '../../services/quotes';

export function ContractListItem({ item }: { item: ContractListData }): JSX.Element {
  let { contract, quote } = item;
  const [voidContract] = useVoidContractMutation({
    fixedCacheKey: 'useVoidContractMutationKey',
  });
  const [sendContract, { isLoading: isSendingContract }] = useSendContractMutation({
    fixedCacheKey: 'useSendContractMutationKey',
  });
  const [_voidQuote, { isLoading: isVoidingQuote }] = useVoidQuoteMutation({
    fixedCacheKey: 'useVoidQuoteMutationKey',
  });
  const [checkDisclosureStatus, { isLoading: isCheckDisclosureLoading }] = useCheckDisclosureStatusMutation();
  const illinoisShinesContract = contract.contractDocuments.find(
    (cd) => cd.provider === ContractDocumentProviders.illinoisShines,
  );
  const [isSubmitting, setSubmitting] = useState(false);
  const contractStatuses: {
    [key in ContractStatus]: {
      label: string;
      variant: BadgeVariant;
    };
  } = {
    [ContractStatus.created]: {
      label: 'Created',
      variant: 'info',
    },
    [ContractStatus.sent]: {
      label: 'Sent',
      variant: 'info',
    },
    [ContractStatus.disclosureSent]: {
      label: 'Pending Disclosure Signature',
      variant: 'info',
    },
    [ContractStatus.disclosureSigned]: {
      label: 'Disclosure Signed',
      variant: 'info',
    },
    [ContractStatus.partiallySigned]: {
      label: 'Partially Signed',
      variant: 'info',
    },
    [ContractStatus.signed]: {
      label: 'Signed',
      variant: 'info',
    },
    [ContractStatus.approved]: {
      label: 'Approved',
      variant: 'success',
    },
    [ContractStatus.voided]: {
      label: 'Voided',
      variant: 'warning',
    },
    [ContractStatus.rejected]: {
      label: 'Rejected',
      variant: 'danger',
    },
  };

  const handleVoidContract = async () => {
    setSubmitting(true);
    const toastId = toast.loading('Voiding contract');
    try {
      const confirmed = window.confirm('Are you sure you want to Void this Contract? This action cannot be undone.');

      if (!confirmed) return;

      await voidContract({ accountId: String(contract.accountId), contractId: String(contract.id) }).unwrap();
      toast.success('Contract voided successfully');
    } catch (e: any) {
      console.error(e);
      toast.error(e?.data?.message || 'Error voiding contract');
    } finally {
      toast.dismiss(toastId);
      setSubmitting(false);
    }
  };

  const handleResendContract = async () => {
    setSubmitting(true);
    const toastId = toast.loading('Resending contract');
    try {
      await sendContract({
        accountId: String(contract.accountId),
        contractId: String(contract.id),
        forceSend: true,
      }).unwrap();
      toast.success('Contract resent successfully');
    } catch (e: any) {
      if (e?.data?.errors) {
        toast.error(e?.data?.errors.join(', '));
      } else {
        toast.error(e?.data?.message || 'Error resending contract');
      }
    } finally {
      toast.dismiss(toastId);
      setSubmitting(false);
    }
  };

  const handleRefreshDisclosure = useCallback(async () => {
    await checkDisclosureStatus({
      accountId: String(contract.accountId),
      contractId: String(contract.id),
    });
  }, [checkDisclosureStatus, contract.accountId, contract.id]);

  const handleSendContract = async () => {
    setSubmitting(true);
    const toastId = toast.loading('Sending contract');
    try {
      await sendContract({
        accountId: String(contract.accountId),
        contractId: String(contract.id),
        forceSend: false,
      }).unwrap();
      toast.success('Contract sent successfully');
    } catch (e: any) {
      if (e?.data?.errors) {
        toast.error(e?.data?.errors.join(', '));
      } else {
        toast.error(e?.data?.message || 'Error sending contract');
      }
    } finally {
      toast.dismiss(toastId);
      setSubmitting(false);
    }
  };

  const contractStatus = contract.status;
  const docusignContract = contract.contractDocuments.find(
    (cd) => cd.provider === ContractDocumentProviders.docusign,
  );

  return (
    <>
      <Box borderWidth="xs 0 0 0" borderColor="separator" padding="md lg" direction="row" childGap="lg">
        <Box width="xl">
          <Badge
            style={{ width: 'fit-content' }}
            message={contractStatuses[contractStatus as keyof typeof ContractStatus]?.label}
            variant={contractStatuses[contractStatus as keyof typeof ContractStatus]?.variant}
          />
        </Box>
        <Box flex="auto" childGap="xs">
          <Box>{quote?.productName || '---'}</Box>
          {contractStatus !== ContractStatus.voided &&
            docusignContract?.recipients &&
            docusignContract.recipients.map((recipient: ContractRecipient, index: number) => (
              <Box color="body-secondary" key={`${index}-${recipient.name}`}>
                {recipient.name} -{' '}
                {recipient.status === 'created'
                  ? 'signature required'
                  : recipient.status === 'sent'
                    ? `sent to ${recipient.email}`
                    : `signed ${
                        recipient.signedAt
                          ? `${convertIsoToDateMed(recipient.signedAt)} ${convertIsoToTimeSimple(recipient.signedAt)}`
                          : '--'
                      }`}
              </Box>
            ))}
          {illinoisShinesContract && (
            <Box borderWidth="xs 0 0 0" borderColor="separator" padding="xs 0 xs 0">
              <Box direction="row" alignItems="center" padding="0 0 xs 0">
                <Box margin="0 md 0 0">Illinois Shines Disclosure Document</Box>
                {![ContractStatus.voided, ContractStatus.created].includes(contract.status) && (
                  <Badge
                    style={{ width: 'fit-content' }}
                    message={String(illinoisShinesContract.status).toUpperCase()}
                    variant="info"
                  />
                )}
              </Box>
              {illinoisShinesContract?.recipients &&
                illinoisShinesContract.recipients.map((recipient: ContractRecipient, index: number) => (
                  <Box color="body-secondary" key={`${index}-${recipient.name}`}>
                    {recipient.name} -{' '}
                    {recipient.status === 'created'
                      ? 'signature required'
                      : recipient.status === 'sent'
                        ? `sent to ${recipient.email}`
                        : `signed ${
                            recipient.signedAt
                              ? `${convertIsoToDateMed(recipient.signedAt)} ${convertIsoToTimeSimple(recipient.signedAt)}`
                              : '--'
                          }`}
                  </Box>
                ))}
            </Box>
          )}
        </Box>

        <Box direction="column" justifyContent="space-between">
          <Box direction="row" alignSelf="flex-end" childGap="xs">
            {(contractStatus === ContractStatus.created || contractStatus === ContractStatus.disclosureSigned) && (
              <Box>
                <RequirePermissions permissions={['admin', 'editor']} checkAllPermissions={false}>
                  <Button
                    size="sm"
                    variant="primary"
                    isDisabled={isSubmitting || isSendingContract || isVoidingQuote}
                    onClick={handleSendContract}
                  >
                    Send
                  </Button>
                </RequirePermissions>
              </Box>
            )}
            {[ContractStatus.sent, ContractStatus.partiallySigned].includes(contractStatus) && (
              <Box>
                <RequirePermissions
                  permissions={['admin', 'editor', 'lightReachSupport']}
                  checkAllPermissions={false}
                >
                  <Button
                    size="sm"
                    variant="primary"
                    isDisabled={isSubmitting || isSendingContract || isVoidingQuote}
                    onClick={handleResendContract}
                  >
                    Resend
                  </Button>
                </RequirePermissions>
              </Box>
            )}
            {![ContractStatus.created, ContractStatus.voided].includes(contractStatus) && (
              <Box>
                <RequirePermissions permissions={['admin', 'editor']} checkAllPermissions={false}>
                  <Button
                    size="sm"
                    variant="secondary"
                    tone="danger"
                    isDisabled={isSubmitting || isCheckDisclosureLoading || isSendingContract || isVoidingQuote}
                    onClick={handleVoidContract}
                  >
                    Void
                  </Button>
                </RequirePermissions>
              </Box>
            )}
          </Box>
          <Box direction="row" alignSelf="flex-end">
            {ContractStatus.disclosureSent === contractStatus && (
              <Box>
                <RequirePermissions permissions={['admin', 'editor']} checkAllPermissions={false}>
                  <Button
                    size="sm"
                    variant="secondary"
                    isLoading={isCheckDisclosureLoading}
                    onClick={handleRefreshDisclosure}
                    isDisabled={isSubmitting || isCheckDisclosureLoading || isSendingContract || isVoidingQuote}
                  >
                    Check Status
                  </Button>
                </RequirePermissions>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
}
