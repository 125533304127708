import { FC, useCallback, useState } from 'react';
import classNames from 'classnames';
import { Box, BoxProps, Button, Popover, TextInputSize } from '@palmetto/palmetto-components';

interface SortItem {
  id: string;
  label: string;
}
interface SortButtonDropdownProps {
  className?: string;
  size?: TextInputSize;
  onSortChange: (sort: { id: string; label: string }) => void;
  sortOptions: SortItem[];
  appliedSort?: SortItem;
}

export const SortButtonDropdown: FC<SortButtonDropdownProps> = ({
  className,
  size = 'sm',
  sortOptions,
  appliedSort,
  onSortChange,
}) => {
  const [isPopoverOpen, setPopoverOpen] = useState(false);

  const togglePopover = useCallback(() => {
    const prevState = isPopoverOpen;
    setPopoverOpen(!prevState);
  }, [isPopoverOpen]);

  const changeSort = (sort: SortItem) => {
    onSortChange(sort);
    setPopoverOpen(false);
  };

  const handleClickOutside = useCallback(() => {
    setPopoverOpen(false);
  }, []);

  const content = (
    <Box display="block" fontSize="sm" overflow="auto" background="secondary" maxHeight="4xl">
      <Box>
        <Box>
          {sortOptions.map((sort) => {
            const { label } = sort;
            const isSelected = appliedSort?.id === sort.id;

            const filterButtonProps = isSelected
              ? ({
                  className: 'filter-focus',
                  textAlign: 'left',
                  borderColor: 'separator',
                  borderWidth: '0 0 xs 0',
                  color: 'body-tertiary',
                  padding: 'xs md',
                  background: 'secondary',
                } as BoxProps)
              : ({
                  as: 'button',
                  className: 'filter-focus',
                  textAlign: 'left',
                  borderColor: 'separator',
                  borderWidth: '0 0 xs 0',
                  color: 'body-primary',
                  cursor: 'pointer',
                  type: 'button',
                  padding: 'xs md',
                  onClick: () => changeSort(sort),
                  background: 'primary',
                  hover: {
                    background: 'info',
                  },
                } as BoxProps);

            return (
              <Box key={`${sort.id}-button`} {...filterButtonProps}>
                {label}
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );

  return (
    <Box alignItems="flex-start" childGap="md" className={className}>
      <Box direction={{ base: 'column', tablet: 'row' }} alignItems={{ base: 'flex-start', tablet: 'center' }} wrap>
        <Popover
          trapFocus
          content={content}
          isOpen={isPopoverOpen}
          placement="bottom-start"
          offsetFromTarget={2}
          contentContainerProps={{
            padding: '0',
            width: '300px',
            shadow: 'lg',
            radius: 'sm',
            overflow: 'hidden',
          }}
          hasArrow={true}
          onClickOutside={handleClickOutside}
          portalTarget={document.body}
          withPortal
        >
          <Button
            onClick={togglePopover}
            variant={'secondary'}
            tone="neutral"
            size={size}
            iconSuffix="caret-down"
            style={{ whiteSpace: 'nowrap' }}
            className={classNames({
              'background-color-secondary-100': isPopoverOpen,
            })}
            aria-label="add filter"
          >
            Sort: {appliedSort?.label}
          </Button>
        </Popover>
      </Box>
    </Box>
  );
};
