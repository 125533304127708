import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { setShouldLogout } from '../store/reducers/authSlice';

interface ErrorData {
  message: string;
  errorCode: string;
}

const baseQuery = fetchBaseQuery({ baseUrl: '/api' });
const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  let result = await baseQuery(args, api, extraOptions);
  const errorData = result?.error?.data as ErrorData;
  const isJWTValidationError = errorData?.message?.match(/^Unable to validate JWT/);
  const isInviteTokenValidationError = errorData?.message?.match(/^Unable to validate invite token/);
  if (result.error && result.error.status === 401 && isJWTValidationError) {
    console.debug('Authentication errors, need to refresh token');
    const refreshResult = await baseQuery({ url: '/auth/jwt-refresh', method: 'POST' }, api, extraOptions);

    if (refreshResult?.meta?.response?.status !== 204) {
      console.warn('Token refresh failed, logging out');
      // If not a successful refresh response, log out.
      api.dispatch(setShouldLogout(true));
      return result;
    }

    // retry the query
    result = await baseQuery(args, api, extraOptions);
  }

  if (isInviteTokenValidationError) {
    console.debug('Invite token error, logging out');
    api.dispatch(setShouldLogout(true));
  }

  return result;
};

/**
 * Create a base API to inject endpoints into elsewhere.
 * Components using this API should import from the injected site,
 * in order to get the appropriate types,
 * and to ensure that the file injecting the endpoints is loaded
 */
export const api = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    'Accounts',
    'AccountAdders',
    'AccountMilestones',
    'AccountProgress',
    'AccountStipulations',
    'Applications',
    'ConstructionAdders',
    'Contracts',
    'CreditReportDetails',
    'CreditReportSummaries',
    'DocumentDocument',
    'EnergyCommunity',
    'FinancialAccountDocument',
    'Funds',
    'FundAccounts',
    'History',
    'Holdbacks',
    'OrganizationHoldbacks',
    'OrganizationEnergyCommunity',
    'OrganizationPpwModifiers',
    'OrganizationPaymentSettings',
    'OrganizationPriceSheetMappings',
    'OrganizationSplitPaySetting',
    'Modifiers',
    'Organizations',
    'PriceSheetMappings',
    'PriceSheets',
    'PpwModifiers',
    'QueueTasks',
    'Quotes',
    'Queues',
    'SystemDesign',
    'Tasks',
    'Users',
    'Utilities',
    'VendorPaymentSchedule',
    'VendorPaymentSchedules',
  ],
  // This api has endpoints injected in adjacent files.
  endpoints: () => ({}),
});
