import { useParams } from 'react-router-dom';
import { Box, Button, toast } from '@palmetto/palmetto-components';
import { Form, Formik } from 'formik';
import { useCallback, useMemo, useState } from 'react';
import * as yup from 'yup';
import FormSectionContainer from '../Forms/FormSectionContainer';
import { EquipmentDetailsFormSection } from '../SystemDesign/EquipmentDetailsFormSection';
import { MountingHardwareFormSection } from '../SystemDesign/MountingHardwareFormSection';
import { useCreateSystemDesignMutation, useGetCurrentDesignQuery } from '../../services/systemDesign';
import { MonthlyProductionFormSection } from '../SystemDesign/MonthlyProductionFormSection';
import { SolarArraysFormSection } from '../SystemDesign/SolarArraysFormSection';
import { useGetAccountQuery } from '../../services/accounts';
import { AlertProductionDifference } from '../SystemDesign/AlertProductionDifference';
import { Milestone, MilestoneRequirement, MilestoneStatus, MilestoneType } from 'types';
import { SystemSizeAndFirstYearProductionSections } from '../SystemDesign/SystemSizeAndFirstYearProductionSections';
import { Sidebar } from './components/Sidebar';
import { SidebarSection } from './components/SidebarSection';
import { ProgressBadge } from './components/ProgressBadge';
import { PropertyBox } from './components/PropertyBox';
import { useGetQuotesQuery } from '../../services/quotes';
import { useGetCurrentContractQuery } from '../../services/contract';
import { setPvSystemInitialValues } from '../../helpers/setPvSystemInitialValues';
import RequirePalmettoFinanceUser from '../auth/RequirePalmettoFinanceUser';

/** has default values for threshold but can be moved to use appSettings in the future */
const isChangeWithinThreshold = ({
  oldValue,
  newValue,
  minPercent = -0.05,
  maxPercent = 0.1,
}: {
  oldValue: number;
  newValue: number;
  minPercent?: number;
  maxPercent?: number;
}): boolean => {
  const percentChange = (newValue - oldValue) / oldValue;
  return percentChange >= minPercent && percentChange <= maxPercent;
};

const avlEntrySchema = yup.object({
  manufacturer: yup.string().optional().nullable(),
  model: yup.string().optional().nullable(),
  count: yup.number().optional().nullable(),
});

const inverterSchema = yup.object({
  model: yup.string().optional().nullable(),
  count: yup.number().optional().nullable(),
});

const solarArraySchema = yup.object({
  panelCount: yup.number().optional().nullable(),
  annualTSRF: yup.number().optional().nullable(),
  orientation: yup.string().optional().nullable(),
  tilt: yup.number().optional().nullable(),
  azimuth: yup.number().optional().nullable(),
  inverter: yup.number().optional().nullable(),
  monthlySolarAccess: yup.mixed().test({
    test: function (value: any) {
      if (value === undefined) return true;
      for (let i = 0; i < 12; i++) {
        const val = value[i];
        if (!val) {
          return this.createError({ message: 'All monthly solar access values must be filled out' });
        }
      }
      return true;
    },
  }),
});

export const pvSystemSchema = yup.object({
  systemSizeKw: yup
    .mixed()
    .test({
      test: function (value: unknown) {
        if (!value) return true;
        const numberValue = Number(value);
        if (isNaN(numberValue)) {
          return this.createError({ message: 'System size must be a number' });
        }
        if (numberValue < 0) {
          return this.createError({ message: 'System size must be a positive number' });
        }
        return true;
      },
    })
    .required('Required for quote creation'),
  systemFirstYearProductionKwh: yup
    .mixed()
    .test({
      test: function (value: unknown) {
        if (!value) return true;
        const numberValue = Number(value);
        if (isNaN(numberValue)) {
          return this.createError({ message: 'Production must be a number' });
        }
        if (numberValue < 0) {
          return this.createError({ message: 'Production must be a positive number' });
        }
        return true;
      },
    })
    .required('Required for quote creation'),
  panelManufacturer: yup.string().optional().nullable(),
  panelModel: yup.string().optional().nullable(),
  totalPanelCount: yup.number().optional().nullable(),
  inverterManufacturer: yup.string().optional().nullable(),
  inverterModels: yup.array().of(inverterSchema).optional().nullable(),
  storage: yup.array().of(avlEntrySchema).optional().nullable(),
  mountingType: yup.string().optional().nullable(),
  mountingManufacturer: yup.string().optional().nullable(),
  firstYearMonthlyProductionKwh: yup
    .mixed()
    // .test({  // Note: Temporarily disabling this validation to unblock contract vendors doing data entry for installed systems.
    //   test: function (value: any) {
    //     if (!(value?.length > 0)) return true;
    //     const sum = (value ?? []).reduce((acc: number, val: string) => (acc ?? 0) + Number(val ?? 0), 0) ?? 0;
    //     const systemFirstYearProductionKwh = this.parent.systemFirstYearProductionKwh;
    //     if (Math.abs(sum - systemFirstYearProductionKwh) > 0.01) {
    //       const message = `Monthly production values sum ${sum.toFixed(
    //         2,
    //       )} kWh does not match expected sum ${systemFirstYearProductionKwh.toFixed(2)} kWh`;
    //       return this.createError({ message });
    //     }
    //     return true;
    //   },
    // })
    .optional()
    .nullable(),
  arrays: yup.array().of(solarArraySchema).optional().nullable(),
});

export function hasInstallMilestoneApproved(milestones: Milestone[]) {
  return (
    milestones.find((milestone: Milestone) => milestone.type === MilestoneType.install)?.status ===
    MilestoneStatus.approved
  );
}
export function hasInstallMilestoneCompleted(milestones: Milestone[]) {
  return !!milestones.find((milestone: Milestone) => milestone.type === MilestoneType.install)?.completedAt;
}

const PVSystem = () => {
  const { id } = useParams<{ id: any }>();
  const { data: systemDesign, isLoading: isDesignLoading }: any = useGetCurrentDesignQuery(id);
  const { data: account, isLoading: isAccountLoading } = useGetAccountQuery(id);
  const isLoading = isDesignLoading || isAccountLoading;
  const hasApprovedContract = useMemo(() => {
    const milestoneRequirements = account?.milestones.find(
      (milestone: any) => milestone.type === MilestoneType.noticeToProceed,
    )?.requirements;
    return (
      milestoneRequirements?.find((requirement: any) => requirement.type === MilestoneRequirement.contractSigned)
        ?.status === MilestoneStatus.approved
    );
  }, [account?.milestones]);
  const [createSystemDesign] = useCreateSystemDesignMutation();
  const [productionDifferenceIfExceedsMargin, setProductionDifferenceIfExceedsMargin] = useState<number>(0);
  const initialValues = setPvSystemInitialValues(systemDesign, account);
  const handleSubmit = useCallback(
    async (values: any, { setSubmitting }: any) => {
      if (
        hasApprovedContract &&
        !isChangeWithinThreshold({
          oldValue: account.systemDetails.systemFirstYearProductionKwh,
          newValue: values.systemFirstYearProductionKwh,
        })
      ) {
        setProductionDifferenceIfExceedsMargin(
          (values.systemFirstYearProductionKwh - account.systemDetails.systemFirstYearProductionKwh) /
            account.systemDetails.systemFirstYearProductionKwh,
        );
        return;
      } else {
        setProductionDifferenceIfExceedsMargin(0);
      }
      try {
        await createSystemDesign({
          accountId: id,
          systemDesign: {
            ...values,
            inverters: values.inverterModels
              ?.map((inverter: { model: string; count: number }) => {
                if (!values.inverterManufacturer || !inverter.model) return undefined;
                return {
                  manufacturer: values.inverterManufacturer,
                  model: inverter.model,
                  count: inverter.count || 1,
                };
              })
              .filter(Boolean),
            inverterManufacturer: undefined, // clear out deprecated field
            inverterModels: undefined, // clear out deprecated field
            firstYearMonthlyProductionKwh: values.firstYearMonthlyProductionKwh.map(
              (value: string, index: number) => ({
                month: index + 1,
                productionKwh: Number(value),
              }),
            ),
            arrays: values.arrays.map((array: any) => ({
              ...array,
              monthlySolarAccess: array.monthlySolarAccess?.map((value: string, index: number) => ({
                month: index + 1,
                accessPercent: Number(value),
              })),
              inverter: array.inverter ? array.inverter - 1 : null,
            })),
          },
        }).unwrap();

        toast.success('Account PV System Details Saved');
      } catch (e: any) {
        toast.error(e?.data?.message || 'Error saving account PV system details');
      }
      setSubmitting(false);
    },
    [account?.systemDetails?.systemFirstYearProductionKwh, createSystemDesign, id, hasApprovedContract],
  );

  const { data: quotes } = useGetQuotesQuery(id);
  const installMilestoneApproved = useMemo(() => {
    return hasInstallMilestoneApproved(account?.milestones || []);
  }, [account?.milestones]);

  const installed = useMemo(() => {
    return hasInstallMilestoneCompleted(account?.milestones || []);
  }, [account?.milestones]);
  const { data: contract } = useGetCurrentContractQuery(id);

  const contractActiveQuote = (quotes || []).find((element: any) => {
    return element.id === contract?.quoteId && element.status !== 'voided';
  });
  const firstYearContracted = contractActiveQuote?.systemFirstYearProductionKwh || 0;
  const firsYearInstalled =
    systemDesign?.systemFirstYearProductionKwh || account?.systemDetails?.systemFirstYearProductionKwh || 0;

  return (
    <Box
      borderWidth="xs"
      borderColor="separator"
      overflow="hidden"
      direction={{ base: 'column', desktop: 'row' }}
      width="100"
      radius="md"
    >
      <Sidebar title="PV System Design">
        <SidebarSection>
          <ProgressBadge milestones={account?.milestones} />
          <Box padding="md 0 0 0">
            <PropertyBox title="Total System Size" noBorder>
              <Box fontSize="lg">
                {systemDesign?.systemSizeKw ?? account?.systemDetails?.systemSizeKw ?? '-- '}
                kW
              </Box>
            </PropertyBox>
            <PropertyBox title="Year-1 Production" noBorder>
              <Box fontSize="lg">
                {systemDesign?.systemFirstYearProductionKwh
                  ? account?.systemDetails?.systemFirstYearProductionKwh?.toFixed(0)
                  : '-- '}
                kWh
              </Box>
            </PropertyBox>
            <PropertyBox title="Total Panel Count" noBorder>
              <Box fontSize="lg">{systemDesign?.totalPanelCount || account?.systemDetails?.panelCount || '-'}</Box>
            </PropertyBox>
          </Box>
        </SidebarSection>
      </Sidebar>
      <Box width={{ base: '100', desktop: '70' }} childGap="lg" padding={{ base: '0', desktop: 'xl' }}>
        <Formik
          initialValues={initialValues}
          validationSchema={pvSystemSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
          validateOnChange={false}
        >
          {({ isSubmitting }) => (
            <Form noValidate>
              <Box as="h2" fontWeight="medium" fontSize="lg" padding={{ base: 'lg lg 0 lg', desktop: '0' }}>
                System Details
              </Box>
              <FormSectionContainer>
                <SystemSizeAndFirstYearProductionSections
                  installMilestoneApproved={installMilestoneApproved}
                  hasCurrentContract={contract && contractActiveQuote}
                  installed={installed}
                  isLoading={isLoading}
                  accountId={id}
                  firsYearInstalledKwH={firsYearInstalled}
                  firstYearContractedKwH={firstYearContracted}
                />
                {account?.programType ? (
                  <>
                    <EquipmentDetailsFormSection isLoading={isLoading} programType={account.programType} />
                    <MountingHardwareFormSection isLoading={isLoading} programType={account.programType} />
                  </>
                ) : (
                  <></>
                )}
                <RequirePalmettoFinanceUser>
                  <MonthlyProductionFormSection isLoading={isLoading} />
                  <SolarArraysFormSection isLoading={isLoading} />
                </RequirePalmettoFinanceUser>
                {productionDifferenceIfExceedsMargin !== 0 && (
                  <Box>
                    <AlertProductionDifference productionDifference={productionDifferenceIfExceedsMargin} />
                  </Box>
                )}
              </FormSectionContainer>

              <Box
                direction={{
                  base: 'column',
                  tablet: 'row',
                }}
                alignItems={{
                  base: 'stretch',
                  tablet: 'flex-end',
                }}
                justifyContent={{
                  tablet: 'flex-end',
                }}
                childGap="sm"
                style={{ flexShrink: 0 }}
                padding={{ base: 'lg', desktop: 'lg 0 0 0' }}
              >
                <Button
                  as="a"
                  href={`/accounts/${id ? id : ''}`}
                  variant="secondary"
                  tone="neutral"
                  size="md"
                  isLoading={isSubmitting}
                >
                  Cancel
                </Button>
                <Button size="md" variant="primary" type="submit" isLoading={isSubmitting}>
                  Save
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default PVSystem;
