import { Box } from '@palmetto/palmetto-components';
import { Search } from './Search';

interface FilterBarProps {
  className?: string;
  filtersState: any;
  handleSearchTerm: (searchTerm: string) => void;
  placeholder?: string;
  children?: React.ReactNode;
}

export function FilterBar({
  className,
  filtersState,
  handleSearchTerm,
  placeholder,
  children,
}: FilterBarProps): JSX.Element {
  return (
    <Box
      padding="sm lg"
      childGap="xs"
      overflow="auto"
      background="tertiary"
      radius={{ base: '0', desktop: 'md' }}
      className={className}
      position="sticky"
      style={{ top: '0' }}
      zIndex="sticky"
      direction={{ base: 'column', tablet: 'row' }}
    >
      <Box>
        <Search
          setSearchText={handleSearchTerm}
          searchText={filtersState?.searchTerm}
          placeholder={placeholder}
          autoFocus
        />
      </Box>
      <Box style={{ flexGrow: 1 }}>{children}</Box>
    </Box>
  );
}
