import { isNumber } from 'lodash';
import { MonthlyProduction, SystemDesign, SystemDetails } from 'types';
import { Quotes } from '../types/Quotes';

export const setPvSystemInitialValues = (
  systemDesign: SystemDesign & SystemDetails,
  account: Quotes,
): SystemDesign => {
  const initialValues = {
    systemSizeKw: systemDesign?.systemSizeKw ?? account?.systemDetails?.systemSizeKw ?? '',
    systemFirstYearProductionKwh:
      systemDesign?.systemFirstYearProductionKwh ?? account?.systemDetails?.systemFirstYearProductionKwh ?? '',
    totalPanelCount: systemDesign?.totalPanelCount || account?.systemDetails?.totalPanelCount || 0,
    panelManufacturer: systemDesign?.panelManufacturer ?? account?.systemDetails?.panelManufacturer ?? '',
    panelModel: systemDesign?.panelModel ?? account?.systemDetails?.panelModel ?? '',
    inverterModels: systemDesign?.inverters?.length
      ? systemDesign.inverters.map((inverter) => {
          return { model: inverter.model, count: inverter.count };
        })
      : account?.systemDetails?.inverterModel
        ? [{ model: account?.systemDetails?.inverterModel, count: 1 }]
        : [{}],
    inverterManufacturer:
      systemDesign?.inverters?.[0]?.manufacturer ?? account?.systemDetails?.inverterManufacturer ?? '',
    mountingType: systemDesign?.mountingType ?? '',
    mountingManufacturer: systemDesign?.mountingManufacturer ?? '',
    arrays: systemDesign?.arrays
      ? systemDesign.arrays.map((array) => ({
          ...array,
          monthlySolarAccess: array.monthlySolarAccess?.map((item) => item.accessPercent),
          inverter: isNumber(array.inverter) ? array.inverter + 1 : array.inverter,
        }))
      : [],
    firstYearMonthlyProductionKwh:
      systemDesign?.firstYearMonthlyProductionKwh?.map((item: MonthlyProduction) => item.productionKwh) ?? [],
  };
  return initialValues as unknown as SystemDesign;
};
