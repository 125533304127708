import * as yup from 'yup';
import { Box, Button } from '@palmetto/palmetto-components';
import { FormikValues, Form, Formik } from 'formik';
import { ConstructionAdderType, ProgramType } from 'types';
import { TargetAccounts } from './TargetAccountsForm';
import { Pricing } from './PricingForm';
import { useNavigate } from 'react-router-dom';
import { ConstructionAddersForm } from './ConstructionAddersForm';
import { ModifiersForm } from './ModifiersForm';

const initialSchemaByProgramType = {
  [ProgramType.solar]: {
    pvOnlyMin: '',
    pvOnlyMax: '',
    absoluteCeiling: '',
    electricalUpgrade: '',
    arbitrageBattery: '',
    backupBattery: '',
    backupBatteryKwh: '',
    holdback: '',
    zeroEscalatorCeiling: '',
    energyCommunity: '',
  },
  [ProgramType.doePr]: {
    minSystemSize: '',
    maxSystemSize: '',
    minBatteryCapacity: '',
    maxBatteryCapacity: '',
    state: { label: 'PR', value: 'PR' },
    solarReadiness: '',
  },
  [ProgramType.hvac]: {},
  base: {
    organizationIds: [],
    state: '',
    utilities: [],
    priceSheet: '',
    escMin: '',
    escMax: '',
  },
};

const validationSchemaByProgramType = {
  [ProgramType.solar]: yup.object({
    pvOnlyMin: yup.object().required('PV-Only Min is required'),
    pvOnlyMax: yup.object().required('PV-Only Max is required'),
    absoluteCeiling: yup.object().required('Absolute Ceiling is required'),
    electricalUpgrade: yup.object().nullable(),
    arbitrageBattery: yup.object().nullable(),
    zeroEscalatorCeiling: yup.object().nullable(),
  }),
  [ProgramType.doePr]: yup.object({
    minSystemSize: yup.object().required('Min System Size is required'),
    maxSystemSize: yup.object().required('Max System Size is required'),
    minBatteryCapacity: yup.object().required('Min Battery Capacity is required'),
    maxBatteryCapacity: yup.object().required('Max Battery Capacity is required'),
  }),
  [ProgramType.hvac]: yup.object({}),
  base: yup.object({
    organizationIds: yup.array().min(1),
    state: yup.object().required('State is required'),
    utilities: yup.array().min(1),
    priceSheet: yup.object().required('Price Sheet is required'),
    escMin: yup.object().required('Escalator Min is required'),
    escMax: yup.object().required('Escalator Max is required'),
  }),
};

export const isFieldSelected = (field: any) => {
  return field && field !== '' && field.value;
};

interface MappingFactoryProps {
  handleSubmit: (data: any) => void;
  programType: ProgramType;
  data?: FormikValues;
}

export const MappingFormFactory = ({ handleSubmit, programType, data = {} }: MappingFactoryProps) => {
  const initialValues = {
    ...initialSchemaByProgramType.base,
    ...initialSchemaByProgramType[programType],
    ...data,
  };
  const validationSchema = validationSchemaByProgramType.base.concat(validationSchemaByProgramType[programType]);

  validationSchemaByProgramType[programType];
  const navigate = useNavigate();

  const onSubmit = (values: any) => {
    let data;
    switch (programType) {
      case ProgramType.solar: {
        const constructionAdders: any[] = [];
        const selectedArbitrageBattery = values.selectedArbitrageBattery;
        const selectedBackupBattery = values.selectedBackupBattery;
        const selectedBackupBatteryKwh = values.selectedBackupBatteryKwh;
        const selectedHoldback = values.selectedHoldback;
        const selectedEnergyCommunity = values.selectedEnergyCommunity;
        const selectedElectricalUpgrade = values.selectedElectricalUpgrade;
        const selectedBackupBatteryPriceCaps = values.selectedBackupBatteryPriceCaps;
        const selectedPpwModifier = values.selectedPpwModifier;

        const arbitrageBattery = selectedArbitrageBattery && {
          id: values.arbitrageBattery.value,
          name: values.arbitrageBattery.label,
          type: ConstructionAdderType.arbitrageBattery,
          kwhAdderBand: selectedArbitrageBattery.kwhAdderBand,
        };
        const backupBattery = selectedBackupBattery && {
          id: values.backupBattery.value,
          name: values.backupBattery.label,
          type: ConstructionAdderType.backupBattery,
          paymentFactors: selectedBackupBattery.paymentFactors,
        };
        const backupBatteryKwh = selectedBackupBatteryKwh && {
          id: values.backupBatteryKwh.value,
          name: values.backupBatteryKwh.label,
          type: ConstructionAdderType.backupBatteryKwh,
          kwhAdderBand: selectedBackupBatteryKwh.kwhAdderBand,
        };
        const electricalUpgrade = selectedElectricalUpgrade && {
          id: values.electricalUpgrade.value,
          name: values.electricalUpgrade.label,
          type: ConstructionAdderType.electricalUpgrade,
          kwhAdderBand: selectedElectricalUpgrade.kwhAdderBand,
        };
        const backupBatteryPriceCaps = selectedBackupBatteryPriceCaps && {
          id: values.backupBatteryPriceCaps.value,
          name: values.backupBatteryPriceCaps.label,
          type: ConstructionAdderType.backupBatteryPriceCap,
          backupBatteryPriceCaps: selectedBackupBatteryPriceCaps.backupBatteryPriceCaps,
        };
        constructionAdders.push(arbitrageBattery);
        constructionAdders.push(electricalUpgrade);
        constructionAdders.push(backupBattery);
        constructionAdders.push(backupBatteryKwh);
        constructionAdders.push(backupBatteryPriceCaps);

        const energyCommunity = selectedEnergyCommunity && {
          id: selectedEnergyCommunity.id,
          name: selectedEnergyCommunity.name,
          values: Object.keys(selectedEnergyCommunity.data).reduce(
            (acc, key) => {
              const obj: Record<string, any> = {};
              const [format] = Object.keys(selectedEnergyCommunity.data[key]).filter(
                (value) => selectedEnergyCommunity.data[key][value],
              );
              obj.type = key;
              obj.format = format;
              obj.value = selectedEnergyCommunity.data[key][format];
              acc.push(obj);
              return acc;
            },
            [] as Record<string, any>[],
          ),
        };

        const ppwModifier = selectedPpwModifier && {
          id: values.ppwModifier.value,
          name: values.ppwModifier.label,
          amount: selectedPpwModifier.data.amount,
        };

        data = {
          organizationIds: values.organizationIds.map((v: any) => v.value),
          state: values.state.value,
          utilities: values.utilities.map((v: any) => v.value),
          priceSheetId: values.priceSheet.value,
          priceSheetName: values.priceSheet.label,
          escalationRateMin: values.escMin.value,
          escalationRateMax: values.escMax.value,
          zeroEscalatorCeiling: values.zeroEscalatorCeiling.value,
          pvOnlyMin: values.pvOnlyMin.value,
          pvOnlyCeiling: values.pvOnlyMax.value,
          absoluteCeiling: values.absoluteCeiling.value,
          constructionAdders: constructionAdders.filter((adder) => !!adder),
          holdback: selectedHoldback,
          energyCommunity,
          ppwModifier,
        };
        break;
      }
      case ProgramType.doePr: {
        const constructionAdders: any[] = [];
        const selectedSolarReadiness = values.selectedSolarReadiness;
        const solarReadiness = selectedSolarReadiness && {
          id: values.solarReadiness.value,
          name: values.solarReadiness.label,
          type: ConstructionAdderType.solarReadiness,
          maxAmount: selectedSolarReadiness.maxAmount,
        };
        constructionAdders.push(solarReadiness);
        data = {
          organizationIds: values.organizationIds.map((v: any) => v.value),
          state: values.state.value,
          utilities: values.utilities.map((v: any) => v.value),
          priceSheetId: values.priceSheet.value,
          priceSheetName: values.priceSheet.label,
          escalationRateMin: values.escMin.value,
          escalationRateMax: values.escMax.value,
          minSystemSize: values.minSystemSize.value,
          maxSystemSize: values.maxSystemSize.value,
          minBatteryCapacity: values.minBatteryCapacity.value,
          maxBatteryCapacity: values.maxBatteryCapacity.value,
          constructionAdders: constructionAdders.filter((adder) => !!adder),
        };
        break;
      }
    }
    handleSubmit(data);
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize={true}
    >
      {(props) => (
        <Form onSubmit={props.handleSubmit} noValidate>
          <Box childGap="xl">
            <Box>
              <TargetAccounts programType={programType} />
            </Box>
            <Box>
              <Pricing programType={programType} />
            </Box>
            <Box>
              <ConstructionAddersForm programType={programType} initialValues={initialValues} />
            </Box>
            <Box>
              <ModifiersForm programType={programType} initialValues={initialValues} />
            </Box>
            <Box direction="row" justifyContent="flex-end" childGap="sm">
              <Box>
                <Button
                  as="button"
                  onClick={() => {
                    props.resetForm();
                    navigate(`/admin/pricing/${programType}`);
                  }}
                  variant="secondary"
                  tone="neutral"
                  size="md"
                  isDisabled={props.isSubmitting}
                >
                  Cancel
                </Button>
              </Box>
              <Box>
                <Button size="md" variant="primary" type="submit" isLoading={props.isSubmitting}>
                  Save Mapping
                </Button>
              </Box>
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
};
