import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useGetAccountQuery } from '../../services/accounts';
import { useGetAddersQuery } from '../../services/adders';
import { AdderType } from '../../types/Quotes';
import { Box } from '@palmetto/palmetto-components';
import { PropertyBox } from '../Account/components/PropertyBox';
import { ProgressBadge } from '../Account/components/ProgressBadge';

interface AddersSidebarContentProps {
  selectedAddersMap: Record<AdderType, boolean>;
}
export const AddersSidebarContent = ({ selectedAddersMap }: AddersSidebarContentProps) => {
  const { id } = useParams<{ id: string }>();
  const accountId = id as string;
  const { data: account, isLoading: isAccountLoading } = useGetAccountQuery(accountId);
  const { data: addersData, isLoading: isAddersLoading } = useGetAddersQuery(accountId);
  const isLoading = isAccountLoading || isAddersLoading;
  const arbitrageBatteryProperty = useMemo(() => {
    if (isLoading) return {};
    const adder = addersData?.adders?.find((adder) => adder.type === AdderType.arbitrageBattery);
    return adder ? { description: `${adder.manufacturer} ${adder.model}`, quantity: adder.quantity } : {};
  }, [addersData?.adders, isLoading]);
  const backupBatteryProperty = useMemo(() => {
    if (isLoading) return {};
    const adder = addersData?.adders?.find((adder) => adder.type === AdderType.backupBattery);
    return adder ? { description: `${adder.manufacturer} ${adder.model}`, quantity: adder.quantity } : {};
  }, [addersData?.adders, isLoading]);
  return (
    <>
      <ProgressBadge milestones={account?.milestones} />
      {selectedAddersMap?.arbitrageBattery && (
        <PropertyBox
          title={
            <>
              <Box fontWeight="medium">Arbitrage Battery</Box>
              <Box fontSize="xs" color="body-secondary">{`${arbitrageBatteryProperty?.quantity ?? '--'}`}</Box>
            </>
          }
        >
          <Box fontSize="xs" color="body-secondary">
            {arbitrageBatteryProperty?.description}
          </Box>
        </PropertyBox>
      )}
      {selectedAddersMap?.backupBattery && (
        <PropertyBox
          title={
            <>
              <Box fontWeight="medium">Backup Battery</Box>
              <Box fontSize="xs" color="body-secondary">{`${backupBatteryProperty?.quantity ?? '--'}`}</Box>
            </>
          }
        >
          <Box fontSize="xs" color="body-secondary">
            {backupBatteryProperty?.description}
          </Box>
        </PropertyBox>
      )}
      {selectedAddersMap?.electricalUpgrade && (
        <PropertyBox title={<Box fontWeight="medium">Electrical Upgrade</Box>} />
      )}
    </>
  );
};
