import { ActivityHistoryEventType, SabalEventType } from 'types';
import ContractCreated from './components/ContractCreated';
import ContractSent from './components/ContractSent';
import ContractSigned from './components/ContractSigned';
import ContractVoided from './components/ContractVoided';
import Email from './components/Email';
import HeliosCreated from './components/HeliosCreated';
import HeliosInvite from './components/HeliosInvite';
import QuoteCreated from './components/QuoteCreated';
import QuoteVoided from './components/QuoteVoided';
import SabalAccountCreated from './components/SabalAccountCreated';
import SabalAccountUpdated from './components/SabalAccountUpdated';
import CreditDecision from './components/CreditDecision';
import CreditError from './components/CreditError';
import CreditExpired from './components/CreditExpired';
import SabalDocumentApproved from './components/SabalDocumentApproved';
import SabalDocumentArchived from './components/SabalDocumentArchived';
import SabalDocumentRejected from './components/SabalDocumentRejected';
import SabalDocumentUploaded from './components/SabalDocumentUploaded';
import SolarFlareEmailNotificationSent from './components/SolarFlareEmailSent';
import SolarFlareSmsNotificationSent from './components/SolarFlareSmsSent';
import Unknown from './components/Unknown';
import SabalPaymentMethodAdded from './components/SabalPaymentMethodAdded';
import SabalPaymentFailed from './components/SabalPaymentFailed';
import SabalAccountMilestoneUpdated from './components/SabalAccountMilestoneUpdated';
import AccountStipulation from './components/AccountStipulation';
import AccountCancelledReactivated from './components/AccountCancelledReactivated';
import SabalSystemDesignUpdated from './components/SabalSystemDesignUpdated';
import SabalAddersUpdated from './components/SabalAddersUpdated';
import Attestation from './components/Attestation';
import IllinoisShinesSent from './components/IllinoisShinesSent';
import IllinoisShinesSigned from './components/IllinoisShinesSigned';
import MilestonePackageSavedSubmitted from './components/MilestonePackageSavedSubmitted';
import SabalHoldbackCreated from './components/HoldbackCreated';
import SabalHoldbackUpdated from './components/HoldbackUpdated';
import SabalPricingSettingUpdated from './components/PricingSettingUpdated';
import SabalPricingSettingCreated from './components/PricingSettingCreated';
import SabalPriceSheetsMappingsCreated from './components/PriceSheetsMappingsCreated';
import SabalPriceSheetsMappingsArchived from './components/PriceSheetMappingsArchived';
import SabalPriceSheetCreated from './components/PriceSheetCreated';
import SabalPriceSheetUpdated from './components/PriceSheetUpdated';
import SabalDocumentSubmittedOrResubmitted from './components/SabalDocumentSubmittedOrResubmitted';
import ActivationPackageSubmitted from './components/ActivationPackageSubmitted';
import ActivationPackageResubmitted from './components/ActivationPackageResubmitted';

export const getActivityHistoryComponent = (events: Array<any>, showUnknown = false) => {
  return events
    .map((event) => {
      switch (event.eventType) {
        case ActivityHistoryEventType.sabalDocumentsArchived:
          return {
            component: SabalDocumentArchived,
            icon: 'box',
            event,
          };
        case ActivityHistoryEventType.sabalDocumentsUploaded:
          return {
            component: SabalDocumentUploaded,
            icon: 'upload',
            event,
          };
        case ActivityHistoryEventType.sabalDocumentsRejected:
          return {
            component: SabalDocumentRejected,
            icon: 'remove',
            iconColor: 'danger',
            event,
          };
        case ActivityHistoryEventType.sabalDocumentsApproved:
          return {
            component: SabalDocumentApproved,
            icon: 'check',
            iconColor: 'success',
            event,
          };
        case ActivityHistoryEventType.sabalDocumentsSubmitted:
        case ActivityHistoryEventType.sabalDocumentsReSubmitted:
          return {
            component: SabalDocumentSubmittedOrResubmitted,
            icon: 'note',
            event,
          };
        case ActivityHistoryEventType.sabalAddersUpdated:
          return {
            component: SabalAddersUpdated,
            icon: 'pencil',
            event,
          };
        case ActivityHistoryEventType.sabalSystemDesignCreated:
          return {
            component: SabalSystemDesignUpdated,
            icon: 'pencil',
            event,
          };
        case ActivityHistoryEventType.sabalAccountCreated:
          return {
            component: SabalAccountCreated,
            icon: 'add',
            event,
          };
        case ActivityHistoryEventType.sabalAccountUpdated:
          return {
            component: SabalAccountUpdated,
            icon: 'pencil',
            event,
          };
        case ActivityHistoryEventType.heliosUserInvited:
          return {
            component: HeliosInvite,
            icon: 'mail',
            event,
          };
        case ActivityHistoryEventType.heliosUserCreated:
          return {
            component: HeliosCreated,
            icon: 'sun',
            event,
          };
        case ActivityHistoryEventType.contractSent:
          return {
            component: ContractSent,
            icon: 'document',
            event,
          };
        case ActivityHistoryEventType.contractSigned:
          return {
            component: ContractSigned,
            icon: 'document',
            event,
          };
        case ActivityHistoryEventType.contractVoided:
          return {
            component: ContractVoided,
            icon: 'document',
            event,
          };
        case ActivityHistoryEventType.contractCreated:
          return {
            component: ContractCreated,
            icon: 'document',
            event,
          };
        case ActivityHistoryEventType.emailNotificationSent:
          return {
            component: Email,
            icon: 'mail',
            event,
          };
        case ActivityHistoryEventType.illinoisDisclosureSent:
          return {
            component: IllinoisShinesSent,
            icon: 'document',
            event,
          };
        case ActivityHistoryEventType.illinoisDisclosureSigned:
          return {
            component: IllinoisShinesSigned,
            icon: 'document',
            event,
          };
        case ActivityHistoryEventType.solarFlareEmailNotificationSent:
          return {
            component: SolarFlareEmailNotificationSent,
            icon: 'mail',
            event,
          };
        case ActivityHistoryEventType.solarFlareSmsNotificationSent:
          return {
            component: SolarFlareSmsNotificationSent,
            icon: 'chat',
            event,
          };
        case ActivityHistoryEventType.sabalQuoteCreated:
          return {
            component: QuoteCreated,
            icon: 'note',
            event,
          };
        case ActivityHistoryEventType.sabalQuoteVoided:
          return {
            component: QuoteVoided,
            icon: 'note',
            event,
          };
        case ActivityHistoryEventType.sabalPaymentMethodAdded:
          return {
            component: SabalPaymentMethodAdded,
            icon: 'add',
            event,
          };
        case ActivityHistoryEventType.sabalPaymentFailed:
          return {
            component: SabalPaymentFailed,
            icon: 'remove',
            iconColor: 'danger',
            event,
          };
        case ActivityHistoryEventType.sabalAccountMilestoneUpdated:
          return {
            component: SabalAccountMilestoneUpdated,
            icon: 'note',
            event,
          };
        case ActivityHistoryEventType.sabalCreditDecisionCompleted:
          return {
            component: CreditDecision,
            icon: 'search',
            event,
          };
        case ActivityHistoryEventType.sabalCreditDecisionError:
          return {
            component: CreditError,
            icon: 'remove',
            iconColor: 'danger',
            event,
          };
        case ActivityHistoryEventType.sabalCreditDecisionExpired:
          return {
            component: CreditExpired,
            icon: 'remove',
            iconColor: 'danger',
            event,
          };
        case ActivityHistoryEventType.sabalDesignStipulationFlagged:
          return {
            component: AccountStipulation,
            icon: 'remove',
            iconColor: 'danger',
            event,
          };
        case ActivityHistoryEventType.sabalDesignStipulationCleared:
          return {
            component: AccountStipulation,
            icon: 'check',
            iconColor: 'success',
            event,
          };
        case ActivityHistoryEventType.sabalAccountCancelled:
          return {
            component: AccountCancelledReactivated,
            icon: 'c-remove',
            event,
          };
        case ActivityHistoryEventType.sabalAccountReactivated:
          return {
            component: AccountCancelledReactivated,
            icon: 'refresh',
            event,
          };
        case ActivityHistoryEventType.sabalSystemDesignAttested:
          return {
            component: Attestation,
            icon: 'note',
            event,
          };
        case ActivityHistoryEventType.sabalInstallPackageSaved:
        case ActivityHistoryEventType.sabalInstallPackageResubmitted:
        case ActivityHistoryEventType.sabalInstallPackageSubmitted:
          return {
            component: MilestonePackageSavedSubmitted,
            icon: 'note',
            event,
          };
        case SabalEventType.sabalHoldbackCreated:
          return {
            component: SabalHoldbackCreated,
            icon: 'note',
            event,
          };
        case SabalEventType.sabalHoldbackUpdated:
          return {
            component: SabalHoldbackUpdated,
            icon: 'note',
            event,
          };
        case SabalEventType.sabalModifierCreated:
          return {
            component: SabalPricingSettingCreated,
            icon: 'note',
            event,
          };
        case SabalEventType.sabalModifierUpdated:
          return {
            component: SabalPricingSettingUpdated,
            icon: 'note',
            event,
          };
        case SabalEventType.sabalConstructionAdderCreated:
          return {
            component: SabalPricingSettingCreated,
            icon: 'note',
            event,
          };
        case SabalEventType.sabalConstructionAdderUpdated:
          return {
            component: SabalPricingSettingUpdated,
            icon: 'note',
            event,
          };
        case SabalEventType.sabalPriceSheetsMappingsCreated:
          return {
            component: SabalPriceSheetsMappingsCreated,
            icon: 'note',
            event,
          };
        case SabalEventType.sabalPriceSheetsMappingsArchived:
          return {
            component: SabalPriceSheetsMappingsArchived,
            icon: 'note',
            event,
          };
        case SabalEventType.sabalPriceSheetCreated:
          return {
            component: SabalPriceSheetCreated,
            icon: 'note',
            event,
          };
        case SabalEventType.sabalPriceSheetUpdated:
          return {
            component: SabalPriceSheetUpdated,
            icon: 'note',
            event,
          };
        case ActivityHistoryEventType.sabalActivationPackageSubmitted:
          return {
            component: ActivationPackageSubmitted,
            icon: 'note',
            event,
          };
        case ActivityHistoryEventType.sabalActivationPackageResubmitted:
          return {
            component: ActivationPackageResubmitted,
            icon: 'note',
            event,
          };
        default:
          return !showUnknown
            ? undefined
            : {
                component: Unknown,
                icon: 'question-mark',
                event,
              };
      }
    })
    .filter((event) => !!event);
};
