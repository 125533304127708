import { Badge, Icon } from '@palmetto/palmetto-components';
import { Link } from 'react-router-dom';
import { PriceSheetStatus, ProgramType } from 'types';
import DateTimeDisplay from '../../DateTime';
import { currencyFormatter, kwhPriceFormatter } from '@/helpers/currencyFormatter';
import { formatKWHNumber, kWHFormatter } from '@/helpers/kwhFormatter';
import { head } from 'lodash';
import { render } from '@testing-library/react';
import { percentageFormatter } from '@/helpers/percentageFormatter';

export const priceSheetColumns =
  {
    [ProgramType.solar]: [
      {
        heading: 'Status',
        dataKey: 'status',
        width: 125,
        render: (_cell: any, row: any) => (
          <Badge
            variant={
              row.status === PriceSheetStatus.active
                ? 'success'
                : row.status === PriceSheetStatus.archived
                  ? 'warning'
                  : 'danger'
            }
            message={row.status}
          />
        ),
      },
      {
        heading: 'Name',
        dataKey: 'name',
        render: (_cell: any, row: any) => (
          <Link
            style={{ color: 'var(--color-text-body-primary)' }}
            className="font-size-md font-weight-bold"
            to={`/admin/pricing/${row.programType}/price-sheet/${row.id}`}
          >
            {row.name}
          </Link>
        ),
      },
      {
        heading: 'KWH Rate Floor',
        dataKey: 'kwhRateFloor',
        headerClassName: 'wrap',
      },
      {
        heading: 'KWH Rate Ceiling',
        dataKey: 'kwhRateCeiling',
        headerClassName: 'wrap',
      },
      {
        heading: 'Production Factor Floor',
        dataKey: 'productionFactorFloor',
        headerClassName: 'wrap',
      },
      {
        heading: 'Production Factor Ceiling',
        dataKey: 'productionFactorCeiling',
        headerClassName: 'wrap',
      },
      {
        heading: 'Created',
        dataKey: 'createdDate',
        width: 150,
        render: (_cell: any, row: Record<'createdDate', string>) => (
          <DateTimeDisplay value={row?.createdDate} dateFormat={'DD'} excludeTime />
        ),
      },
      {
        heading: 'Archived',
        dataKey: 'archivedDate',
        width: 150,
        render: (_cell: any, row: Record<'archivedDate', string>) => (
          <DateTimeDisplay value={row?.archivedDate} dateFormat={'DD'} excludeTime />
        ),
      },
      {
        render: (_cell: any, row: any) =>
          row.status !== 'archived' && (
            <Link
              to={`/admin/pricing/${row.programType}/price-sheet/edit/${row.id}`}
              style={{ textDecoration: 'underline' }}
            >
              <Icon color="body-primary" name="settings" size="sm" />
            </Link>
          ),
      },
    ],
    [ProgramType.hvac]: [],
    [ProgramType.doePr]: [
      {
        heading: 'Status',
        dataKey: 'status',
        width: 125,
        render: (_cell: any, row: any) => (
          <Badge
            variant={
              row.status === PriceSheetStatus.active
                ? 'success'
                : row.status === PriceSheetStatus.archived
                  ? 'warning'
                  : 'danger'
            }
            message={row.status}
          />
        ),
      },
      {
        heading: 'Name',
        dataKey: 'name',
        render: (_cell: any, row: any) => (
          <Link
            style={{ color: 'var(--color-text-body-primary)' }}
            className="font-size-md font-weight-bold"
            to={`/admin/pricing/${row.programType}/price-sheet/${row.id}`}
          >
            {row.name}
          </Link>
        ),
      },
      {
        heading: 'Min Battery Capacity',
        dataKey: 'minBatteryCapacity',
        headerClassName: 'wrap',
      },
      {
        heading: 'Max Battery Capacity',
        dataKey: 'maxBatteryCapacity',
        headerClassName: 'wrap',
      },
      {
        heading: 'Min System Size',
        dataKey: 'minSystemSize',
        headerClassName: 'wrap',
      },
      {
        heading: 'Max System Size',
        dataKey: 'maxSystemSize',
        headerClassName: 'wrap',
      },
      {
        heading: 'Created',
        dataKey: 'createdDate',
        width: 150,
        render: (_cell: any, row: Record<'createdDate', string>) => (
          <DateTimeDisplay value={row?.createdDate} dateFormat={'DD'} excludeTime />
        ),
      },
      {
        heading: 'Archived',
        dataKey: 'archivedDate',
        width: 150,
        render: (_cell: any, row: Record<'archivedDate', string>) => (
          <DateTimeDisplay value={row?.archivedDate} dateFormat={'DD'} excludeTime />
        ),
      },
      {
        render: (_cell: any, row: any) =>
          row.status !== 'archived' && (
            <Link
              to={`/admin/pricing/${row.programType}/price-sheet/edit/${row.id}`}
              style={{ textDecoration: 'underline' }}
            >
              <Icon color="body-primary" name="settings" size="sm" />
            </Link>
          ),
      },
    ],
  } || [];

export const priceSheetDetailColumns =
  {
    [ProgramType.solar]: [],
    [ProgramType.hvac]: [],
    [ProgramType.doePr]: [
      {
        heading: 'Escalation Rate',
        dataKey: 'escalationRate',
        headerClassName: 'wrap',
        render: (cell: any) => {
          return <div>{percentageFormatter.format(cell)}</div>;
        },
      },
      {
        heading: 'Min System Size (kW)',
        dataKey: 'minSystemSize',
        headerClassName: 'wrap',
        render: (cell: any) => {
          return <div>{formatKWHNumber(cell)}</div>;
        },
      },
      {
        heading: 'Max System Size (kW)',
        dataKey: 'maxSystemSize',
        headerClassName: 'wrap',
        render: (cell: any) => {
          return <div>{formatKWHNumber(cell)}</div>;
        },
      },
      {
        heading: 'Min Battery Capacity (kW)',
        dataKey: 'minBatteryCapacity',
        headerClassName: 'wrap',
        render: (cell: any) => {
          return <div>{kWHFormatter.format(cell)}</div>;
        },
      },
      {
        heading: 'Max Battery Capacity (kW)',
        dataKey: 'maxBatteryCapacity',
        headerClassName: 'wrap',
        render: (cell: any) => {
          return <div>{formatKWHNumber(cell)}</div>;
        },
      },
      {
        heading: 'Monthly Payment',
        dataKey: 'monthlyPayment',
        headerClassName: 'wrap',
        render: (cell: any) => {
          return <div>{currencyFormatter.format(cell)}</div>;
        },
      },
      {
        heading: 'PPW',
        dataKey: 'ppwRate',
        headerClassName: 'wrap',
        render: (cell: any) => {
          return <div>{currencyFormatter.format(cell)}</div>;
        },
      },
    ],
  } || [];
