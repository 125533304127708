import {
  Badge,
  BadgeVariant,
  Box,
  Button,
  Details,
  DimensionSize,
  toast,
  useOpenClose,
} from '@palmetto/palmetto-components';
import { AdderType, QuoteStatus } from '../../types/Quotes';
import { useVoidQuoteMutation } from '../../services/quotes';
import { currencyFormatter, kwhPriceFormatter } from '../../helpers/currencyFormatter';
import RequirePermissions from '../auth/requirePermissions';
import { isErrorWithData } from '../../services/helpers';
import { AdditionalCostAdderType, ConstructionAdderType, Quote } from 'types';
import { useSendContractMutation } from '../../services/contract';
import { getAdderTypeLabel } from '../../helpers/addersHelpers';

const getAdderLabel = (adder: AdderType, backupBatteryDetails: any): string => {
  if (adder === AdderType.backupBattery && backupBatteryDetails?.pricingMethod === 'kwhRate') {
    return getAdderTypeLabel(AdderType.backupBatteryKwh) ?? adder;
  }

  return getAdderTypeLabel(adder) ?? adder;
};

interface QuoteListItemProps {
  item: Quote;
}

interface ListItemElementProps {
  label: string;
  value: string;
}

export function ListItemElement({ label, value }: ListItemElementProps) {
  return (
    <Box direction="row" padding="0 lg" gap="5xl">
      <Box width={{ base: 'xs' as DimensionSize, tablet: 'lg' }} />
      <Box borderWidth="0 0 xs 0" borderColor="separator" flex="auto" direction="row" padding="xs 0">
        <Box flex="auto">{label}</Box>
        <Box>{value}</Box>
      </Box>
    </Box>
  );
}

export function QuoteListItem({ item }: QuoteListItemProps): JSX.Element {
  const {
    totalAmountPaid,
    kwhPrice,
    systemPricingDetails,
    totalSystemCost,
    pricePerWatt,
    productName,
    status,
    adders,
    additionalCostAdders,
    backupBatteryDetails,
  } = item;
  const showVoidButton = status === QuoteStatus.active || status === QuoteStatus.contracted;
  const [voidQuote, { isLoading: isVoidingQuote }] = useVoidQuoteMutation({
    fixedCacheKey: 'useVoidQuoteMutationKey',
  });
  const [_sendContract, { isLoading: isSendingContract }] = useSendContractMutation({
    fixedCacheKey: 'useSendContractMutationKey',
  });
  const { isOpen: isDetailsOpen, handleToggle: handleDetailsToggle } = useOpenClose({
    defaultIsOpen: showVoidButton,
  });
  const quoteStatus: {
    [key in QuoteStatus]: {
      label: string;
      variant: BadgeVariant;
    };
  } = {
    [QuoteStatus.contracted]: {
      label: 'Contracted',
      variant: 'success',
    },
    [QuoteStatus.active]: {
      label: 'Active',
      variant: 'success',
    },
    [QuoteStatus.voided]: {
      label: 'Voided',
      variant: 'warning',
    },
  };
  const getAdderList = (adders?: string[]) => {
    return adders ? adders.map((adder) => getAdderLabel(adder as AdderType, backupBatteryDetails)).join(', ') : '';
  };
  const handleVoidQuote = async () => {
    const toastId = toast.loading('Voiding quote');

    try {
      const confirmed = window.confirm('Are you sure you want to Void this Quote? This action cannot be undone.');

      if (!confirmed) return;
      await voidQuote({ accountId: item.accountId, quoteId: item.id }).unwrap();
      toast.success('Quote voided successfully');
    } catch (e) {
      if (isErrorWithData(e)) {
        const errorMessage = e.data.message;
        toast.error(errorMessage);
      } else {
        console.error(e);
        toast.error('Error voiding quote');
      }
    } finally {
      toast.dismiss(toastId);
    }
  };

  const hasBackupBattery = adders ? adders.includes(AdderType.backupBattery) : false;
  const hasSolarReadiness = adders ? adders.includes(ConstructionAdderType.solarReadiness) : false;
  const totalBackupBatteryCost = additionalCostAdders?.find(
    (adder: any) => adder.type === AdderType.backupBattery,
  )?.cost;
  const totalAdderCost =
    additionalCostAdders?.reduce((acc: number, adder: any) => {
      acc += adder.cost;
      return acc;
    }, 0) ?? 0;
  const solarReadinessCost = hasSolarReadiness
    ? additionalCostAdders.find((element) => element.type === AdditionalCostAdderType.solarReadiness)?.cost || 0
    : 0;
  const title = `${productName}, ${kwhPriceFormatter.format(kwhPrice)}/kWh`;
  return (
    <Details isOpen={isDetailsOpen} gap="sm" borderWidth="xs 0 0 0" borderColor="separator">
      <Details.Summary
        isDetailsOpen={isDetailsOpen}
        onToggle={showVoidButton ? undefined : handleDetailsToggle}
        display="inline"
        cursor={'pointer'}
        width="100"
      >
        <Box width="100" gap="5xl-tablet 5xl-desktop" columnGap={'md'} direction="row" padding={'sm lg'}>
          <Box width="lg">
            <Badge
              message={quoteStatus[status as keyof typeof QuoteStatus]?.label}
              variant={quoteStatus[status as keyof typeof QuoteStatus]?.variant}
              style={{ width: 'fit-content' }}
            />
          </Box>
          <Box gap="xs" flex="auto">
            <Box>{title || '---'}</Box>
            <Box fontSize="xs" color="body-secondary">
              {getAdderList(adders)}
            </Box>
          </Box>
          <Box display={'flex'}>
            {showVoidButton ? (
              <RequirePermissions permissions={['admin', 'editor']} checkAllPermissions={false}>
                <Button
                  size="sm"
                  variant="secondary"
                  tone="danger"
                  disabled={isVoidingQuote || isSendingContract}
                  onClick={handleVoidQuote}
                >
                  Void
                </Button>
              </RequirePermissions>
            ) : (
              <Box fontSize="xs">{isDetailsOpen ? 'Hide Details' : 'Show Details'}</Box>
            )}
          </Box>
        </Box>
      </Details.Summary>

      <Box padding="0 0 lg 0">
        {<ListItemElement label="Solar-Only Rate ($/kWh)" value={kwhPriceFormatter.format(kwhPrice)} />}
        {
          <ListItemElement
            label="Estimated Solar-Only Year-1 Monthly Payment"
            value={currencyFormatter.format(systemPricingDetails[0].monthlyPayment)}
          />
        }
        {<ListItemElement label="Solar-only EPC Rate ($/W)" value={currencyFormatter.format(pricePerWatt)} />}
        {hasSolarReadiness && (
          <ListItemElement label="Solar Readiness" value={currencyFormatter.format(solarReadinessCost)} />
        )}
        {
          <ListItemElement
            label="Solar-only EPC Total Payment"
            value={currencyFormatter.format(totalSystemCost - totalAdderCost)}
          />
        }
        {hasBackupBattery && (
          <>
            <ListItemElement
              label="Estimated Battery Year-1 Monthly Payment"
              value={currencyFormatter.format(systemPricingDetails[0].backupBatteryPayment || 0)}
            />
            {totalBackupBatteryCost && (
              <ListItemElement
                label="Backup Battery Total Cost"
                value={currencyFormatter.format(totalBackupBatteryCost)}
              />
            )}
          </>
        )}
        {hasBackupBattery && (
          <ListItemElement label="Total EPC Payment" value={currencyFormatter.format(totalSystemCost)} />
        )}
        <ListItemElement
          label="Total Solar Lease Monthly Payments"
          value={currencyFormatter.format(totalAmountPaid)}
        />
      </Box>
    </Details>
  );
}
