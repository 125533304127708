import { Box } from '@palmetto/palmetto-components';
import PaymentSchedule from './PaymentSchedule';
import BankAccount from './BankAccount';
import { MilestoneType } from 'types';
import { OrganizationNode } from '../../../../types/Organizations';
import { useGetOrganizationPaymentScheduleQuery } from '../../../../services/organizations';

export interface paymentSetting {
  milestone: MilestoneType;
  paymentPercent: number;
  rebate: number;
}
interface PaymentsProps {
  organization: OrganizationNode;
  isLoading?: boolean;
}

const Payments = ({ organization }: PaymentsProps) => {
  const { data: paymentSchedule, isLoading: isLoadingPaymentSchedule } =
    useGetOrganizationPaymentScheduleQuery(organization);
  return (
    <Box
      childGap={{
        base: 'sm',
        tablet: 'md',
        desktop: 'lg',
      }}
      direction="row"
      alignItems="center"
      padding="lg 0 0 0"
    >
      <Box flex="auto" width="50%">
        <PaymentSchedule
          organization={organization}
          isLoading={isLoadingPaymentSchedule}
          vendorPaymentSchedule={paymentSchedule}
        />
      </Box>
      <Box flex="auto" width="50%">
        <BankAccount organization={organization} />
      </Box>
    </Box>
  );
};

export default Payments;
