import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { AppliedDataFilter, DataFilterOperation } from '../filters/FilterButton';
import { FilterBar } from '../filters';
import { AddFilterButtonDropdown } from '../filters/AddFilterButton';
import { getStateOptions } from '../../helpers/getStateOptions';
import { DataFilterType } from '../filters/SearchableCheckboxFilterList';
import { LazyQueryTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';

export const MarketFilter = ({ trigger }: { trigger: LazyQueryTrigger<any> }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [appliedFilters, setAppliedFilters] = useState<Array<any>>([]);
  const advancedFilters = searchParams.get('advancedFilters');
  const searchTerm = searchParams.get('search') || undefined;
  const currentPage = searchParams.get('page');
  useEffect(() => {
    const pageNum = currentPage ? Number(currentPage) : 1;
    const filters = advancedFilters || JSON.stringify([]);
    setAppliedFilters(JSON.parse(filters));
    trigger({ pageNum, advancedFilters: filters, searchTerm });
  }, [trigger, currentPage, advancedFilters, searchTerm]);

  const stateFilter = useMemo(() => {
    const states = getStateOptions(true);
    return {
      id: 'state',
      label: 'State',
      type: DataFilterType.Select,
      selectOptions: states,
      operations: [DataFilterOperation.Equal, DataFilterOperation.NotEqual],
    };
  }, []);

  const onSearchChange = (searchTerm: string) => {
    if (searchTerm && searchTerm !== '') {
      searchParams.set('search', searchTerm);
    } else {
      searchParams.delete('search');
    }
    searchParams.delete('page');
    setSearchParams(searchParams);
  };
  const handleApplyAdvancedFilters = useCallback(
    (filtersToApply: AppliedDataFilter[]) => {
      if (filtersToApply.some((filter) => filter.selectedValues && filter.selectedValues.length)) {
        searchParams.set('advancedFilters', JSON.stringify(filtersToApply));
        setSearchParams(searchParams);
      }
      searchParams.delete('page');
      setAppliedFilters(filtersToApply);
    },
    [setAppliedFilters, searchParams, setSearchParams],
  );
  const handleRemoveAdvancedFilter = useCallback(
    (filterId: string) => {
      const newFilters = appliedFilters.filter((filter) => filter.id !== filterId);
      if (newFilters.length) {
        searchParams.set('advancedFilters', JSON.stringify(newFilters));
      } else {
        searchParams.delete('advancedFilters');
      }
      searchParams.delete('page');
      setSearchParams(searchParams);
      setAppliedFilters(newFilters);
    },
    [appliedFilters, setAppliedFilters, searchParams, setSearchParams],
  );
  const handleClearAdvancedFilters = useCallback(() => {
    searchParams.delete('advancedFilters');
    searchParams.delete('page');
    setSearchParams(searchParams);
    setAppliedFilters([]);
  }, [setAppliedFilters, searchParams, setSearchParams]);
  return (
    <FilterBar
      filtersState={{
        searchTerm,
      }}
      handleSearchTerm={(searchTerm: string) => onSearchChange(searchTerm)}
      placeholder="Search by utility"
    >
      <AddFilterButtonDropdown
        dataFilters={[stateFilter]}
        appliedFilters={appliedFilters}
        onRemoveFilter={handleRemoveAdvancedFilter}
        onApplyFilters={handleApplyAdvancedFilters}
        onClearFilters={handleClearAdvancedFilters}
      />
    </FilterBar>
  );
};
