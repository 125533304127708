import { Box, Button, Card, Column, Table, useBreakpoint, useOpenClose } from '@palmetto/palmetto-components';
import { useParams } from 'react-router-dom';
import { ModifierType, ProgramType, modifierMap } from 'types';
import { useLazyGetModifiersQuery } from '../../../services/pricing';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { kwhPriceFormatter } from '../../../helpers/currencyFormatter';
import { CreateModifierModal } from './CreateModifierModal';
import RequirePalmettoFinanceUser from '../../auth/RequirePalmettoFinanceUser';
import RequirePermissions from '../../auth/requirePermissions';
import { EditModifierModal } from './EditModifierModal';
import { percentageFormatter } from '../../../helpers/percentageFormatter';

const formatECData = (energyCommunitySetting: any) =>
  energyCommunitySetting?.percent
    ? `${percentageFormatter.format(energyCommunitySetting.percent)}`
    : `${kwhPriceFormatter.format(energyCommunitySetting?.perWatt)}/W`;

export const SpecificModifier = () => {
  const { isPhone } = useBreakpoint();
  const [selected, setSelected] = useState<any>(null);
  const { programType, modifierType } = useParams<{ programType: string; modifierType: ModifierType }>();
  const [trigger, { data = [], isLoading, isFetching }] = useLazyGetModifiersQuery();
  const { isOpen: isEditModalOpen, handleOpen: openEditModal, handleClose: closeEditModal } = useOpenClose();
  const { isOpen: isModalOpen, handleOpen: openModal, handleClose: closeModal } = useOpenClose();

  const handleClick = useCallback(
    (row: any) => {
      setSelected(row);
      openEditModal();
    },
    [openEditModal],
  );

  const columnConfig = useMemo(() => {
    switch (modifierType) {
      case ModifierType.energyCommunity:
        return [
          {
            heading: 'Name',
            dataKey: 'name',
            render: (_cell: any, row: any) => (
              <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => handleClick(row)}>
                {row.name}
              </span>
            ),
          },
          { heading: 'Coal Closure', render: (_cell: any, row: any) => formatECData(row.data.coalTract) },
          { heading: 'Fossil Fuel Employment', render: (_cell: any, row: any) => formatECData(row.data.fossilFuel) },
          { heading: 'Both', render: (_cell: any, row: any) => formatECData(row.data.combined) },
        ];
      case ModifierType.ppwAdjustment:
        return [
          {
            heading: 'Name',
            dataKey: 'name',
            render: (_cell: any, row: any) => (
              <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => handleClick(row)}>
                {row.name}
              </span>
            ),
          },
          { heading: 'Adjustment Amount', render: (_cell: any, row: any) => row.data.amount },
        ];
      default:
        return [];
    }
  }, [handleClick, modifierType]);

  useEffect(() => {
    if (modifierType) {
      trigger({ type: modifierType, programType: programType as ProgramType });
    }
  }, [modifierType, trigger]);

  return (
    <>
      <CreateModifierModal isModalOpen={isModalOpen} closeModal={closeModal} modifierType={modifierType} />
      <EditModifierModal
        isModalOpen={isEditModalOpen}
        closeModal={closeEditModal}
        id={selected?.id}
        name={selected?.name}
      />
      <Card padding="sm">
        <Card.Header>
          <Box direction="row" justifyContent="space-between">
            <Box fontSize="md" fontWeight="medium">
              {modifierMap[modifierType as ModifierType]}
            </Box>
            <RequirePalmettoFinanceUser>
              <RequirePermissions permissions={['pricingAdmin']}>
                <Button
                  as="button"
                  variant="primary"
                  iconPrefix="add"
                  size={{ base: 'sm', desktop: 'md' }}
                  aria-label="create modifier"
                  onClick={openModal}
                >
                  {!isPhone && 'Option'}
                </Button>
              </RequirePermissions>
            </RequirePalmettoFinanceUser>
          </Box>
        </Card.Header>
        <Table rowKey="id" columns={columnConfig as Column[]} rows={data} isLoading={isLoading || isFetching} />
      </Card>
    </>
  );
};
