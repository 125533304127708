import { FC, useEffect, useState } from 'react';
import {
  Box,
  Button,
  SelectInputNative,
  Popover,
  useBreakpoint,
  TextInputSize,
} from '@palmetto/palmetto-components';
import classNames from 'classnames';
import { DataFilterType, DropdownOption, SearchableCheckboxFilterList } from './SearchableCheckboxFilterList';
import styles from './FilterButton.module.scss';

export enum DataFilterOperation {
  Equal = 'Equal',
  NotEqual = 'NotEqual',
}

export interface AppliedDataFilter {
  id: string;
  selectedValues?: string[];
  operation?: DataFilterOperation;
}

export interface FilterChange {
  filterId: string;
  selectedValues?: string[];
  operation?: DataFilterOperation;
}

export interface DataFilterDisplay {
  id: string;
  label: string;
  description?: string;
  type: DataFilterType;
  displayValuePrefix?: string;
  displayValueSuffix?: string;
  operations: DataFilterOperation[];
  selectOptions?: DropdownOption[];
}

export const DataFilterOperationDisplay = {
  Equal: { label: 'is' },
  NotEqual: { label: 'is not' },
} as const;

interface FilterButtonProps {
  className?: string;
  size?: TextInputSize;
  appliedFilter: AppliedDataFilter;
  dataFilter: DataFilterDisplay;
  onRemoveFilter: (filterId: string) => void;
  onApplyFilters: () => void;
  onFilterChange: (change: FilterChange) => void;
  startOpen?: boolean;
}

export const FilterButton: FC<FilterButtonProps> = ({
  className,
  size = 'sm',
  appliedFilter,
  dataFilter,
  onRemoveFilter,
  onFilterChange,
  onApplyFilters,
  startOpen = false,
}) => {
  const { isPhone } = useBreakpoint();
  const [isPopoverOpen, setPopoverOpen] = useState(startOpen);
  const [removePopoverOpen, setRemovePopoverOpen] = useState(false);

  useEffect(() => {
    setPopoverOpen(startOpen);
  }, [startOpen]);

  const { id: appliedFilterId, operation, selectedValues } = appliedFilter;

  const {
    operations = [],
    label,
    selectOptions = [],
    displayValuePrefix,
    displayValueSuffix,
    type,
  } = dataFilter ?? {};

  const togglePopover = () => {
    setPopoverOpen(!isPopoverOpen);
  };

  const handleDone = () => {
    onApplyFilters();
    setPopoverOpen(false);
  };

  const isMissingInputValue = !isPopoverOpen && !selectedValues?.length;

  const labelContent = (
    <>
      {label}{' '}
      <span className="font-weight-regular">
        {`${!isMissingInputValue && operation ? DataFilterOperationDisplay[operation].label : 'is'} ${
          isMissingInputValue
            ? 'missing value'
            : `${displayValuePrefix ?? ''}${
                selectedValues
                  ?.map((item) => selectOptions.find((option) => option.value === item)?.label)
                  ?.join(', ') || ''
              }${displayValueSuffix || ''}`
        }`}
      </span>
    </>
  );

  const operationOptions = operations?.map((op) => ({
    value: op,
    label: DataFilterOperationDisplay[op].label,
  }));

  return (
    <Box
      direction="row"
      position="relative"
      className={classNames(styles.appliedFilter, className)}
      margin={{ base: '0 sm sm 0', tablet: '0 2xs 2xs 0' }}
    >
      <Popover
        trapFocus
        hasArrow={false}
        offsetFromTarget={2}
        content={
          <>
            <Box
              padding="sm"
              borderColor="separator"
              borderWidth="0 0 xs 0"
              maxHeight={{ base: '3xl', tablet: '4xl' }}
              childGap="sm"
            >
              <SelectInputNative
                id="defaultSelect"
                label="choose a condition"
                hideLabel
                options={operationOptions}
                placeholder="choose..."
                size={size}
                value={operation || DataFilterOperation.Equal}
                onChange={(event) => {
                  const selectedOperation = event.target.value as DataFilterOperation;
                  onFilterChange({
                    filterId: appliedFilterId,
                    selectedValues: selectedValues,
                    operation: selectedOperation,
                  });
                }}
              />
              {/* multi-select from known list of options */}
              <Box overflow="auto" childGap="sm">
                {[DataFilterType.Select].includes(type) && (
                  <SearchableCheckboxFilterList
                    selectOptions={selectOptions}
                    filterType={type}
                    selectedValues={selectedValues}
                    handleChange={(event) => {
                      let values = selectedValues ?? [];

                      if (event.target.checked) {
                        values.push(event.target.id);
                      } else {
                        values = values.filter((selection) => selection !== event.target.id);
                      }

                      onFilterChange({
                        filterId: appliedFilterId,
                        selectedValues: values,
                        operation,
                      });
                    }}
                  />
                )}
              </Box>
            </Box>
            <Box justifyContent="center" background="primary" padding="sm">
              <Button variant="primary" size={size} onClick={handleDone} className="shadow-sm">
                Done
              </Button>
            </Box>
          </>
        }
        isOpen={isPopoverOpen}
        placement="bottom-start"
        contentContainerProps={{
          padding: '0',
          width: '318px',
          shadow: 'lg',
          radius: 'sm',
          overflow: 'hidden',
          background: 'secondary',
        }}
        onClickOutside={handleDone}
        portalTarget={document.body}
        withPortal
      >
        <Button
          variant="secondary"
          tone="neutral"
          size={size}
          onClick={togglePopover}
          className={classNames(styles.appliedFilterBtn, 'shadow-ws')}
          style={{
            backgroundColor: isMissingInputValue ? 'var(--color-background-danger)' : undefined,
          }}
        >
          {labelContent}
        </Button>
      </Popover>
      <Popover
        isOpen={removePopoverOpen}
        content="remove filter"
        placement="top"
        contentContainerProps={{
          padding: 'xs',
          background: 'primary',
          radius: 'sm',
          fontSize: 'xs',
          color: 'body-primary',
          fontWeight: 'bold',
        }}
        portalTarget={document.body}
        withPortal
      >
        <Button
          aria-label={`clear ${label} filter`}
          variant="secondary"
          tone="neutral"
          size={size}
          iconPrefix="remove"
          onClick={() => onRemoveFilter(appliedFilterId)}
          onMouseOver={() => setRemovePopoverOpen(true)}
          onMouseOut={() => setRemovePopoverOpen(false)}
          className={classNames(styles.appliedFilterRemove, {
            'shadow-xs': isPhone,
          })}
        />
      </Popover>
    </Box>
  );
};
