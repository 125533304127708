import { currencyFormatter, kwhPriceFormatter } from '@/helpers/currencyFormatter';
import { percentageFormatter } from '@/helpers/percentageFormatter';
import { useGetHoldbacksQuery, useGetModifiersQuery } from '@/services/pricing';
import { Box, SelectInput } from '@palmetto/palmetto-components';
import { useFormikContext } from 'formik';
import { ProgramType } from 'types';
import { sortByLabel } from './util';
import { isFieldSelected } from './MappingFormFactory';
import { useEffect, useMemo } from 'react';

const energyCommunityMap: Record<string, string> = {
  coalTract: 'Coal Tract',
  fossilFuel: 'Fossil Fuel',
  combined: 'Both',
};

const formatModifierValue = (type: string, value: number) => {
  switch (type) {
    case 'percent':
      return percentageFormatter.format(value);
    case 'perWatt':
      return `${kwhPriceFormatter.format(value)}/W`;
    default:
      return value;
  }
};

const SolarModifiersForm = ({ initialValues }: { initialValues: Record<any, any> }) => {
  const formik = useFormikContext<any>();
  const { data: holdbacks = [] } = useGetHoldbacksQuery({ programType: ProgramType.solar });
  const { data: energyCommunityAdders = [] } = useGetModifiersQuery({
    type: 'energyCommunity',
    programType: ProgramType.solar,
  });
  const { data: ppwModifiers = [] } = useGetModifiersQuery({
    type: 'ppwAdjustment',
    programType: ProgramType.solar,
  });
  const energyCommunityOptions = energyCommunityAdders
    .map((ec: any) => ({ label: ec.name, value: ec.id }))
    .sort(sortByLabel);
  const holdbackOptions = holdbacks.map((hb: any) => ({ label: hb.name, value: hb.id })).sort(sortByLabel);
  const ppwModifierOptions = ppwModifiers.map((ppw: any) => ({ label: ppw.name, value: ppw.id })).sort(sortByLabel);

  useEffect(() => {
    if (
      initialValues.holdback &&
      holdbacks.length &&
      !formik.values.selectedHoldback &&
      !formik.touched.selectedHoldback
    ) {
      const selectedHoldback = holdbacks.find((hb: any) => hb.id === initialValues.holdback.value);
      formik.setFieldValue('selectedHoldback', selectedHoldback);
      formik.setFieldTouched('selectedHoldback', true);
    }
    if (
      initialValues.energyCommunity &&
      energyCommunityAdders.length &&
      !formik.values.selectedEnergyCommunity &&
      !formik.touched.selectedEnergyCommunity
    ) {
      const selectedEnergyCommunity = energyCommunityAdders.find(
        (ec: any) => ec.id === initialValues.energyCommunity.value,
      );
      formik.setFieldValue('selectedEnergyCommunity', selectedEnergyCommunity);
      formik.setFieldTouched('selectedEnergyCommunity', true);
    }
    if (
      initialValues.ppwModifier &&
      ppwModifiers.length &&
      !formik.values.selectedPpwModifier &&
      !formik.touched.selectedPpwModifier
    ) {
      const selectedPpwModifier = ppwModifiers.find((ppw: any) => ppw.id === initialValues.ppwModifier.value);
      formik.setFieldValue('selectedPpwModifier', selectedPpwModifier);
      formik.setFieldTouched('selectedPpwModifier', true);
    }
  }, [initialValues, holdbacks, energyCommunityAdders, ppwModifiers, formik.values, formik.touched]);

  const energyCommunityValues = useMemo(() => {
    if (!formik.values.selectedEnergyCommunity) {
      return {};
    }
    return Object.keys(formik.values.selectedEnergyCommunity.data).reduce(
      (acc, key) => {
        acc[key] = acc[key] || {};
        const [format] = Object.keys(formik.values.selectedEnergyCommunity.data[key]).filter(
          (value) => formik.values.selectedEnergyCommunity.data[key][value],
        );
        acc[key].format = format;
        acc[key].value = formik.values.selectedEnergyCommunity.data[key][format];
        return acc;
      },
      {} as Record<string, any>,
    );
  }, [formik.values.selectedEnergyCommunity]);
  return (
    <Box childGap="xl" radius="md" borderWidth="xs" borderColor="separator" padding="lg">
      <Box as="h3" fontWeight="medium" fontSize="md">
        Modifiers
      </Box>
      <Box childGap="xl">
        <Box direction={{ desktop: 'row', base: 'column' }} childGap={{ desktop: '4xl', base: 'sm' }}>
          <Box width={{ desktop: '3xl', base: 'auto' }} padding={{ desktop: '0 0 lg 0', base: '0 0 xs 0' }}>
            <SelectInput
              id="holdback"
              name="holdback"
              label="Holdback"
              value={formik.values.holdback}
              onChange={(event) => {
                const selectedOption = event.target.value;
                formik.setFieldValue('holdback', selectedOption);
                const selectedHoldback = isFieldSelected(selectedOption)
                  ? holdbacks.find((hb: any) => hb.id === selectedOption.value)
                  : null;
                formik.setFieldValue('selectedHoldback', selectedHoldback);
                formik.setFieldTouched('selectedHoldback', true);
              }}
              options={holdbackOptions}
              onBlur={formik.handleBlur}
              isClearable
              isDisabled={formik.isSubmitting}
              error={formik.touched.holdback && (formik.errors.holdback as string)}
            />
          </Box>
          {formik.values.selectedHoldback && (
            <Box
              radius="md"
              borderWidth="xs"
              borderColor="separator"
              padding="sm lg"
              flex="auto"
              width="auto"
              alignSelf={{ base: 'flex-start', desktop: 'center' }}
            >
              <Box>{currencyFormatter.format(formik.values.selectedHoldback.amount)}/W</Box>
            </Box>
          )}
        </Box>
        <Box direction={{ desktop: 'row', base: 'column' }} childGap={{ desktop: '4xl', base: 'sm' }}>
          <Box width={{ desktop: '3xl', base: 'auto' }} padding={{ desktop: '0 0 lg 0', base: '0 0 xs 0' }}>
            <SelectInput
              id="energyCommunity"
              name="energyCommunity"
              label="Energy Community"
              value={formik.values.energyCommunity}
              onChange={(event) => {
                const selectedOption = event.target.value;
                formik.setFieldValue('energyCommunity', selectedOption);
                const selectedEnergyCommunity = isFieldSelected(selectedOption)
                  ? energyCommunityAdders.find((ec: any) => ec.id === selectedOption.value)
                  : null;
                formik.setFieldValue('selectedEnergyCommunity', selectedEnergyCommunity);
                formik.setFieldTouched('selectedEnergyCommunity', true);
              }}
              options={energyCommunityOptions}
              onBlur={formik.handleBlur}
              isClearable
              isDisabled={formik.isSubmitting}
              error={formik.touched.energyCommunity && (formik.errors.energyCommunity as string)}
            />
          </Box>
          {formik.values.selectedEnergyCommunity && (
            <Box
              radius="md"
              borderWidth="xs"
              borderColor="separator"
              padding="sm lg"
              flex="auto"
              width="auto"
              gap="4xl"
              direction="row"
              alignSelf={{ base: 'flex-start', desktop: 'center' }}
            >
              {Object.keys(energyCommunityValues).map((key) => (
                <Box childGap="sm">
                  <Box fontSize="xs" fontWeight="bold">
                    {energyCommunityMap[key]}
                  </Box>
                  <Box>
                    {formatModifierValue(energyCommunityValues[key].format, energyCommunityValues[key].value)}
                  </Box>
                </Box>
              ))}
            </Box>
          )}
        </Box>

        <Box direction={{ desktop: 'row', base: 'column' }} childGap={{ desktop: '4xl', base: 'sm' }}>
          <Box width={{ desktop: '3xl', base: 'auto' }} padding={{ desktop: '0 0 lg 0', base: '0 0 xs 0' }}>
            <SelectInput
              id="ppwModifier"
              name="ppwModifier"
              label="PPW Modifier"
              value={formik.values.ppwModifier}
              onChange={(event) => {
                const selectedOption = event.target.value;
                formik.setFieldValue('ppwModifier', selectedOption);
                const selectedPpwModifier = isFieldSelected(selectedOption)
                  ? ppwModifiers.find((ppw: any) => ppw.id === selectedOption.value)
                  : null;
                formik.setFieldValue('selectedPpwModifier', selectedPpwModifier);
                formik.setFieldTouched('selectedPpwModifier', true);
              }}
              options={ppwModifierOptions}
              onBlur={formik.handleBlur}
              isClearable
              isDisabled={formik.isSubmitting}
              error={formik.touched.ppwModifier && (formik.errors.ppwModifier as string)}
            />
          </Box>
          {formik.values.selectedPpwModifier && (
            <Box
              radius="md"
              borderWidth="xs"
              borderColor="separator"
              padding="sm lg"
              flex="auto"
              width="auto"
              alignSelf={{ base: 'flex-start', desktop: 'center' }}
            >
              <Box>{kwhPriceFormatter.format(formik.values.selectedPpwModifier.data.amount)} /kWh</Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export const ModifiersForm = ({
  programType,
  initialValues,
}: {
  programType: ProgramType;
  initialValues: Record<any, any>;
}) => {
  switch (programType) {
    case ProgramType.solar:
      return <SolarModifiersForm initialValues={initialValues} />;
    default:
      return null;
  }
};
