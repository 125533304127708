import {
  Alert,
  Box,
  Button,
  FormLabel,
  FormikRadioGroup,
  FormikTextInput,
  Modal,
} from '@palmetto/palmetto-components';
import { useState, useCallback } from 'react';
import { Form, Formik, Field } from 'formik';
import { useUpdateBankAccountMutation } from '../../../../services/organizations';
import * as yup from 'yup';

interface BankAccountModalProps {
  isModalOpen: boolean;
  closeModal: any;
  alias: string;
  accountingEmail?: string;
}

const bankAccountRegistrationSchema = yup.object({
  email: yup.string().email().required('Email is required'),
  routingNumber: yup
    .string()
    .required('Routing number is required')
    .length(9, 'Routing number must be 9 digits')
    .matches(/^[0-9]+$/, 'Routing number must be numeric'),
  confirmRoutingNumber: yup
    .string()
    .required('Routing number confirmation is required')
    .oneOf([yup.ref('routingNumber'), null as any], 'Routing numbers must match'),
  accountNumber: yup
    .string()
    .length(12, 'Account number must be 12 digits')
    .required('Account number is required')
    .matches(/^[0-9]+$/, 'Account number must be numeric'),
  confirmAccountNumber: yup
    .string()
    .required('Account number confirmation is required')
    .oneOf([yup.ref('accountNumber'), null as any], 'Account numbers must match'),
  bankAccountType: yup.string().required('Account type is required'),
});

const BankAccountModal = ({ isModalOpen, closeModal, alias, accountingEmail }: BankAccountModalProps) => {
  const [submissionError, setSubmissionError] = useState(null);
  const [updateBankAccountMutation] = useUpdateBankAccountMutation();
  const onDismiss = useCallback(
    (resetForm: any) => {
      setSubmissionError(null);
      closeModal();
      resetForm?.();
    },
    [closeModal, setSubmissionError],
  );

  const initialValues = {
    email: accountingEmail || '',
    routingNumber: '',
    confirmRoutingNumber: '',
    accountNumber: '',
    confirmAccountNumber: '',
    bankAccountType: '',
  };

  const save = useCallback(
    async (values: any, { resetForm }: any) => {
      try {
        const paymentAccount = {
          bankAccount: {
            ...values,
          },
        };
        await updateBankAccountMutation({ paymentAccount, alias }).unwrap();
        onDismiss(resetForm);
      } catch (err: any) {
        setSubmissionError(err?.data?.message ?? 'An error occurred');
      }
    },
    [alias, updateBankAccountMutation, onDismiss],
  );
  return (
    <Formik initialValues={initialValues} onSubmit={save} validationSchema={bankAccountRegistrationSchema}>
      {({ submitForm, isSubmitting, resetForm }) => (
        <Form>
          <Modal
            ariaLabelledBy="titleBasic"
            isOpen={isModalOpen}
            onDismiss={closeModal}
            fullScreenMobile
            maxWidth="640px"
          >
            <Modal.Header id="titleBasic" title="Bank ACH Information" onDismiss={() => onDismiss(resetForm)} />
            <Modal.Body background="secondary">
              <Box padding="sm" childGap="md">
                <Field component={FormikTextInput} id="email" name="email" label="Account holder email" />
                <Field
                  component={FormikTextInput}
                  id="routingNumber"
                  name="routingNumber"
                  label="Routing number"
                  placeholder="110000000"
                />
                <Field
                  component={FormikTextInput}
                  id="confirmRoutingNumber"
                  name="confirmRoutingNumber"
                  label="Confirm routing number"
                  placeholder="110000000"
                />
                <Field
                  component={FormikTextInput}
                  id="accountNumber"
                  name="accountNumber"
                  label="Account number"
                  placeholder="000123456789"
                />
                <Field
                  component={FormikTextInput}
                  id="confirmAccountNumber"
                  name="confirmAccountNumber"
                  label="Confirm account number"
                  placeholder="000123456789"
                />
                <Box childGap="sm">
                  <FormLabel inputId="bankAccountType">Account Type</FormLabel>
                  <Field
                    component={FormikRadioGroup}
                    id="bankAccountType"
                    label="Bank Account Type"
                    name="bankAccountType"
                    options={[
                      { id: 'checking', value: 'checking', label: 'Checking' },
                      { id: 'savings', value: 'savings', label: 'Savings' },
                    ]}
                  />
                </Box>
              </Box>
              {submissionError && (
                <Box margin="md 0">
                  <Alert variant="danger" hasIcon message={submissionError} />
                </Box>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button
                size="md"
                type="button"
                variant="secondary"
                tone="neutral"
                onClick={() => onDismiss(resetForm)}
              >
                Cancel
              </Button>
              <Button size="md" type="submit" isLoading={isSubmitting} variant="primary" onClick={submitForm}>
                Save Bank Account
              </Button>
            </Modal.Footer>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

export default BankAccountModal;
