import { Box, Icon } from '@palmetto/palmetto-components';
import EventBy from './EventBy';

function camelCaseToTitleCase(str: string): string {
  return str
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/\b\w+/g, (word) => word.charAt(0).toUpperCase() + word.slice(1));
}

const ValueDiff = ({ property, from, to }: { property: string; from: string; to: string }) => (
  <>
    <Box display="inline-block" fontWeight="bold" margin="0 0 2xs 0">
      {camelCaseToTitleCase(property)}
    </Box>
    <Box direction="row" alignItems="center" childGap="xs" margin="0 0 xs 0">
      <Box>
        {typeof from === 'object' && !Array.isArray(from) && from !== null ? JSON.stringify(from, null, 2) : from}
      </Box>
      <Box>
        <Icon name="arrow-right" />
      </Box>
      <Box>{typeof to === 'object' && !Array.isArray(to) && to !== null ? JSON.stringify(to, null, 2) : to}</Box>
    </Box>
  </>
);

const hiddenProperties = ['currentMilestone', 'requirements', 'milestones'];

const SabalAccountUpdated = ({ event }: { event: any }) => {
  const renderDiffs = (diff: any): any => {
    const properties =
      diff &&
      Object.keys(diff).map((key: any) => {
        const diffObj = diff[key];

        if (!diffObj || hiddenProperties.includes(key)) {
          return null;
        } else if (Array.isArray(diffObj)) {
          return (
            <Box key={key} childGap="xs">
              <Box fontWeight="bold">{camelCaseToTitleCase(key)}</Box>
              <Box padding="0 0 0 md">
                {diffObj.map((item: any) => {
                  if (
                    item &&
                    typeof item === 'object' &&
                    (Object.keys(item).includes('from') || Object.keys(item).includes('to'))
                  ) {
                    return <ValueDiff key={key} property={key} from={item.from} to={item.to} />;
                  } else {
                    return renderDiffs(item);
                  }
                })}
              </Box>
            </Box>
          );
        } else if (Object.keys(diffObj).includes('from') || Object.keys(diffObj).includes('to')) {
          return <ValueDiff key={key} property={key} from={diffObj.from} to={diffObj.to} />;
        } else {
          return (
            <span key={key}>
              <strong>{camelCaseToTitleCase(key)}</strong>
              {` `}
              {renderDiffs(diffObj)}
            </span>
          );
        }
      });

    return properties;
  };

  return (
    <Box display="block">
      <EventBy event={event} /> updated {renderDiffs(event?.data?.diff)}
    </Box>
  );
};

export default SabalAccountUpdated;
