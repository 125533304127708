import { Helmet } from 'react-helmet';
import { Box, Button, Card, useBreakpoint } from '@palmetto/palmetto-components';
import { useGetOrganizationTreeQuery } from '../../../services/organizations';
import PageHeader from '../../PageHeader';
import RequirePermissions from '../../auth/requirePermissions';
import OrganizationListRow from './OrganizationListRow';
import { MainContainer } from '../../MainContainer';

export function Organizations() {
  const { data: { tree = [] } = {}, isLoading } = useGetOrganizationTreeQuery();
  const { isPhone } = useBreakpoint();

  return (
    <>
      <Helmet>
        <title>Organizations | Settings</title>
      </Helmet>
      <MainContainer>
        <PageHeader title="Organizations" description="All visible organizations for the current organization">
          <RequirePermissions permissions={['admin']}>
            <Button
              as="a"
              href="/settings/organizations/new"
              variant="primary"
              size={{ base: 'sm', desktop: 'md' }}
              aria-label="create Organization"
              iconPrefix="add"
            >
              {!isPhone && 'Organization'}
            </Button>
          </RequirePermissions>
        </PageHeader>
        {isLoading && <Box>Loading...</Box>}
        <Card>{tree?.map((item) => <OrganizationListRow key={item.id} item={item} level={0} />)}</Card>
      </MainContainer>
    </>
  );
}
