import { api } from './api';

export const contractApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCurrentContract: build.query<any, string>({
      query: (accountId) => ({ url: `accounts/${accountId}/contracts/current` }),
      providesTags: (_result, _error, id) => [{ type: 'Contracts', id }],
    }),
    getContracts: build.query<any, string>({
      query: (accountId) => ({ url: `accounts/${accountId}/contracts` }),
      providesTags: (result) =>
        result
          ? [...result.map(({ id }: any) => ({ type: 'Contracts' as const, id })), { type: 'Contracts', id: 'LIST' }]
          : [{ type: 'Contracts', id: 'LIST' }],
    }),
    sendContract: build.mutation<any, { accountId: string; contractId: string; forceSend: boolean }>({
      query: ({ accountId, forceSend }) => ({
        url: `accounts/${accountId}/contracts/current/send?force=${forceSend}`,
        method: 'POST',
      }),
      invalidatesTags: (_result, _error, arg) => [
        { type: 'Accounts', id: arg.accountId },
        { type: 'Contracts', id: arg.contractId },
      ],
    }),
    voidContract: build.mutation<any, { accountId: string; contractId: string }>({
      query: ({ accountId, contractId }) => ({
        url: `accounts/${accountId}/contracts/${contractId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result: any, _error: any, arg: { accountId: string; contractId: string }) => [
        { type: 'Accounts', id: arg.accountId },
        { type: 'Contracts', id: 'LIST' },
        { type: 'AccountMilestones', id: arg.accountId },
        { type: 'DocumentDocument', id: 'LIST' },
      ],
    }),
    checkDisclosureStatus: build.mutation<any, { accountId: string; contractId: string }>({
      query: ({ accountId, contractId }) => ({
        url: `accounts/${accountId}/contracts/${contractId}/disclosure/status`,
        method: 'POST',
      }),
      invalidatesTags: (_result: any, _error: any, arg: { accountId: string; contractId: string }) => [
        { type: 'Accounts', id: arg.accountId },
        { type: 'Contracts', id: 'LIST' },
        { type: 'AccountMilestones', id: arg.accountId },
        { type: 'DocumentDocument', id: 'LIST' },
      ],
    }),
  }),
});

export const {
  useSendContractMutation,
  useGetContractsQuery,
  useGetCurrentContractQuery,
  useVoidContractMutation,
  useCheckDisclosureStatusMutation,
} = contractApi;
