import { Box } from '@palmetto/palmetto-components';
import { Link } from 'react-router-dom';

export const DisclosureConfirmation = () => {
  return (
    <Box>
      <Box fontSize="2xl" fontWeight="medium" margin="lg 0">
        Gracias por solicitar un plan de energía LightReach.
      </Box>
      <Box fontSize="md" rowGap="md" margin="0 0 lg 0">
        <Box>Su envío está completo y le hemos enviado un correo electrónico con más información.</Box>
        <Box>Trabaje con su consultor de energía dedicado en los próximos pasos para su solicitud.</Box>
        <Box direction="row">
          Si tiene alguna pregunta, comuníquese con&nbsp;
          <Link to="mailto:ayuda@golightreach.com">ayuda@golightreach.com</Link>
        </Box>
      </Box>
      <Box>
        <Box fontSize="2xl" fontWeight="medium" margin="lg 0">
          Thank you for applying for a LightReach Energy Plan.
        </Box>
        <Box fontSize="md" rowGap="md" margin="0 0 lg 0">
          <Box>Your submission is complete and we have sent you an email with more information.</Box>
          <Box>Please work with your dedicated Energy Consultant on the next steps for your application.</Box>
          <Box direction="row">
            If you have any questions, please reach out to&nbsp;
            <Link to="mailto:help@golightreach.com" color="success">
              help@golightreach.com
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
