import { Box, Button, Card, Spinner, useBreakpoint, useOpenClose } from '@palmetto/palmetto-components';
import { useParams } from 'react-router-dom';
import { useGetQuotesQuery, useVoidQuoteMutation } from '../../services/quotes';
import { QuoteListItem } from './QuoteListItem';
import QuoteModal from './QuoteModal';
import { QuoteStatus } from '../../types/Quotes';
import {
  useGetCurrentContractQuery,
  useSendContractMutation,
  useVoidContractMutation,
} from '../../services/contract';
import RequirePermissions from '../auth/requirePermissions';

export function Quotes({ className }: { className?: string }) {
  const { id } = useParams<{ id: any }>();
  const { data: quotes, isLoading: quotesIsLoading } = useGetQuotesQuery(id);
  const { data: contract } = useGetCurrentContractQuery(id);

  const { isOpen: isModalOpen, handleOpen: openModal, handleClose: closeModal } = useOpenClose();
  const { isPhone } = useBreakpoint();
  const isLoading = quotesIsLoading;
  const quotesWithContract = quotes?.reduce((acc, quote) => {
    return [...acc, { ...quote, contractStatus: contract?.status || undefined }];
  }, []);
  const [_voidQuote, { isLoading: isVoidingQuote }] = useVoidQuoteMutation({
    fixedCacheKey: 'useVoidQuoteMutationKey',
  });
  const [_sendContract, { isLoading: isSendingContract }] = useSendContractMutation({
    fixedCacheKey: 'useSendContractMutationKey',
  });
  const [_voidContract, { isLoading: isVoidingContract }] = useVoidContractMutation({
    fixedCacheKey: 'useVoidContractMutationKey',
  });
  const hasActiveOrContractedQuotes = quotesWithContract?.some(
    (quote: any) => quote.status === QuoteStatus.active || quote.status === QuoteStatus.contracted,
  );
  const disableActions = isVoidingQuote || isSendingContract || isVoidingContract;
  return (
    <>
      <Card className={className}>
        <Box childGap="lg" padding="lg" alignItems="center" direction="row" justifyContent="space-between">
          <Box as="h3" fontWeight="medium" fontSize="md">
            Quotes {quotesWithContract?.length > 0 && `(${quotesWithContract?.length})`}
          </Box>
          {hasActiveOrContractedQuotes ? (
            <RequirePermissions permissions={['admin', 'editor']} checkAllPermissions={false}>
              <Button
                as="button"
                onClick={() => {
                  openModal();
                }}
                size="sm"
                variant="primary"
                tone="neutral"
                iconPrefix="add"
                isDisabled={disableActions}
              >
                {!isPhone && 'Quote'}
              </Button>
            </RequirePermissions>
          ) : (
            <RequirePermissions permissions={['admin', 'editor']} checkAllPermissions={false}>
              <Button
                as="a"
                href={`/accounts/${id}/quote/create`}
                size="sm"
                variant="primary"
                tone="neutral"
                iconPrefix="add"
                isDisabled={disableActions}
              >
                {!isPhone && 'Quote'}
              </Button>
            </RequirePermissions>
          )}
        </Box>
        <QuotesList isLoading={isLoading} items={quotesWithContract as any} />
      </Card>
      <QuoteModal isModalOpen={isModalOpen} closeModal={closeModal} />
    </>
  );
}

export interface QuotesListProps {
  isLoading: boolean;
  items: any[];
}

export function QuotesList({ isLoading, items = [] }: QuotesListProps) {
  const totalCount = items?.length || 0;
  return (
    <Box width="100" overflow="auto">
      {totalCount > 0 ? (
        <Box>
          {items.map((item) => (
            <QuoteListItem item={item} key={item.id} />
          ))}
        </Box>
      ) : (
        <Box
          display="block"
          textAlign="center"
          padding="lg"
          childGap="xl"
          borderColor="separator"
          borderWidth="xs 0 0 0"
        >
          {isLoading ? (
            <Spinner size="lg" />
          ) : (
            <>
              <Box fontSize="sm" color="body-secondary">
                No quotes exist for this account
              </Box>
            </>
          )}
        </Box>
      )}
    </Box>
  );
}
