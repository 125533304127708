import FormSection from '../Forms/FormSection';
import { PvSystemDescription } from '../InstallMilestonePackage/InstallationFormSectionDescriptions';
import { EquipmentDetailsFormSection } from '../SystemDesign/EquipmentDetailsFormSection';
import { MountingHardwareFormSection } from '../SystemDesign/MountingHardwareFormSection';
import { FlagBox } from '../QueueFlags/FlagBox';

export const PVSystem = () => {
  return (
    <FormSection description={PvSystemDescription} title="PV System">
      <EquipmentDetailsFormSection hideTitleAndDescription />
      <MountingHardwareFormSection hideTitleAndDescription className="border-out" />
      <FlagBox dataKey="PV System" />
    </FormSection>
  );
};
