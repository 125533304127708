import { Box, Button, Modal, TextInput, toast } from '@palmetto/palmetto-components';
import { FormikValues, useFormik } from 'formik';
import { SyntheticEvent } from 'react';
import * as yup from 'yup';
import { AdderType, ProgramType, adderMap } from 'types';
import { useCreateConstructionAdderMutation } from '../../../services/pricing';

export const CreateBackupBatteryModal = ({
  isModalOpen,
  closeModal,
  adderType,
}: {
  isModalOpen: boolean;
  closeModal: (event?: SyntheticEvent<Element, Event> | undefined) => void;
  adderType?: string;
}) => {
  const [createConstructionAdder] = useCreateConstructionAdderMutation();

  const formik = useFormik({
    initialValues: {
      name: '',
      zeroEsc: undefined,
      zeroNineNineEsc: undefined,
      oneNineNineEsc: undefined,
      twoNineNineEsc: undefined,
      threeFiveEsc: undefined,
    },
    validationSchema: yup.object({
      name: yup.string().required('Option Name is required'),
      zeroEsc: yup.number().required('0% Escalator is required'),
      zeroNineNineEsc: yup.number().required('0.99% Escalator is required'),
      oneNineNineEsc: yup.number().required('1.99% Escalator is required'),
      twoNineNineEsc: yup.number().required('2.99% Escalator is required'),
      threeFiveEsc: yup.number().required('3.5% Escalator is required'),
    }),
    onSubmit: async (values: FormikValues, { setSubmitting, resetForm }) => {
      try {
        await createConstructionAdder({
          data: {
            adderType: adderType,
            name: values.name,
            paymentFactors: [
              {
                escalationRate: 0,
                value: values.zeroEsc,
              },
              { escalationRate: 0.0099, value: values.zeroNineNineEsc },
              { escalationRate: 0.0199, value: values.oneNineNineEsc },
              { escalationRate: 0.0299, value: values.twoNineNineEsc },
              { escalationRate: 0.035, value: values.threeFiveEsc },
            ],
          },
          programType: ProgramType.solar,
        }).unwrap();
        toast.success('Adder created successfully');
        resetForm();
        closeModal();
      } catch (e) {
        console.log(e);
        toast.error('Error creating adder');
      } finally {
        setSubmitting(false);
      }
    },
  });
  return (
    <Modal ariaLabelledBy="createConstructionAdder" isOpen={isModalOpen} onDismiss={closeModal} maxWidth="500px">
      <Modal.Header
        id="createConstructionAdder"
        title={`Add ${adderMap[ProgramType.solar][adderType as AdderType]} Modifier`}
        onDismiss={closeModal}
      />
      <form onSubmit={formik.handleSubmit}>
        <Modal.Body background="secondary">
          <Box direction="column" childGap="md">
            <TextInput
              type="text"
              label="Option Name"
              name="name"
              id="name"
              value={formik.values.name}
              onChange={(event) => formik.setFieldValue('name', event.target.value)}
              isRequired
              onBlur={formik.handleBlur}
              error={formik.touched.name && (formik.errors.name as string)}
            />
            <Box childGap="lg">
              <Box fontWeight="medium" color="body-primary">
                Backup Battery Modifiers
              </Box>
              <Box direction="row" gap="lg">
                <TextInput
                  type="text"
                  label="0% Escalator"
                  name="zeroEsc"
                  id="zeroEsc"
                  value={formik.values.zeroEsc}
                  onChange={(event) => formik.setFieldValue('zeroEsc', event.target.value)}
                  isRequired
                  onBlur={formik.handleBlur}
                  error={formik.touched.zeroEsc && (formik.errors.zeroEsc as string)}
                />
                <TextInput
                  type="text"
                  label="0.99% Escalator"
                  name="zeroNineNineEsc"
                  id="zeroNineNineEsc"
                  value={formik.values.zeroNineNineEsc}
                  onChange={(event) => formik.setFieldValue('zeroNineNineEsc', event.target.value)}
                  isRequired
                  onBlur={formik.handleBlur}
                  error={formik.touched.zeroNineNineEsc && (formik.errors.zeroNineNineEsc as string)}
                />
              </Box>
              <Box direction="row" gap="lg">
                <TextInput
                  type="text"
                  label="1.99% Escalator"
                  name="oneNineNineEsc"
                  id="oneNineNineEsc"
                  value={formik.values.oneNineNineEsc}
                  onChange={(event) => formik.setFieldValue('oneNineNineEsc', event.target.value)}
                  isRequired
                  onBlur={formik.handleBlur}
                  error={formik.touched.oneNineNineEsc && (formik.errors.oneNineNineEsc as string)}
                />
                <TextInput
                  type="text"
                  label="2.99% Escalator"
                  name="twoNineNineEsc"
                  id="twoNineNineEsc"
                  value={formik.values.twoNineNineEsc}
                  onChange={(event) => formik.setFieldValue('twoNineNineEsc', event.target.value)}
                  isRequired
                  onBlur={formik.handleBlur}
                  error={formik.touched.twoNineNineEsc && (formik.errors.twoNineNineEsc as string)}
                />
              </Box>
              <Box direction="row" gap="lg">
                <TextInput
                  type="text"
                  label="3.5% Escalator"
                  name="threeFiveEsc"
                  id="threeFiveEsc"
                  value={formik.values.threeFiveEsc}
                  onChange={(event) => formik.setFieldValue('threeFiveEsc', event.target.value)}
                  isRequired
                  onBlur={formik.handleBlur}
                  error={formik.touched.threeFiveEsc && (formik.errors.threeFiveEsc as string)}
                />
              </Box>
            </Box>
          </Box>
        </Modal.Body>
        <Modal.Footer>
          <Button
            as="button"
            onClick={() => {
              formik.resetForm();
              closeModal();
            }}
            variant="secondary"
            tone="neutral"
            size="md"
            isLoading={formik.isSubmitting}
          >
            Cancel
          </Button>
          <Button size="md" type="submit" isLoading={formik.isSubmitting}>
            Add Option
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
