import { useParams } from 'react-router-dom';
import { Box, Button, Icon } from '@palmetto/palmetto-components';
import { MilestoneType } from '../../types/Milestone';

const milestoneTypeToLabel: Partial<Record<MilestoneType, any>> = {
  [MilestoneType.noticeToProceed]: 'Notice To Proceed',
  [MilestoneType.install]: 'Install',
  [MilestoneType.activation]: 'Activation',
};

const milestoneLinkMap: {
  [Key in MilestoneType]?: string;
} = {
  [MilestoneType.install]: 'installation-package/edit',
  [MilestoneType.activation]: 'activation-package/edit',
};

interface RejectionBannerProps {
  milestone: MilestoneType;
  rejections: string[];
  className?: string;
}

const RejectionBanner = ({ milestone, rejections, className }: RejectionBannerProps) => {
  const { id } = useParams<{ id: any }>();

  return (
    <Box
      borderWidth="xs"
      borderColor="danger"
      radius="md"
      direction={{ base: 'column', tablet: 'row' }}
      alignItems="stretch"
      fontSize="sm"
      overflow="hidden"
      background="primary"
      shadow="xs"
      className={className}
    >
      <Box
        background="danger"
        padding="lg"
        justifyContent="center"
        direction="row"
        childGap="sm"
        alignItems="center"
      >
        <Icon name="remove" size="2xl" color="danger" />
        <Box fontSize="xl" fontWeight="bold">
          Milestone Rejection
        </Box>
      </Box>
      <Box flex="auto" childGap="2xs" padding="lg" fontSize="md" fontWeight="medium">
        <Box>Milestone: {milestoneTypeToLabel[milestone]}</Box>
        <Box>Rejections: {rejections?.join(', ')}</Box>
      </Box>
      <Box padding={{ base: '0 lg lg lg', tablet: 'lg' }} justifyContent="center">
        <Button
          as="a"
          variant="secondary"
          tone="neutral"
          size={{ base: 'sm', desktop: 'md' }}
          aria-label="view rejected milestone"
          href={`${id}/${milestoneLinkMap[milestone]}`}
        >
          View
        </Button>
      </Box>
    </Box>
  );
};

export default RejectionBanner;
