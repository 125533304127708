import { Box, Card, Column, Spinner, Table } from '@palmetto/palmetto-components';
import { Link, useParams } from 'react-router-dom';
import { useGetPriceSheetMappingsByIdQuery } from '../../../services/pricing';
import { useMemo } from 'react';
import './PriceSheetMappings.css';

export function PriceSheetMappings() {
  const { id, programType } = useParams<{ id: any; programType: string }>();

  const { data: priceSheetMappingData, isLoading: isPricingSheetMappingLoading } = useGetPriceSheetMappingsByIdQuery(
    {
      id,
    },
  );
  const columnConfig = [
    {
      heading: 'Organization',
      dataKey: 'organizationName',
      render: (_cell: any, row: any) => <Box fontWeight="bold">{row.organizationName}</Box>,
    },
    {
      heading: 'State',
      dataKey: 'state',
    },
    {
      heading: 'Utilities',
      dataKey: 'utilities',
      render: (_cell: any, row: any) =>
        row.utilities
          .sort()
          .map((utility: string, index: number) => <Box key={`${utility}-${index}`}>{utility}</Box>),
    },
  ];
  const tableData: any[] = useMemo(() => {
    if (isPricingSheetMappingLoading || !priceSheetMappingData || priceSheetMappingData?.length === 0) return [];
    const organizationAndUtilities = priceSheetMappingData.reduce((acc: any, cur: any) => {
      const existingOrg = acc.find((item: any) => item.organizationId === cur.organizationId);
      const utilityNames = [`${cur.utility.name}${cur.status === 'archived' ? ' - (Archived)' : ''}`];
      if (!existingOrg) {
        acc.push({
          organizationId: cur.organizationId,
          organizationName: cur.organizationName,
          utilities: utilityNames,
          state: cur.state,
        });
      } else {
        existingOrg['utilities'].push(...utilityNames);
      }
      return acc;
    }, []);
    return organizationAndUtilities;
  }, [priceSheetMappingData, isPricingSheetMappingLoading]);
  return (
    <>
      {isPricingSheetMappingLoading ? (
        <Box display="block" textAlign="center">
          <Spinner size="lg" />
        </Box>
      ) : (
        <Card>
          <Card.Section>
            {priceSheetMappingData?.length > 0 ? (
              <Table
                className="priceSheetMappingTable"
                rowKey="organizationId"
                columns={columnConfig as Column[]}
                rows={tableData}
                isLoading={false}
                isScrollable={{
                  x: true,
                }}
              />
            ) : (
              <Box textAlign="center" padding="xl">
                <Box padding="xs">This price sheet is not mapped to any organizations</Box>
                <Link to={`/admin/pricing/${programType}`} style={{ textDecoration: 'underline' }}>
                  <Box
                    padding="xs"
                    fontWeight="bold"
                    as="span"
                    hover={{ color: 'primary' }}
                    style={{ transitionDuration: '0s' }}
                  >
                    View all mappings
                  </Box>
                </Link>
              </Box>
            )}
          </Card.Section>
        </Card>
      )}
    </>
  );
}
