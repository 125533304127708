import { api } from './api';

export const systemDesignApi = api.injectEndpoints({
  endpoints: (build) => ({
    createSystemDesign: build.mutation<any, { systemDesign: Record<string, any>; accountId: string }>({
      query: ({ systemDesign, accountId }) => ({
        url: `accounts/${accountId}/system-design`,
        method: 'POST',
        body: systemDesign,
      }),
      invalidatesTags: (_result, _error, args) => [
        { type: 'SystemDesign', accountId: args.accountId },
        { type: 'Accounts', id: args.accountId },
      ],
    }),
    getCurrentDesign: build.query<any, string>({
      query: (id) => ({ url: `accounts/${id}/system-design/current` }),
      providesTags: (_result, _error, args) => [{ type: 'SystemDesign', accountId: args }],
    }),
  }),
});

export const { useCreateSystemDesignMutation, useGetCurrentDesignQuery } = systemDesignApi;
