import {
  Box,
  Button,
  FileUpload,
  FormLabel,
  FormikSelectInputNative,
  FormikTextareaInput,
  Icon,
  Modal,
  toast,
} from '@palmetto/palmetto-components';
import { useMemo, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { useCancelAccountMutation, useReactivateAccountMutation } from '../../services/accounts';
import { useUploadDocumentMutation } from '../../services/documents';
import { CancellationDocumentTypes, CancelActivityRequesterType, CancelReason, DocumentDocument } from 'types';
import * as yup from 'yup';
import { isErrorWithData } from '@/services/helpers';
interface CancelAccountProps {
  handleClose: () => void;
  accountData: any;
  accountId: string;
  showNoCancelAfterInstall: boolean;
  title: string;
  isReactivation?: boolean;
}

export const cancelReasonLabels = {
  [CancelReason.financialConcerns]: 'Financial Concerns',
  [CancelReason.ineligiblePropertyType]: 'Ineligible Property Type',
  [CancelReason.choseDifferentFinancialProvider]: 'Chose Different Financial Provider',
  [CancelReason.unhappyWithSalesProcess]: 'Unhappy With Sales Process',
  [CancelReason.reasonNotProvided]: 'Reason Not Provided',
};

export const cancelActivityRequesterTypeLabels = {
  [CancelActivityRequesterType.organization]: 'Organization',
  [CancelActivityRequesterType.customer]: 'Customer',
  [CancelActivityRequesterType.internal]: 'LightReach',
};

const cancelSchema = yup.object({
  notes: yup.string().optional().nullable(),
  reason: yup.string().required('Cancellation reason is required'),
  requesterType: yup.string().required('Requester type is required'),
});

const reactivateSchema = yup.object({
  notes: yup.string().required('Reactivation notes are required'),
  requesterType: yup.string().required('Requester type is required'),
});

export const CancelReactivateAccountModal = ({
  handleClose,
  accountData,
  accountId,
  showNoCancelAfterInstall,
  title,
  isReactivation = false,
}: CancelAccountProps) => {
  const [files, setFiles] = useState<FileList | undefined>();
  const [uploadDocument, { isLoading: isUploadingDocument }] = useUploadDocumentMutation();
  const [cancelAccount] = useCancelAccountMutation();
  const [reactivateAccount] = useReactivateAccountMutation();

  const onFileChange = (event: any) => {
    setFiles(event.target?.files);
  };

  const closeUploadModal = () => {
    setFiles(undefined);
    handleClose();
  };

  const handleSubmit = async (
    values: {
      notes: string | undefined;
      reason: CancelReason | undefined;
      requesterType: CancelActivityRequesterType | undefined;
    },
    { setSubmitting }: any,
  ) => {
    const { notes, reason, requesterType } = values || {};
    let resultingDocuments = [] as DocumentDocument[];
    if (!!files) {
      const data = new FormData();
      data.append('grouped', 'true');
      data.append(
        'type',
        isReactivation ? CancellationDocumentTypes.reactivation : CancellationDocumentTypes.cancellation,
      );

      for (let i = 0; i < files.length; i++) {
        data.append(`files[${i}]`, files[i]);
      }

      try {
        resultingDocuments = (await uploadDocument({
          accountId,
          formData: data,
          invalidateAllTags: true,
        }).unwrap()) as any;
        toast.success(`${isReactivation ? 'Reactivation' : 'Cancellation'} documents uploaded`);
      } catch (e: any) {
        if (e.status === 413 || e.originalStatus === 413) {
          toast.error('File size too large. Please upload files smaller than 32MB.');
        } else if (isErrorWithData(e)) {
          toast.error(e.data.message);
        } else {
          toast.error('An error occurred while uploading documents');
        }
      }
    }
    try {
      const cancelDetails = {
        notes,
        reason,
        requesterType,
        supportingDocuments: resultingDocuments?.length ? resultingDocuments : [],
      };
      if (isReactivation) {
        await reactivateAccount({
          cancelDetails,
          id: accountId,
        }).unwrap();
        await toast.success('Account reactivated successfully.');
      } else {
        await cancelAccount({
          cancelDetails,
          id: accountId,
        }).unwrap();
        await toast.success('Account cancelled successfully.');
      }
      closeUploadModal();
    } catch (e: any) {
      toast.error(e?.data?.message || 'Error cancelling the account.');
      closeUploadModal();
    }
    setSubmitting(false);
  };

  const cancelReasonOptions = useMemo(() => {
    return Object.keys(CancelReason).map((reason) => ({
      label: cancelReasonLabels[reason as keyof typeof CancelReason],
      value: reason,
    }));
  }, []);
  const requesterTypeOptions = useMemo(() => {
    return Object.keys(CancelActivityRequesterType).map((type) => ({
      label: cancelActivityRequesterTypeLabels[type as keyof typeof CancelActivityRequesterType],
      value: type,
    }));
  }, []);

  const initialValues = useMemo(() => {
    return {
      notes: '',
      reason: accountData?.cancellation?.reason,
      requesterType: accountData?.cancellation?.requesterType,
    };
  }, [accountData]);
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize={true}
      validationSchema={isReactivation ? reactivateSchema : cancelSchema}
    >
      {({ isSubmitting }) => (
        <Form noValidate id="cancelReactivateForm">
          {isReactivation && (
            <Modal.Header id="cancelModalHeader" title="Reactivate Account" onDismiss={handleClose} />
          )}
          <Modal.Body background={isReactivation ? 'secondary' : 'danger'} gap="lg" fontSize="sm">
            {!showNoCancelAfterInstall && !isReactivation && (
              <Box gap="xs" alignItems="center">
                <Icon name="remove" size="2xl" color="danger" />
                <Box id="cancelReactiveModalTitle" as="h4" fontSize="lg" fontWeight="regular" textAlign="center">
                  {title}
                </Box>
              </Box>
            )}
            <Box as="p" fontWeight="medium" color="body-primary">
              {isReactivation
                ? 'Reactivating the account will return this account to its previous state before it was cancelled.'
                : 'Cancelling the account will remove it from the list of active accounts, but will be searchable if the project needs to be reactivated.'}
            </Box>
            <Box gap="md">
              {!isReactivation && (
                <Field
                  label="Cancellation Reason"
                  name="reason"
                  id="reason"
                  options={cancelReasonOptions}
                  component={FormikSelectInputNative}
                  isDisabled={isSubmitting}
                  isRequired
                  menuPortalTarget={document.body}
                />
              )}
              <Field
                label="Requested By"
                name="requesterType"
                id="requesterType"
                options={requesterTypeOptions}
                component={FormikSelectInputNative}
                isDisabled={isSubmitting}
                isRequired
                menuPortalTarget={document.body}
              />
              <Box childGap="xs">
                <FormLabel inputId="files" required>
                  Attach File (optional)
                </FormLabel>
                <Box color="body-secondary">
                  {isReactivation
                    ? 'e.g. screenshot of reactivation request chat or email'
                    : 'e.g. cancellation email, screenshot of request'}
                </Box>
                <FileUpload
                  buttonText="Choose File(s)"
                  name="files"
                  labelText="Choose File(s)"
                  id="files"
                  accept="image/*,.pdf"
                  variant="secondary"
                  multiple
                  size="md"
                  required
                  requiredIndicator=""
                  onChange={onFileChange}
                  isDisabled={isUploadingDocument}
                  files={files}
                />
              </Box>
              <Field
                label={isReactivation ? 'Reactivation Notes' : 'Cancellation Note (Optional)'}
                name="notes"
                id="notes"
                component={FormikTextareaInput}
                autoComplete="off"
                isDisabled={isSubmitting}
                isRequired={isReactivation}
                helpText={`Add context not captured by the ${isReactivation ? 'reactivation' : 'cancellation'} reason.`}
              />
            </Box>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" tone="neutral" size="md" onClick={handleClose} isDisabled={isSubmitting}>
              {showNoCancelAfterInstall ? 'Close' : isReactivation ? 'Cancel' : 'Do Not Cancel'}
            </Button>
            {!showNoCancelAfterInstall && (
              <Button size="md" tone={isReactivation ? 'primary' : 'danger'} type="submit" isLoading={isSubmitting}>
                {isReactivation ? 'Reactivate Account' : 'Cancel Account'}
              </Button>
            )}
          </Modal.Footer>
        </Form>
      )}
    </Formik>
  );
};
