import React, { useEffect } from 'react';
import { Field, Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import { Badge, Box, Button, FormikRadioGroup, FormikTextInput, Table } from '@palmetto/palmetto-components';
import { object, string, Schema } from 'yup';
import { MilestoneLabels, MilestoneType, VendorPaymentSchedule } from 'types';

export interface PaymentFormEditData {
  shouldHavePaymentSchedule: string;
  paymentScheduleId?: string | undefined;
  legalEntity: string;
  setUpBankAccount: string;
  netSuiteVendorId?: string | undefined;
}

const paymentSchema = {
  shouldHavePaymentSchedule: string().required().oneOf(['yes', 'no']),
  paymentScheduleId: string().when('shouldHavePaymentSchedule', {
    is: 'yes',
    then: (schema) => schema.required('A payment schedule is required'),
    otherwise: (schema) => schema.optional(),
  }),
  legalEntity: string().required().oneOf(['yes', 'no']),
  setUpBankAccount: string().required().oneOf(['yes', 'no']),
  netSuiteVendorId: string().optional(),
};

export const PaymentFormSchema: Schema<PaymentFormEditData> = object().shape(paymentSchema).noUnknown(true);

type SubmitHandler = (values: any, formikHelpers: FormikHelpers<any>) => void | Promise<any>;

interface PaymentFormProps {
  initialValues: any;
  handleSubmit: SubmitHandler;
  isLoading: boolean;
  disabledFields?: string[];
  organizationName?: string;
  paymentScheduleOptions: VendorPaymentSchedule[] | undefined;
}

export const PaymentForm = ({
  initialValues,
  handleSubmit,
  isLoading,
  disabledFields = [],
  organizationName,
  paymentScheduleOptions,
}: PaymentFormProps) => {
  const PaymentScheduleIdInput = (props: any) => {
    const { setFieldValue, values, setErrors }: any = useFormikContext();
    const handleScheduleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setFieldValue('paymentScheduleId', event.target.value);
    };
    useEffect(() => {
      if (values.paymentScheduleId) {
        setErrors({ paymentScheduleId: undefined });
      }
    }, [setErrors, values.paymentScheduleId]);
    return (
      <input
        type="radio"
        name="paymentScheduleId"
        onChange={handleScheduleChange}
        value={props.id}
        disabled={disabledFields.includes('paymentScheduleId')}
        checked={values.paymentScheduleId === props.id}
      />
    );
  };

  const paymentScheduleColumns = [
    {
      dataKey: 'id',
      render: (cell: any) => <PaymentScheduleIdInput id={cell} />,
    },
    {
      heading: 'Schedule Name',
      dataKey: 'name',
      render: (cell: any) => (
        <Box fontWeight="medium" as="span" display="inline">
          {cell}
        </Box>
      ),
    },
    {
      dataKey: 'badge',
      render: (_cell: any, row: any) =>
        initialValues.paymentScheduleId === row.id && <Badge variant="info" message="current" />,
    },
    {
      heading: 'Modifier ($/w)',
      dataKey: 'holdback',
      render: (cell: any) => (
        <Box fontWeight="medium" as="span" display="inline">
          {cell?.modifier ? cell?.modifier : '-'}
        </Box>
      ),
    },
    {
      heading: MilestoneLabels[MilestoneType.noticeToProceed],
      dataKey: 'paymentSchedule',
      render: (cell: any) => {
        const ntpMilestone = cell?.find((item: any) => item?.milestone === MilestoneType.noticeToProceed);
        return (
          <Box fontWeight="medium" as="span" display="inline">
            {ntpMilestone ? `${ntpMilestone.paymentPercent * 100}%` : '-'}
          </Box>
        );
      },
    },
    {
      heading: MilestoneLabels[MilestoneType.installComplete],
      dataKey: 'paymentSchedule',
      render: (cell: any) => {
        const installCompleteMilestone = cell?.find(
          (item: any) => item?.milestone === MilestoneType.installComplete,
        );
        return (
          <Box fontWeight="medium" as="span" display="inline">
            {installCompleteMilestone ? `${installCompleteMilestone.paymentPercent * 100}%` : '-'}
          </Box>
        );
      },
    },
    {
      heading: MilestoneLabels[MilestoneType.activation],
      dataKey: 'paymentSchedule',
      render: (cell: any) => {
        const activationMilestone = cell?.find((item: any) => item?.milestone === MilestoneType.activation);
        return (
          <Box fontWeight="medium" as="span" display="inline">
            {activationMilestone ? `${activationMilestone.paymentPercent * 100}%` : '-'}
          </Box>
        );
      },
    },
  ];

  const shouldHavePaymentScheduleOptions = [
    {
      id: 'shouldHavePaymentScheduleTrue',
      value: 'yes',
      label: 'Yes',
    },
    {
      id: 'shouldHavePaymentScheduleFalse',
      value: 'no',
      label: 'No, payments schedule should be inherited from parent organization.',
    },
  ];

  const legalEntityOptions = [
    {
      id: 'legalEntityTrue',
      value: 'yes',
      label: 'Yes',
    },
    {
      id: 'legalEntityFalse',
      value: 'no',
      label: 'No',
    },
  ];

  const setUpBankAccountOptions = [
    {
      id: 'setUpBankAccountTrue',
      value: 'yes',
      label: `Yes`,
    },
    {
      id: 'setUpBankAccountFalse',
      value: 'no',
      label: 'No, payments should be made to parent organization',
    },
  ];

  const InheritScheduleInput = (props: any) => {
    const { setFieldValue, values }: any = useFormikContext();
    useEffect(() => {
      if (values.shouldHavePaymentSchedule === 'no') {
        setFieldValue('paymentScheduleId', undefined);
      }
    }, [setFieldValue, values.shouldHavePaymentSchedule]);

    return (
      <Field
        title={`Should ${organizationName} have its own payment schedule?`}
        name="shouldHavePaymentSchedule"
        id="shouldHavePaymentSchedule"
        options={shouldHavePaymentScheduleOptions}
        component={FormikRadioGroup}
        isRequired
        isDisabled={props.isSubmitting}
      />
    );
  };

  const IsLegalEntityInput = (props: any) => {
    const { setFieldValue, values }: any = useFormikContext();
    useEffect(() => {
      if (values.legalEntity === 'no') {
        setFieldValue('netSuiteVendorId', undefined);
      }
    }, [setFieldValue, values.legalEntity, values.shouldHavePaymentSchedule]);

    return (
      <Field
        title={`Does ${organizationName} represent its own legal entity?`}
        name="legalEntity"
        id="legalEntity"
        options={legalEntityOptions}
        component={FormikRadioGroup}
        isRequired
        isDisabled={props.isSubmitting}
      />
    );
  };

  return (
    <>
      <Formik
        validationSchema={PaymentFormSchema}
        initialValues={initialValues}
        validateOnChange={false}
        onSubmit={handleSubmit}
        enableReinitialize={false}
      >
        {({ isSubmitting, values, errors }) => (
          <Form noValidate>
            <Box childGap="xl">
              <Box childGap="xl">
                <Box>
                  <InheritScheduleInput isSubmitting={isSubmitting} initialValues={initialValues} />
                </Box>
                {values.shouldHavePaymentSchedule === 'yes' && (
                  <Box>
                    <Table rowKey="id" columns={paymentScheduleColumns} rows={paymentScheduleOptions ?? []} />
                    {errors?.paymentScheduleId && <Box color="danger">{errors.paymentScheduleId as string}</Box>}
                  </Box>
                )}
              </Box>
              <Box>
                <Field
                  title={`Should ${organizationName} set up their own bank account to receive project payments?`}
                  name="setUpBankAccount"
                  id="setUpBankAccount"
                  options={setUpBankAccountOptions}
                  component={FormikRadioGroup}
                  isRequired
                  isDisabled={isSubmitting}
                />
              </Box>
              <Box>
                <Box>
                  <IsLegalEntityInput isSubmitting={isSubmitting} />
                </Box>
                {errors?.legalEntity && <Box color="danger">{errors.legalEntity as string}</Box>}
              </Box>
              {errors?.setUpBankAccount && <Box color="danger">{errors.setUpBankAccount as string}</Box>}
              {values.legalEntity === 'yes' && (
                <Box width="300px">
                  <Field
                    type="text"
                    label="NetSuite Vendor Id"
                    name="netSuiteVendorId"
                    id="netSuiteVendorId"
                    component={FormikTextInput}
                    autoComplete="off"
                    isDisabled={isSubmitting}
                  />
                </Box>
              )}
            </Box>

            <Box
              direction={{
                base: 'column',
                tablet: 'row',
              }}
              alignItems={{
                base: 'stretch',
                tablet: 'flex-end',
              }}
              justifyContent={{
                tablet: 'flex-end',
              }}
              childGap="sm"
              style={{ flexShrink: 0 }}
              padding="lg 0 0 0"
            >
              <Button
                as="a"
                href="/settings/organizations"
                variant="secondary"
                tone="neutral"
                size="md"
                isDisabled={isLoading}
                isLoading={isSubmitting}
              >
                Cancel
              </Button>
              <Button variant="primary" size="md" type="submit" isDisabled={isLoading} isLoading={isSubmitting}>
                Save
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};
