import { useMemo } from 'react';
import { Field, FormikValues, useFormikContext } from 'formik';
import { FormikSelectInputNative } from '@palmetto/palmetto-components';
import { useGetUtilitiesQuery } from '../../services/utilities';

export const UtilityNameSelectInput = ({ isDisabled }: { isDisabled: boolean }) => {
  const { data: utilities = [], isLoading: isUtilitiesLoading } = useGetUtilitiesQuery({});
  const { values, isSubmitting }: { values: FormikValues; isSubmitting: boolean } = useFormikContext();

  const utilityOptions = useMemo(() => {
    if (!values?.state || values?.state === '' || isUtilitiesLoading) {
      return [];
    }
    const utilitiesByState = utilities.filter((utility: any) => utility.state === values.state);
    return utilitiesByState.map((utility: any) => ({
      value: utility.lseId,
      label: utility.name,
    }));
  }, [values?.state, isUtilitiesLoading, utilities]);

  return (
    <Field
      type="text"
      label="Utility Name"
      name="lseId"
      id="lseId"
      options={utilityOptions}
      component={FormikSelectInputNative}
      isRequired
      autoComplete="off"
      isDisabled={isSubmitting || isUtilitiesLoading || isDisabled}
      style={{ marginRight: 'var(--size-spacing-xl)' }}
    />
  );
};
