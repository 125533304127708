export enum ProgramType {
  solar = 'solar',
  hvac = 'hvac',
  doePr = 'doePr',
}
export const ProgramTypes: ProgramType[] = Object.values(ProgramType);
export const ProgramTypeLabels: Record<ProgramType, string> = {
  solar: 'Solar',
  hvac: 'HVAC',
  doePr: 'DOE Puerto Rico',
};
