import { Box, Button, Card, Spinner, useBreakpoint } from '@palmetto/palmetto-components';
import RequirePermissions from '../../auth/requirePermissions';
import OrganizationListRow from './OrganizationListRow';
import OrganizationProperties from './OrganizationProperties';
import OrganizationUsers from './OrganizationUsers';
import { OrganizationNode } from '../../../types/Organizations';

interface OrganizationSettingsProps {
  organization: OrganizationNode | undefined;
  isLoading?: boolean;
}

const OrganizationSettings = ({ organization, isLoading }: OrganizationSettingsProps) => {
  const { isPhone } = useBreakpoint();

  return (
    <Box gap="lg">
      <OrganizationProperties organization={organization} isLoading={isLoading} />
      <Card>
        <Box childGap="lg" padding="lg" alignItems="center" direction="row" justifyContent="space-between">
          <Box as="h3" fontWeight="medium" fontSize="md">
            Organizations
          </Box>
          <RequirePermissions permissions={['admin']}>
            <Button
              as="a"
              href={`/settings/organizations/new?parent=${organization?.alias}`}
              size="sm"
              variant="primary"
              tone="neutral"
              aria-label="create Organization"
              iconPrefix="add"
            >
              {!isPhone && 'Organization'}
            </Button>
          </RequirePermissions>
        </Box>
        {isLoading ? (
          <Box padding="0 lg lg lg">
            <Spinner />
          </Box>
        ) : (
          <Box>
            {organization?.children?.map((item) => <OrganizationListRow key={item.id} item={item} level={0} />)}
          </Box>
        )}
      </Card>
      <OrganizationUsers />
    </Box>
  );
};

export default OrganizationSettings;
