import { useEditPaymentMethodInformationMutation } from '@/services/paymentMethods';
import { Box, Button, Icon, Modal, RadioGroup, TextInput, toast } from '@palmetto/palmetto-components';
import { FormikValues, useFormik } from 'formik';
import { SyntheticEvent, useState } from 'react';
import { useParams } from 'react-router-dom';
import { BankAccountTypes, FinancialAccountBank, FinancialAccountDocument } from 'types';
import * as yup from 'yup';

export const AccountPaymentMethod = ({
	isModalOpen,
	closeModal,
	paymentMethods = [],
}: {
	isModalOpen: boolean;
	closeModal: (event?: SyntheticEvent<Element, Event> | undefined) => void;
	paymentMethods?: FinancialAccountDocument[];
}) => {
	const { id = '' } = useParams<{ id: string }>();
	const [editPaymentMethodInformation] = useEditPaymentMethodInformationMutation();
	const [showAccountNumber, setShowAccountNumber] = useState(false)
	const formik = useFormik({
		initialValues: {
			bankAccountType: paymentMethods[0]?.bankAccount?.bankAccountType || BankAccountTypes.checking,
			routingNumber: paymentMethods[0]?.bankAccount?.routingNumber || '',
			accountNumber: paymentMethods[0]?.bankAccount?.accountNumber || '',
			isAccountValidated: true,
			confirmAccountNumber: '',
		},
		validationSchema: yup.object({
			routingNumber: yup.string().required('The Routing Number is required'),
			accountNumber: yup.string().required('The Account Number is required'),
			confirmAccountNumber: yup.string().oneOf([yup.ref('accountNumber')], 'The Account Numbers must match')
				.required('The Account Number needs to be confirmed'),
		}
		),
		enableReinitialize: true,
		onSubmit: async (values: FormikValues, { setSubmitting, resetForm }) => {
			try {
				await editPaymentMethodInformation({
					id, data: { bankAccount: values as FinancialAccountBank }
				}).unwrap();
				toast.success('Payment Method updated successfully');
				setShowAccountNumber(false);
				resetForm();
				closeModal();
			} catch (e) {
				console.log(e);
				toast.error('Error editing Payment Method');
			} finally {
				setSubmitting(false);
			}
		},
	});

	return (
		<Modal ariaLabelledBy="accountPaymentMethod" isOpen={isModalOpen} onDismiss={closeModal} maxWidth="500px">
			<Modal.Header id="accountPaymentMethod" title="Account Payment Method" onDismiss={closeModal} />
			<form onSubmit={formik.handleSubmit}>
				<Modal.Body background="grey-50">
					<Box direction="column" childGap="xl">
						<RadioGroup
							title="Account type"
							id="bankAccountType"
							name="bankAccountType"
							label="Account Type"
							value={formik.values.bankAccountType}
							onChange={(event) => formik.setFieldValue('bankAccountType', event.target.value)}
							options={[
								{ label: "Checking", value: BankAccountTypes.checking, id: BankAccountTypes.checking },
								{ label: "Savings", value: BankAccountTypes.savings, id: BankAccountTypes.savings },
							]} />
						<Box>
							<TextInput
								label="Routing Number"
								name="routingNumber"
								id="routingNumber"
								type="text"
								value={formik.values.routingNumber}
								onChange={(event) => formik.setFieldValue('routingNumber', event.target.value)}
								error={formik.touched.routingNumber && (formik.errors.routingNumber as string)}
							/>
						</Box>
						<Box>
							<Box fontSize='sm' fontWeight='medium' padding='0 0 sm 0'>Account Number</Box>
							<Box direction={{ base: 'column', tablet: 'row' }} gap='sm'>
								<TextInput
									label=""
									hideLabel
									name="accountNumber"
									id="accountNumber"
									type={showAccountNumber ? 'text' : 'password'}
									value={formik.values.accountNumber}
									onChange={(event) => formik.setFieldValue('accountNumber', event.target.value)}
									error={formik.touched.accountNumber && (formik.errors.accountNumber as string)}
								/>
								<Button variant="secondary" tone="neutral" size="sm" onClick={() => setShowAccountNumber(!showAccountNumber)} >{showAccountNumber
									? <Icon size='sm' name="eye" />
									: <Icon size="sm" name="eye-slash" />}
								</Button>
							</Box>
						</Box>
						<Box>
							<TextInput
								label="Confirm Account Number"
								name="confirmAccountNumber"
								id="confirmAccountNumber"
								type={showAccountNumber ? 'text' : 'password'}
								onChange={(event) => formik.setFieldValue('confirmAccountNumber', event.target.value)}
								value={formik.values.confirmAccountNumber}
								error={formik.touched.confirmAccountNumber && (formik.errors.confirmAccountNumber as string)}
								onPaste={(event: any) => event.preventDefault()}
							/>
						</Box>
					</Box>
				</Modal.Body>
				<Modal.Footer>
					<Button
						as="button"
						onClick={() => {
							formik.resetForm();
							closeModal();
						}}
						variant="secondary"
						tone="neutral"
						size="md"
						isLoading={formik.isSubmitting}
					>
						Cancel
					</Button>
					<Button variant="primary" size="md" type="submit" isLoading={formik.isSubmitting}>
						Save
					</Button>
				</Modal.Footer>
			</form>
		</Modal>
	);
};
