import { Box, Spinner } from '@palmetto/palmetto-components';
import { ActivityList } from '../../Activity/ActivityList';
import { useGetHistoryQuery } from '../../../services/history';
import { SabalEventType } from 'types';

const pricingTypes = [
  SabalEventType.sabalHoldbackCreated,
  SabalEventType.sabalHoldbackUpdated,
  SabalEventType.sabalModifierCreated,
  SabalEventType.sabalModifierUpdated,
  SabalEventType.sabalPriceSheetsMappingsCreated,
  SabalEventType.sabalPriceSheetsMappingsArchived,
  SabalEventType.sabalConstructionAdderCreated,
  SabalEventType.sabalConstructionAdderUpdated,
  SabalEventType.sabalPriceSheetCreated,
  SabalEventType.sabalPriceSheetUpdated
];
export const PricingHistory = () => {
  const { data, isLoading } = useGetHistoryQuery({
    types: pricingTypes,
  });
  const response = data?.data;
  return (
    <>
      <Box direction="row" childGap="3xl">
        <Box flex="auto">
          {response?.length > 0 ? (
            <ActivityList activities={response} />
          ) : isLoading ? (
            <Spinner size="lg" />
          ) : (
            <>
              <Box fontSize="sm" color="body-secondary">
                No history exists at the moment.
              </Box>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};
