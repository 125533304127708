import { HistorySearchQueryPayload } from 'types';
import { api } from './api';

export const historyAPI = api.injectEndpoints({
  endpoints: (build) => ({
    getHistory: build.query<any, HistorySearchQueryPayload | undefined>({
      query: (arg) => ({ url: `/history${arg ? (() => {
        const searchParams = new URLSearchParams();
        if(arg.types){
          searchParams.append('types', JSON.stringify(arg.types));
        }
        if(arg.pagination){
          if(!isNaN(arg.pagination.skip)){
            searchParams.append('skip', `${arg.pagination.skip}`);
          }
          if(!isNaN(arg.pagination.limit)){
            searchParams.append('limit', `${arg.pagination.limit}`);
          }
        }
        return searchParams.size > 0 ? `?${searchParams.toString()}` : '';
      })() : ''}` }),
      providesTags: () => [{ type: 'History', id: 'LIST' }],
    }),
  }),
});

export const { useGetHistoryQuery } = historyAPI;
