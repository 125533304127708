import { Box, FontColor, Icon, IconName, useBreakpoint } from '@palmetto/palmetto-components';
import { groupBy } from 'lodash';
import { DateTime } from 'luxon';
import { ReactNode } from 'react';
import { getActivityHistoryComponent } from './getActivityHistoryComponent';
import { useAuth } from '../auth/authProvider';
import { isPalmettoFinanceUser } from '../auth/RequirePalmettoFinanceUser';

export const convertIsoToDateMed = (isoDate: string) =>
  isoDate ? DateTime.fromISO(isoDate).toLocaleString(DateTime.DATE_MED) : null;

export const convertIsoToTimeSimple = (isoDate: string) =>
  isoDate ? DateTime.fromISO(isoDate).toLocaleString(DateTime.TIME_SIMPLE) : null;

export const ActivityList = ({ activities }: { activities: any[] }) => {
  const activitiesGroupedByDate = groupBy(activities, (activity) => convertIsoToDateMed(activity.createdAt));
  const { claims } = useAuth();
  const showUnknown = isPalmettoFinanceUser(claims);
  const components = Object.entries(activitiesGroupedByDate).map(([date, groupedActivities]) => {
    const activityComponents = getActivityHistoryComponent(groupedActivities, showUnknown);
    return (
      <Box margin="md 0 0 0" childGap="md" key={date}>
        <Box fontWeight="bold">{date}</Box>
        {activityComponents.map(
          (activityComponent) =>
            activityComponent && (
              <ActivityHistoryRow
                key={activityComponent.event.id}
                time={activityComponent.event.createdAt}
                icon={activityComponent.icon as IconName}
                iconColor={activityComponent.iconColor as FontColor}
              >
                <activityComponent.component event={activityComponent.event} />
              </ActivityHistoryRow>
            ),
        )}
      </Box>
    );
  });

  return (
    <Box display="block" padding={{ base: '0 lg', desktop: '0' }} fontSize="sm">
      {components.length > 0 ? components : 'No activity history at this time.'}
    </Box>
  );
};

interface ActivityHistoryRowProps {
  time: string;
  icon?: IconName;
  iconColor?: FontColor;
  children?: ReactNode;
}

export const ActivityHistoryRow: React.FC<ActivityHistoryRowProps> = ({
  time,
  icon,
  iconColor = 'body-tertiary',
  children,
}: ActivityHistoryRowProps) => {
  const { isPhone } = useBreakpoint();

  return (
    <Box
      direction="row"
      padding="sm 0"
      childGap="md"
      width="100"
      alignItems="flex-start"
      style={{ lineHeight: 'var(--size-line-height-text)' }}
    >
      <Box minWidth="lg" width="lg">
        {convertIsoToTimeSimple(time)}
      </Box>
      {!isPhone && (
        <Box minWidth="sm" padding="2xs 0 0 0" width="sm" color={iconColor}>
          {icon && <Icon size="md" name={icon} />}
        </Box>
      )}
      <Box flex="auto">{children}</Box>
    </Box>
  );
};
