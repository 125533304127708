import { Box, FormikTextInput } from '@palmetto/palmetto-components';
import { Field, useFormikContext } from 'formik';
import FormSection from '../Forms/FormSection';
import { UtilityNameSelectInput } from '../Account/UtilityNameSelectInput';
import { ActivationDocumentTypes, DocumentTypes, InterconnectionAgreementDocumentType, documentTitles } from 'types';
import { IncentivesDescription } from '../InstallMilestonePackage/InstallationFormSectionDescriptions';
import { DocumentList } from './Documents/DocumentList';
import { useParams } from 'react-router-dom';
import { useGetAccountQuery } from '../../services/accounts';

type IncentivesProps = {
  originContext?: string;
  allowArchive?: boolean
};

export const Incentives = ({ originContext, allowArchive }: IncentivesProps) => {
  const { id = '' } = useParams<{ id: string }>();
  const { data: account } = useGetAccountQuery(id);
  const { isSubmitting } = useFormikContext<any>();

  return (
    <FormSection description={IncentivesDescription} title="Incentive Documents">
      <DocumentList
        documentTypeFilter={[ActivationDocumentTypes.incentives]}
        title="Incentive Document"
        showTypeLabelOnItems={false}
        baseCategory={documentTitles.incentiveDocuments}
        uploadContext={originContext}
        allowArchive={allowArchive}
      />
      <DocumentList
        documentTypeFilter={[DocumentTypes.utilityBill]}
        title="Utility Bill"
        showTypeLabelOnItems={false}
        baseCategory={'Utility Bill'}
        uploadContext={originContext}
        allowArchive={allowArchive}
      />
      {account?.address?.state === 'CA' && (
        <DocumentList
          documentTypeFilter={[InterconnectionAgreementDocumentType.interconnectionAgreement]}
          title="Approved Interconnection Agreement"
          showTypeLabelOnItems={false}
          baseCategory={'Interconnection Agreement'}
          allowArchive={allowArchive}
        />
      )}
      <Box direction="row">
        <UtilityNameSelectInput isDisabled={true} />
        <Field
          type="number"
          label="Tariff Id"
          name="tariffId"
          id="tariffId"
          component={FormikTextInput}
          autoComplete="off"
          isDisabled={isSubmitting}
        />
      </Box>
    </FormSection>
  );
};
