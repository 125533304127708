import { ApprovedVendorSettings, ProgramType, VendorEquipmentTypes } from 'types';
import { api } from './api';

export const approvedVendorsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApprovedVendors: build.query<ApprovedVendorSettings, { programType?: ProgramType }>({
      query: ({ programType }) => ({ url: `approved-vendors${programType ? '?programType=' + programType : ''}` }),
    }),
    getApprovedVendorsByType: build.query<any, { programType?: ProgramType; equipmentType?: VendorEquipmentTypes }>({
      query: ({ programType, equipmentType }) => ({
        url: `approved-vendors/${equipmentType}${programType ? '?programType=' + programType : ''}`,
      }),
    }),
  }),
});

export const { useGetApprovedVendorsQuery, useGetApprovedVendorsByTypeQuery } = approvedVendorsApi;
