import { Field, Form, useFormikContext } from 'formik';
import FormSectionContainer from '../Forms/FormSectionContainer';
import FormSection from '../Forms/FormSection';
import { Box, BoxProps, Button, FormikTextInput, Icon, Spinner } from '@palmetto/palmetto-components';
import { percentageFormatter } from '../../helpers/percentageFormatter';
import { formatKWHNumber } from '../../helpers/kwhFormatter';
import {
  CheckButtonProps,
  CommonFormSectionProps,
  ConfirmObject,
  ErrorObject,
  FormFooterActionProps,
  FormFooterProps,
  FormSectionProps,
  IInputFormProps,
  LoadingState,
  Origin,
} from '../../types/ProductionValueCheck';
import { useNavigate } from 'react-router-dom';
import { ChangeEvent, useCallback } from 'react';

export function InputForm({
  onChange,
  errorObject,
  loadingState,
  activeQuote,
  origin,
  confirmObject,
}: IInputFormProps) {
  const { setFieldValue, values } = useFormikContext<any>();
  const currentSFYPKwh = activeQuote ? activeQuote.systemFirstYearProductionKwh : 0;

  const setValue = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setFieldValue(event.target.name, event.target.value);
      onChange();
    },
    // eslint-disable-next-line
    [setFieldValue, values, onChange],
  );

  const isFromInstallPackage = origin === Origin.INSTALL_PACKAGE;
  const installedEstimateLabel = isFromInstallPackage
    ? 'Installed Year-1 Total Production Estimate'
    : 'New Total Year-1 Production Estimate';

  return (
    <Form noValidate>
      <FormSectionContainer>
        <CommonFormSection title="Contracted Year-1 Total Production Estimate">
          <Box fontWeight={'medium'} fontSize={'lg'}>
            {formatKWHNumber(currentSFYPKwh)} kWh
          </Box>
        </CommonFormSection>
        <CommonFormSection
          title={installedEstimateLabel}
          description="If any change to the pv system design has occurred between sale and install, input the updated production number here to determine if a new contract is required."
          baseStyle={{
            borderColor: errorObject ? 'transparent' : 'separator',
            padding: errorObject ? 'md 0 lg 0' : 'md 0',
          }}
        >
          <Field
            name="systemFirstYearProductionKwh"
            id="systemFirstYearProductionKwh"
            component={FormikTextInput}
            isDisabled={somethingIsUpdating(loadingState)}
            suffix="kWh"
            maxWidth="2xl"
            onChange={setValue}
          />
        </CommonFormSection>
        <DifferenceFormSection errorObject={errorObject} confirmObject={confirmObject} />
        <FormFooter origin={origin} confirmObject={confirmObject} loadingState={loadingState} />
      </FormSectionContainer>
    </Form>
  );
}

export function somethingIsLoading(loadingState: LoadingState) {
  return Object.values(loadingState).includes(true);
}

export function somethingIsUpdating(loadingState: LoadingState) {
  return loadingState.isUpdating || loadingState.isVoiding;
}

export function getDiffErrorLabel(error: ErrorObject) {
  return `${percentageFormatter.format(error.percentErrorDiff)} (${formatKWHNumber(error.errorKwH)} kWh)`;
}

export function getDiffConfirmLabel(object: ConfirmObject) {
  return `${percentageFormatter.format(object.percentDiff)}`;
}

export const DifferenceFormSection = ({ errorObject, confirmObject }: FormSectionProps) => {
  if (!errorObject && !confirmObject) {
    return null;
  }
  let label: string = '';

  if (errorObject) {
    label = getDiffErrorLabel(errorObject);
  } else if (confirmObject) {
    label = getDiffConfirmLabel(confirmObject);
  }
  const hasConfirmationWithoutError = confirmObject && !errorObject;

  return (
    <CommonFormSection
      title="Difference"
      baseStyle={{
        padding: 'md 0 lg 0',
        borderColor: 'separator',
      }}
    >
      <Box
        direction={{
          base: 'column',
          tablet: 'row',
        }}
        childGap={{ base: 'lg', desktop: 'xl' }}
      >
        <Box
          fontWeight={'medium'}
          fontSize={'lg'}
          color={hasConfirmationWithoutError ? 'contrast' : 'danger'}
          data-testid="pvcheck-input-form-diff-lbl"
          display={'flex'}
          direction={'row'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          {label}
          {hasConfirmationWithoutError && (
            <Box fontSize={'sm'} margin={'0 0 0 sm'} display={'flex'} direction={'row'} color={'success-500'}>
              &nbsp;
              <Icon name="check" size="sm" color="success" />
              <Box
                as="span"
                style={{
                  marginLeft: '0.2em',
                }}
              >
                {' '}
                No change order required, as it is within the allowable range.{' '}
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </CommonFormSection>
  );
};

export function CheckButton({ confirmObject, loadingState, origin }: CheckButtonProps) {
  const comesFromInstallMilestone = origin === Origin.INSTALL_PACKAGE;
  const isWorking = loadingState.isChecking || loadingState.isVoiding || loadingState.isUpdating;
  const isLoading = somethingIsLoading(loadingState);

  if (comesFromInstallMilestone) {
    return (
      <Button
        variant="primary"
        type="submit"
        isLoading={isWorking}
        iconSuffix="arrow-right"
        disabled={isLoading}
        data-testid="frm-foot-check-cta"
      >
        Next
      </Button>
    );
  }
  if (confirmObject) {
    return (
      <Button
        variant="primary"
        type="submit"
        isLoading={isWorking}
        disabled={isLoading}
        data-testid="frm-foot-check-cta"
      >
        Save
      </Button>
    );
  }
  return (
    <Button
      variant="primary"
      type="submit"
      isLoading={isWorking}
      disabled={isLoading}
      data-testid="frm-foot-check-cta"
    >
      Check
    </Button>
  );
}

export const FormFooterActions = ({ loadingState, origin, confirmObject }: FormFooterActionProps) => {
  const navigate = useNavigate();
  function goBack() {
    navigate(-1);
  }
  const loading = somethingIsLoading(loadingState);

  return (
    <Box
      {...boxStyle}
      width={{
        base: '100',
        tablet: 'auto',
      }}
    >
      <Button onClick={goBack} variant="secondary" tone="neutral" isDisabled={loading}>
        Cancel
      </Button>
      <CheckButton confirmObject={confirmObject} loadingState={loadingState} origin={origin} />
    </Box>
  );
};

export const FormFooter = ({ loadingState, origin, confirmObject }: FormFooterProps) => {
  return (
    <Box {...boxStyle} justifyContent={boxStyle.justifyContent} alignItems={'center'}>
      {loadingState.isChecking && (
        <Box width={'100%'} display={'flex'} direction={'row'} alignItems={'center'}>
          <Box data-testid="frm-foot-spin-checking-spinn">
            <Spinner size="lg" color="info" />
          </Box>
          <Box padding={'0 sm 0 sm'} fontSize={'md'} color="body-primary">
            Validating new year-1 production estimate is within acceptable tolerance...
          </Box>
        </Box>
      )}
      <FormFooterActions confirmObject={confirmObject} loadingState={loadingState} origin={origin} />
    </Box>
  );
};

const boxStyle: BoxProps = {
  direction: {
    base: 'column',
    tablet: 'row',
  },
  alignItems: {
    base: 'stretch',
    tablet: 'flex-end',
  },
  justifyContent: {
    tablet: 'flex-end',
  },
  childGap: 'sm',
  style: { flexShrink: 0 },
  position: 'relative',
  padding: 'lg 0 0 0',
};

export function CommonFormSection({ baseStyle = {}, children, description, title }: CommonFormSectionProps) {
  return (
    <FormSection
      title={title}
      description={description}
      baseStyle={{
        padding: 'md 0',
        borderColor: 'transparent',
        ...baseStyle,
      }}
    >
      <Box
        data-testid="comm-frm-section"
        direction={{
          base: 'column',
          tablet: 'row',
        }}
        childGap={{ base: 'lg', desktop: 'xl' }}
      >
        {children}
      </Box>
    </FormSection>
  );
}
