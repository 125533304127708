import { ActivationDocumentTypes, Flag, InstallationPackagePhotoCategoryTitles, documentTitles } from "types"
import { DocumentList } from '../MilestonePackagePartials/Documents/DocumentList';
import FormSection from "../Forms/FormSection"
import { Alert } from "@palmetto/palmetto-components";

export const ConditionallyApprovedDocs = ({ flaggedDocs }: { flaggedDocs: Flag[] }) => {

	const flaggedMissingDocs = [...new Set(flaggedDocs?.map((flag: Flag & { category?: string }) => ((Object.values({
		...documentTitles, installPhotoDocumentation: 'Install Photo Documentation',
		designPackageDocuments: 'Design Package Documents',
	}).includes(flag.category || '') && !flag.resolved)
		? flag.category : undefined ||
			(Object.values(InstallationPackagePhotoCategoryTitles).includes(flag.dataKey || '') && !flag.resolved) ? flag.dataKey : undefined))
		.filter((missingDoc => missingDoc !== undefined)))];

	const MissingDocsList = () => {
		return (
			<ul>
				{flaggedMissingDocs.map((missingDoc?: any) => (
					<li key={missingDoc}>
						{missingDoc}
					</li>
				))}
			</ul>
		);
	};

	return (
		<FormSection title="Conditional Approval Information" description="Provide necessary documentation, files or notes that address conditional approval requirements">
			<Alert variant="warning"
				hasIcon
				fontSize="xs"
				title={`The installation package for this project was conditionally approved. 
							We need final proof of the following submitted along with the activation package for Milestone Approval:`}
				message={<MissingDocsList />}
			/>
			<DocumentList
				documentTypeFilter={[ActivationDocumentTypes.conditionalApproval]}
				title="Conditional Approval Documents"
				showTypeLabelOnItems={false}
				baseCategory={ActivationDocumentTypes.conditionalApproval}
			/>
		</FormSection>
	)
}
