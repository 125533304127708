import {
  Box,
  Button,
  Card,
  Column,
  Icon,
  Pagination,
  Table,
  toast,
  useOpenClose,
} from '@palmetto/palmetto-components';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { MainContainer } from '../MainContainer';
import DateTimeDisplay from '../DateTime';
import { FilterBar } from '../filters';
import PageHeader from '../PageHeader';
import { RemoveClaimModal } from './RemoveClaimModal';
import {
  AppliedDataFilter,
  UserInfo,
  QueueSortField,
  QueueStatus,
  queueStatusMap,
  paramToQueueTypeMap,
} from 'types';
import {
  useLazyGetQueuesQuery,
  useUpdateQueueMutation,
  useGetQueueListFiltersQuery,
  useGetQueuesQuery,
} from '../../services/queues';
import { useAuth } from '../auth/authProvider';
import './QueueTable.css';
import { AddFilterButtonDropdown } from '../filters/AddFilterButton';
import { SortButtonDropdown } from '../filters/SortButton';

const queueSortOptions: { id: string; label: string }[] = [
  {
    id: QueueSortField.UPDATED_AT_ASC,
    label: 'Oldest',
  },
  { id: QueueSortField.UPDATED_AT_DESC, label: 'Newest' },
];

const getMostRecentObj = (array: any[]) => {
  return array?.reduce((prev: any, current: any) => {
    return prev.date > current.date ? prev : current;
  });
};

export const Queue = () => {
  const { type = '' } = useParams();
  const queueProps = paramToQueueTypeMap[type];
  const { user } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const { isOpen: isModalOpen, handleClose: handleModalClose, handleOpen: openModal } = useOpenClose();

  const [trigger, { data, isLoading, isFetching }] = useLazyGetQueuesQuery({});
  const { data: unfilteredQueues } = useGetQueuesQuery({ type: queueProps?.type });
  const { data: filters = [], isLoading: isFiltersLoading } = useGetQueueListFiltersQuery({
    type,
  });
  const [updateQueue] = useUpdateQueueMutation();

  const { queues, total } = data || { queues: [], total: 0 };
  const { total: unfilteredTotal } = unfilteredQueues ?? 0;

  const [appliedFilters, setAppliedFilters] = useState<Array<any>>([]);
  const [modalData, setModalData] = useState<Record<string, string> | undefined>();

  const currentPage = searchParams.get('page') || 1;
  const searchTerm = searchParams.get('search') || undefined;
  const advancedFilters = searchParams.get('advancedFilters');
  const sort = (searchParams.get('sort') as QueueSortField) || QueueSortField.UPDATED_AT_ASC;
  const appliedSort = sort ? queueSortOptions.find((opt) => opt.id === sort) : queueSortOptions[0];
  const isFiltered = !!Array.from(searchParams.keys()).filter((key) => key !== 'sort').length;

  useEffect(() => {
    const pageNum = currentPage ? Number(currentPage) : 1;
    const filters = advancedFilters || JSON.stringify([]);

    setAppliedFilters(JSON.parse(filters));
    trigger({
      pageNum,
      advancedFilters: filters,
      searchTerm,
      sort: appliedSort?.id,
      type: queueProps?.type,
    });
  }, [trigger, currentPage, advancedFilters, searchTerm, appliedSort, queueProps]);

  const removeClaim = useCallback(
    (assignee: UserInfo, id: string) => {
      setModalData({ assigneeName: assignee.name, id });
      openModal();
    },
    [openModal],
  );

  const claimQueue = useCallback(
    async (id: string) => {
      await updateQueue({
        data: { assigneeId: user?.sub },
        id,
      }).unwrap();
      toast.success('Queue claimed');
    },
    [updateQueue, user],
  );

  const columns = useMemo(
    () => [
      {
        heading: 'status',
        cellClassName: 'queueStatus',
        render: (_: any, row: any) => {
          const mostRecentHoldOrRejectionObj =
            row?.status === QueueStatus.rejected
              ? getMostRecentObj(row?.rejectionReasons)
              : row?.status === QueueStatus.onHold
                ? getMostRecentObj(row?.holdReasons)
                : undefined;
          return (
            <Box childGap="xs" alignItems="center" className={row.status}>
              <Box alignItems="center" childGap="xs">
                {row.status === 'onHold' && <Icon name="pause" size="lg" color="warning-500" />}
                {row.status === 'rejected' && <Icon name="remove" size="lg" color="danger" />}
                <Box textAlign="center" fontSize="sm" fontWeight="medium">
                  {queueStatusMap[row.status]}
                </Box>
              </Box>
              {mostRecentHoldOrRejectionObj?.reasons ? (
                <Box fontWeight="medium" fontSize="xs">
                  {mostRecentHoldOrRejectionObj.reasons.join(', ')}
                </Box>
              ) : null}
              <Box>
                <DateTimeDisplay value={row.updatedAt} showDuration fontSize="xs" dateFormat="DD h:mm:ss a" inline />
              </Box>
            </Box>
          );
        },
      },
      {
        heading: 'Account',
        dataKey: 'account',
        render: (cell: any) => {
          return (
            <Box display="block" childGap="2xs" fontSize="xs" padding="sm 0" color="body-secondary">
              <Box display="block" fontWeight="medium" fontSize="sm">
                <Link to={queueProps.getAccountReviewLink(cell.id)}>{cell.primaryApplicantName}</Link>
              </Box>
              {cell.address && (
                <Box childGap="2xs">
                  <Box>{cell.address.address1}</Box>
                  <Box>
                    {cell.address.city} {cell.address.state} {cell.address.zip}
                  </Box>
                </Box>
              )}
              {cell.applicants?.length && cell.applicants[0].email && <Box>{cell?.applicants[0]?.email}</Box>}
              {cell.externalReference && <Box>{`Ext. Reference: ${cell.externalReference}`}</Box>}
            </Box>
          );
        },
      },
      {
        heading: 'claimed by',
        dataKey: 'assignee',
        render: (cell: any, { id }: any) => {
          if (cell) {
            return (
              <Box direction="row" gap="4xs" alignItems="center" fontSize="xs">
                <Box>{cell.name}</Box>
                <Box>
                  <Button
                    variant="tertiary"
                    tone="neutral"
                    iconPrefix="c-remove"
                    aria-label="remove"
                    size="xs"
                    onClick={() => removeClaim(cell, id)}
                  />
                </Box>
              </Box>
            );
          } else {
            return (
              <Button size="xs" variant="primary" tone="neutral" onClick={() => claimQueue(id)}>
                Claim
              </Button>
            );
          }
        },
      },
      {
        heading: 'licensed org',
        render: (_: any, { account }: any) => {
          return (
            <Box fontSize="xs" padding="sm 0" color="body-secondary">
              <Box>{account.licensedOrganizationName}</Box>
            </Box>
          );
        },
      },
      {
        heading: 'last submitted',
        dataKey: 'lastSubmittedAt',
        render: (cell: any) => <DateTimeDisplay value={cell} fontSize="xs" dateFormat="DD" showDuration inline />,
      },
    ],
    [removeClaim, claimQueue, queueProps],
  );

  const handleApplyAdvancedFilters = useCallback(
    (filtersToApply: AppliedDataFilter[]) => {
      if (filtersToApply.some((filter) => filter.selectedValues && filter.selectedValues.length)) {
        searchParams.set('advancedFilters', JSON.stringify(filtersToApply));
        setSearchParams(searchParams);
      }
      searchParams.delete('page');
      setAppliedFilters(filtersToApply);
    },
    [setAppliedFilters, searchParams, setSearchParams],
  );

  const handleRemoveAdvancedFilter = useCallback(
    (filterId: string) => {
      const newFilters = appliedFilters.filter((filter) => filter.id !== filterId);
      if (newFilters.length) {
        searchParams.set('advancedFilters', JSON.stringify(newFilters));
      } else {
        searchParams.delete('advancedFilters');
      }
      searchParams.delete('page');
      setSearchParams(searchParams);
      setAppliedFilters(newFilters);
    },
    [appliedFilters, setAppliedFilters, searchParams, setSearchParams],
  );

  const handleClearAdvancedFilters = useCallback(() => {
    searchParams.delete('advancedFilters');
    searchParams.delete('page');
    setSearchParams(searchParams);
    setAppliedFilters([]);
  }, [setAppliedFilters, searchParams, setSearchParams]);

  const handleSortSelection = useCallback(
    (sortSelection: { id: string; label: string }) => {
      searchParams.set('sort', sortSelection.id);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams],
  );

  const onPageChange = (newPage: number) => {
    searchParams.set('page', newPage.toString());
    setSearchParams(searchParams);
  };
  const onSearchChange = (searchTerm: string) => {
    if (searchTerm && searchTerm !== '') {
      searchParams.set('search', searchTerm);
    } else {
      searchParams.delete('search');
    }
    searchParams.delete('page');
    setSearchParams(searchParams);
  };

  return (
    !isLoading && (
      <>
        <Helmet>
          <title>{`${queueProps?.label} Queue`}</title>
        </Helmet>
        <RemoveClaimModal isModalOpen={isModalOpen} closeModal={handleModalClose} data={modalData} />
        <MainContainer>
          <PageHeader
            title={`Review ${queueProps?.label} Queue`}
            eyebrow={
              queueProps?.legacyType ? (
                <Link
                  to={`/queues/review/${queueProps?.legacyType}?status=ready${queueProps?.documentType ? '&documentType=' + queueProps.documentType : ''}`}
                >
                  Click here for older items still in review.
                </Link>
              ) : null
            }
          />
          <Card>
            <Box padding="lg lg 0 lg">
              <FilterBar
                filtersState={{
                  searchTerm,
                }}
                handleSearchTerm={(searchTerm: string) => onSearchChange(searchTerm)}
              >
                <Box direction="row" style={{ flexGrow: 1 }}>
                  {!isFiltersLoading && (
                    <AddFilterButtonDropdown
                      dataFilters={filters}
                      appliedFilters={appliedFilters}
                      onRemoveFilter={handleRemoveAdvancedFilter}
                      onApplyFilters={handleApplyAdvancedFilters}
                      onClearFilters={handleClearAdvancedFilters}
                    />
                  )}
                  <SortButtonDropdown
                    appliedSort={appliedSort}
                    sortOptions={queueSortOptions}
                    onSortChange={handleSortSelection}
                    className="sortButton"
                  />
                </Box>
              </FilterBar>
              <Box color="body-primary" fontSize="sm" fontWeight="bold" width="100" padding="lg sm sm sm">
                {!isFetching ? (
                  isFiltered ? (
                    <>
                      {`${queues?.length} match${queues?.length && queues.length > 1 ? 'es' : ''}`}{' '}
                      {`of ${unfilteredTotal}`}
                    </>
                  ) : (
                    `${total} Items`
                  )
                ) : isFiltered ? (
                  <>
                    {`-- match${queues?.length && queues.length > 1 ? 'es' : ''}`} {`of ${unfilteredTotal}`}
                  </>
                ) : (
                  `-- Items`
                )}
              </Box>
              <Table
                rowKey="id"
                columns={columns as Column[]}
                className="fade-in"
                rows={queues}
                align="left"
                isScrollable={{
                  x: true,
                  y: false,
                }}
              />
            </Box>
            {queues?.length > 0 && (
              <Pagination
                activePage={Number(currentPage) || 1}
                arePagesVisible
                itemsPerPage={20}
                numberOfPagesDisplayed={3}
                onChange={(page) => onPageChange(page)}
                totalItemsCount={total}
                isCompact
              />
            )}
          </Card>
        </MainContainer>
      </>
    )
  );
};
