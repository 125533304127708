import { Button, Modal, toast } from '@palmetto/palmetto-components';
import { Form, Formik, FormikHelpers, FormikValues } from 'formik';
import { SyntheticEvent, useCallback, useMemo } from 'react';
import { ModifierType, modifierMap } from 'types';
import { useCreateModifierMutation } from '../../../services/pricing';
import {
  CreateEnergyCommunityModifierForm,
  createEnergyCommunityModifierValidationSchema,
} from './CreateEnergyCommunityModifierForm';
import { CreatePpwAdjustmentForm, createPpwAdjustmentValidationSchema } from './CreatePpwAdjustmentForm';

const initialValues = {
  name: '',
};

export const CreateModifierModal = ({
  isModalOpen,
  closeModal,
  modifierType,
}: {
  isModalOpen: boolean;
  closeModal: (event?: SyntheticEvent<Element, Event> | undefined) => void;
  modifierType?: ModifierType;
}) => {
  const [createModifier] = useCreateModifierMutation();
  const onSubmit = useCallback(
    async (values: FormikValues, { setSubmitting, resetForm }: FormikHelpers<FormikValues>) => {
      try {
        await createModifier({
          data: {
            modifierType,
            ...values,
          },
        }).unwrap();
        toast.success('Adder created successfully');
        resetForm();
        closeModal();
      } catch (e) {
        console.log(e);
        toast.error('Error creating modifier');
      } finally {
        setSubmitting(false);
      }
    },
    [closeModal, createModifier, modifierType],
  );

  const validationSchema = useMemo(() => {
    switch (modifierType) {
      case ModifierType.energyCommunity:
        return createEnergyCommunityModifierValidationSchema;
      case ModifierType.ppwAdjustment:
        return createPpwAdjustmentValidationSchema;
    }
  }, [modifierType]);

  const formFields = useMemo(() => {
    switch (modifierType) {
      case ModifierType.energyCommunity:
        return <CreateEnergyCommunityModifierForm />;
      case ModifierType.ppwAdjustment:
        return <CreatePpwAdjustmentForm />;
    }
  }, [modifierType]);

  return (
    <Modal ariaLabelledBy="createModifier" isOpen={isModalOpen} onDismiss={closeModal} maxWidth="500px">
      <Modal.Header
        id="createModifier"
        title={`Add ${modifierMap[modifierType as ModifierType]} Modifier`}
        onDismiss={closeModal}
      />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit as any}
        enableReinitialize
        validateOnBlur
      >
        {({ resetForm, isSubmitting }) => {
          return (
            <Form>
              <Modal.Body background="secondary">{formFields}</Modal.Body>
              <Modal.Footer>
                <Button
                  as="button"
                  onClick={() => {
                    resetForm();
                    closeModal();
                  }}
                  variant="secondary"
                  tone="neutral"
                  size="md"
                  isLoading={isSubmitting}
                >
                  Cancel
                </Button>
                <Button variant="primary" size="md" type="submit" isLoading={isSubmitting}>
                  Add Option
                </Button>
              </Modal.Footer>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};
