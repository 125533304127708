import { Helmet } from 'react-helmet';
import { Box, Button, toast } from '@palmetto/palmetto-components';
import { Form, Formik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import PageHeader from '../PageHeader';
import { useGetDisclosuresByTypeQuery } from '@/services/disclosures';
import { MainContainer } from '@/components';
import { isErrorWithData } from '@/services/helpers';
import { useInviteToken } from '@/components/auth/useInviteToken';
import { AccountRequirementStatus, DisclosureDocument, DisclosureType, Locale, MilestoneRequirement } from 'types';
import { DisclosureCard } from '@/components/Disclosures/DisclosureCard';
import FormSection from '@/components/Forms/FormSection';
import { useUpdateMilestoneRequirementMutation } from '@/services/accounts';
import { useMemo } from 'react';

export function DisclosureForm() {
  const { id } = useParams<{ id: any }>();
  const { inviteToken } = useInviteToken();
  // these can be parameterized to handle further disclosures in the future
  const disclosureType = DisclosureType.termsAndConditions;
  const requirementType = MilestoneRequirement.acceptTermsAndConditions;
  const initialValues = {
    [disclosureType]: false,
  };
  const [acceptDisclosure] = useUpdateMilestoneRequirementMutation();
  // we may eventually be able to get what disclosures are required from milestone requirements
  const { data: disclosures, isLoading: isDisclosuresLoading } = useGetDisclosuresByTypeQuery({
    type: disclosureType,
    inviteToken,
    language: [Locale.en, Locale.es],
  });

  const sortedDisclosures = useMemo(() => {
    if (!disclosures) {
      return [];
    }
    const sorted = [...disclosures].sort((a: any, b: any) => {
      if (a.language === Locale.es) return -1;
      if (b.language === Locale.es) return 1;
      return 0;
    });
    return sorted;
  }, [disclosures]);

  const navigate = useNavigate();
  const handleSubmit = async (values: any, { setSubmitting }: any) => {
    if (!values[disclosureType]) {
      toast.error('Please acknowledge the disclosure');
      setSubmitting(false);
      return;
    }
    try {
      await acceptDisclosure({
        accountId: id,
        inviteToken,
        requirementType,
        status: AccountRequirementStatus.completed,
      });
      toast.success('Disclosure acknowledged successfully');
      navigate(`/accounts/${id}/disclosure-confirmation?userState=Authenticated&inviteToken=${inviteToken}`);
    } catch (e) {
      if (isErrorWithData(e)) {
        const errorMessage = e.data.message;
        toast.error(errorMessage);
      } else {
        console.error(e);
        toast.error('Error saving disclosures');
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Terms and Conditions</title>
      </Helmet>
      <MainContainer>
        <PageHeader title="Términos y Condiciones / Terms and Conditions" />
        <Box>
          <Box
            fontSize={{
              base: 'sm',
              tablet: 'md',
              desktop: 'lg',
            }}
            color="body-secondary"
          >
            Revise los siguientes términos y condiciones para su acuerdo de compra de energía
          </Box>
          –
          <Box
            fontSize={{
              base: 'sm',
              tablet: 'md',
              desktop: 'lg',
            }}
            color="body-secondary"
          >
            Please review the following terms and conditions for your power purchase agreement
          </Box>
        </Box>
        <Box padding={{ base: '0 lg', tablet: '0' }}>
          <Formik
            initialValues={initialValues}
            validateOnChange={false}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({ isSubmitting }) => (
              <Form noValidate>
                <FormSection title="Divulgaciones / Disclosures">
                  <Box childGap="lg">
                    {sortedDisclosures?.length > 0 &&
                      sortedDisclosures.map((disclosure: any) => (
                        <Box>
                          <DisclosureCard
                            disclosure={disclosure}
                            key={disclosure.id}
                            disclosureProperty="termsAndConditions"
                            isLoading={isDisclosuresLoading}
                          />
                        </Box>
                      ))}
                  </Box>
                </FormSection>
                <Box
                  direction={{
                    base: 'column',
                    tablet: 'row',
                  }}
                  alignItems={{
                    base: 'stretch',
                    tablet: 'flex-end',
                  }}
                  justifyContent={{
                    tablet: 'flex-end',
                  }}
                  childGap="sm"
                  style={{ flexShrink: 0 }}
                  padding="lg 0 0 0"
                >
                  <Button
                    as="a"
                    href={`/accounts/${id}`}
                    variant="secondary"
                    tone="neutral"
                    size="md"
                    isDisabled={isDisclosuresLoading}
                    isLoading={isSubmitting}
                  >
                    Cancel
                  </Button>
                  <Button
                    size="md"
                    variant="primary"
                    type="submit"
                    isDisabled={isDisclosuresLoading}
                    isLoading={isSubmitting}
                  >
                    Submit
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </MainContainer>
    </>
  );
}
