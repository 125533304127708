import { UserWithDate } from './UserTypes';

export enum FlagReasons {
  missingDocumentation = 'Missing Documentation',
  illegibleDocumentation = 'Illegible Documentation',
  incorrectDocumentation = 'Incorrect Documentation',
  other = 'Other',
}

/** Used for changedFrom and changedTo in history */
export enum FlagStatus {
  flagged = 'flagged',
  resolved = 'resolved',
}
export interface Flag extends UserWithDate {
  reason: FlagReasons;
  dataKey: string;
  note?: string;
  fileUpload?: string;
  fileName?: string;
  resolved?: boolean;
  baseCategory?: string
}

export type FlagPayload = Omit<Flag, 'user' | 'date' | 'resolved'>;
