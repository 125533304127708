import { Alert, Badge, BadgeVariant, Box, Button, Card, useBreakpoint } from '@palmetto/palmetto-components';
import { useNavigate, useParams } from 'react-router-dom';
import { useMemo } from 'react';
import { useGetAccountQuery } from '../../services/accounts';
import {
  AccountMilestone,
  AccountRequirementDocument,
  AccountRequirementStatus,
  MilestoneRequirement,
  MilestoneType,
  QueueStatus,
  QueueType,
  QuoteStatus,
} from 'types';
import { useHasEnabledFlag } from '../FlagGuard/Hook';
import { Origin } from '../../types/ProductionValueCheck';
import { useGetQuotesQuery } from '../../services/quotes';
import { useGetQueueByAccountIdAndTypeQuery } from '@/services/queues';

export const installMilestoneStatusMap: {
  [key in AccountRequirementStatus]: {
    label: string;
    variant: BadgeVariant;
  };
} = {
  [AccountRequirementStatus.completed]: {
    label: 'Approved',
    variant: 'success',
  },
  [AccountRequirementStatus.rejected]: {
    label: 'Rejected',
    variant: 'danger',
  },
  [AccountRequirementStatus.pending]: {
    label: 'Pending',
    variant: 'warning',
  },
  [AccountRequirementStatus.submitted]: {
    label: 'Submitted',
    variant: 'warning',
  },
  [AccountRequirementStatus.resubmitted]: {
    label: 'Resubmitted',
    variant: 'warning',
  },
  [AccountRequirementStatus.inProgress]: {
    label: 'Pending',
    variant: 'warning',
  },
  [AccountRequirementStatus.error]: {
    label: 'Error',
    variant: 'warning',
  },
} as const;

export const InstallMilestonePackageContainer = () => {
  const { id } = useParams<{ id: any }>();
  const { data: account } = useGetAccountQuery(id);
  const navigate = useNavigate();
  const hasProdConfFlagEnabled = useHasEnabledFlag(['useProductionConfirmation']);
  const { data: quotes, isLoading: areQuotesLoading } = useGetQuotesQuery(id);

  const { isPhone } = useBreakpoint();

  const { data: queue } = useGetQueueByAccountIdAndTypeQuery({
    accountId: id,
    type: QueueType.installPackage,
  });

  const installPackageRequirement = useMemo(() => {
    if (!account) {
      return;
    }

    const installMilestone = account.milestones?.find((m: AccountMilestone) => m.type === MilestoneType.install);
    const installPackageRequirement: AccountRequirementDocument = installMilestone.requirements.find(
      (req: AccountRequirementDocument) => req.type === MilestoneRequirement.installPackage,
    );
    return installPackageRequirement as AccountRequirementDocument;
  }, [account]);

  const exceptionInformation = account?.systemDetails?.toleranceException;

  const installPackageStatus = installPackageRequirement
    ? installPackageRequirement.status
    : AccountRequirementStatus.pending;
  const isInstallPackageCreated = installPackageStatus !== AccountRequirementStatus.pending;
  const isLoading = areQuotesLoading;
  const hasActiveQuote = isLoading ? false : Boolean((quotes || []).find((element: any) => (element.status === QuoteStatus.active)));
  const queueStatusIsConditionallyApproved = queue && queue.status === QueueStatus.conditionallyApproved;

  const handleClick = function () {
    const hasAttestment = Boolean(account?.systemDetails?.attestment);
    //If it has an attestment, we skip it as it already has been attested.
    if (hasProdConfFlagEnabled() && hasActiveQuote && !hasAttestment) {
      navigate(`/accounts/${id}/production-value-check`, {
        state: {
          from: Origin.ACCOUNT_VIEW,
        },
      });
    } else {
      navigate(`/accounts/${id}/installation-package/create`);
    }
  };

  return (
    <Card margin="0 0 lg 0">
      <Box childGap="lg" padding="lg" alignItems="center" direction="row" justifyContent="space-between">
        <Box as="h3" fontWeight="medium" fontSize="md">
          Install Package
        </Box>
        {!isInstallPackageCreated && (
          <Button
            onClick={handleClick}
            size="sm"
            variant="primary"
            tone="neutral"
            iconPrefix="add"
            isLoading={isLoading}
          >
            {!isPhone && 'Install Package'}
          </Button>
        )}
      </Box>
      <Box
        borderWidth="xs 0 0 0"
        borderColor="separator"
        background="primary"
        padding={{ base: 'md 0', tablet: '0' }}
        direction="row"
        width="100"
        alignItems="center"
      >
        <Box width={{ base: '100', tablet: '60' }} direction={{ base: 'column', tablet: 'row' }}>
          <Box
            alignItems="flex-start"
            width={{ base: '100', tablet: '30' }}
            padding={{ base: 'md lg', tablet: 'md md md lg' }}
          >
            {isInstallPackageCreated && (
              <Badge
                message={installMilestoneStatusMap[installPackageStatus].label}
                variant={installMilestoneStatusMap[installPackageStatus].variant}
              />
            )}
            {isInstallPackageCreated && exceptionInformation && (
              <Box margin="sm 0 0 0">
                <Badge message="Exception" variant="warning" />
              </Box>
            )}
          </Box>
          <Box
            width={{ base: '100', tablet: '70' }}
            display="flex"
            childGap="xs"
            padding={{ base: '0 lg', tablet: 'md md md lg' }}
            justifyContent={{ base: queueStatusIsConditionallyApproved ? 'center' : 'flex-start' }}
            direction={{ base: 'column', tablet: 'row' }}
            gap="md"
          >
          </Box>
        </Box>
        {isInstallPackageCreated ? (
          <>
            <Box
              width="40"
              padding={{ base: '0 lg', tablet: 'md lg' }}
              direction={{ base: 'column', tablet: 'row' }}
              childGap="xs"
              justifyContent="flex-end"
              alignItems="flex-end"
            >
              {installPackageStatus !== AccountRequirementStatus.completed && (
                <div>
                  <Button
                    size="sm"
                    variant="secondary"
                    tone="neutral"
                    onClick={() => navigate(`installation-package/edit`)}
                  >
                    Edit
                  </Button>
                </div>
              )}
              {installPackageStatus === AccountRequirementStatus.completed && (
                <div>
                  <Button
                    size="sm"
                    variant="secondary"
                    tone="neutral"
                    onClick={() => navigate(`installation-package/review`)}
                  >
                    View
                  </Button>
                </div>
              )}
            </Box>
          </>
        ) : null}
      </Box>
    </Card>
  );
};
