import {
  Box,
  Card,
} from '@palmetto/palmetto-components';
import { StatusIcon } from '../StatusIcon';
import { AccountRequirementStatus } from '../../types/Milestone';

interface TermsAndConditionProps {
  status: AccountRequirementStatus;
}

export function TermsAndConditions({ status }: TermsAndConditionProps) {
  return (
    <>
      <Card.Section childGap="md">
        <Box childGap="lg" alignItems="center" direction="row" justifyContent="space-between">
          <Box direction="row" alignItems="center">
            <Box padding="0 xs 0 0">
              <StatusIcon status={status} size="md" />
            </Box>
            <Box as="h3" fontWeight="medium" fontSize="md">
              Terms and Conditions
            </Box>
          </Box>
          {status === 'completed' ? (
            <Box fontWeight="medium" fontSize="sm" color="body-secondary">
              Terms and Conditions Accepted
            </Box>
          ) :
            (
              <Box fontWeight="medium" fontSize="sm" color="body-secondary">
                Terms and Conditions Not Accepted
              </Box>
            )
          }
        </Box>
      </Card.Section>
    </>
  );
}
