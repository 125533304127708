import { Box } from '@palmetto/palmetto-components';
import { NavLink, Route, Routes, useParams, useNavigate } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import { modifierMap } from 'types';
import { SpecificModifier } from './SpecificModifier';

export const Modifiers = () => {
  const navigate = useNavigate();
  const { programType, modifierType } = useParams<{ programType: string; modifierType: string }>();

  // if no modifier selected, use first one, if invalid modifier selected, redirect to main modifiers page
  useEffect(() => {
    if (!modifierType) {
      const modifier = Object.keys(modifierMap)[0];
      navigate(`${modifier}`);
    }
  }, [modifierMap, modifierType, navigate]);

  return (
    <Box direction="row" childGap="3xl">
      <Box>
        {Object.keys(modifierMap).map((type) => (
          <NavLink
            to={`/admin/pricing/${programType}/modifiers/${type}`}
            style={{ textDecoration: 'none', fontWeight: '500' }}
            key={type}
            end
          >
            {({ isActive }) => (
              <Box
                as="span"
                padding="md"
                className={isActive ? 'tab-active' : ''}
                hover={{ color: 'primary' }}
                style={{ transitionDuration: '0s', borderBottom: 0 }}
              >
                {modifierMap[type as keyof typeof modifierMap]}
              </Box>
            )}
          </NavLink>
        ))}
      </Box>
      <Box flex="auto">
        <Routes>
          <Route path="/:modifierType?" element={<SpecificModifier />} />
        </Routes>
      </Box>
    </Box>
  );
};
