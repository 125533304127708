import { ObjectId } from 'mongodb';
import { StateCode } from './Helpers';
import { LineItemCategories, LineItemTypes } from './InboundPayments';
import { AccountStipulationReason, DisclosureType, ProgramType } from '.';
import { CaptivateIQSettings } from './CaptivateIQ';

export enum AppSettingsTypes {
  approvedVendorList = 'approvedVendorList',
  cedSplitPayPrograms = 'cedSplitPayPrograms',
  customQuoteSettings = 'customQuoteSettings',
  disclosures = 'disclosures',
  inboundPayment = 'inboundPayment',
  inboundPaymentsCustomerTaxSettings = 'inboundPaymentsCustomerTaxSettings',
  orgContractRequirements = 'orgContractRequirements',
  pricing = 'pricing',
  priceSheetMappingLock = 'priceSheetMappingLock',
  quoteValidation = 'quoteValidation',
  stateLicenseRequirements = 'stateLicenseRequirements',
  stateSalesRepEmailRequirements = 'stateSalesRepEmailRequirements',
  stateSolarIncentives = 'stateSolarIncentives',
  testSSNs = 'testSSNs',
  underwriting = 'underwriting',
  utilityNetMeteringRates = 'utilityNetMeteringRates',
  netsuite = 'netSuite',
  designTools = 'designTools',
  concord = 'concord',
  capitvateIQ = 'capitvateIQ',
  stipulations = 'stipulations',
}

export interface IAppSettingsDocument {
  id: ObjectId;
  type: AppSettingsTypes;
  value: Record<string, any>;
}

export interface ICEDSplitPayProgramNamesSettingsDocument extends IAppSettingsDocument {
  value: {
    programs: {
      programId: string;
      programName: string;
    };
    parallelPayShipConfirmPaymentPercentage: number;
  };
}

export interface ICustomQuoteSettingsDocument extends IAppSettingsDocument {
  value: {
    approvedOrganizations: {
      organizationId: string;
      maxEscalationRate: number;
    }[];
  };
}

export interface IDesignTools extends IAppSettingsDocument {
  value: {
    aerialytic: string;
    aurora: string;
    bostonSolar: string;
    eagleview: string;
    openSolar: string;
    pvWatts: string;
    scanify: string;
    solargraf: string;
    solo: string;
    subcontractorHub: string;
    sunRebel: string;
  };
}

export interface IQuoteValidationSettingsDocument extends IAppSettingsDocument {
  value: {
    preConAdderLimit: number;
    maxProductionDecrease: number;
    maxProductionIncrease: number;
    maxOveragePercentage: number;
  };
}

export interface IPricingSettingsDocument extends IAppSettingsDocument {
  value: {
    productionFactorRoundingThreshold: number;
    monthlyPaymentPanelDegradationRate: number;
    performanceGuaranteePanelDegradationRate: number;
    annualProductionGuaranteePercentage: number;
    programType: ProgramType;
  };
}

export interface IInboundPaymentSettingsDocument extends IAppSettingsDocument {
  value: {
    invoiceDueDay: number;
    billingDay: number;
    gracePeriodDurationInDays: number;
  };
}

export interface IDisclosuresSettingsDocument extends IAppSettingsDocument {
  value: { activeDisclosureVersions: Array<{ type: DisclosureType; version: number }> };
}

export interface StateRequirement {
  state: string;
  required: boolean;
  effectiveDate: Date;
}
export interface IStateLicenseRequirementsDocument extends IAppSettingsDocument {
  value: {
    requiresLicense: Array<StateRequirement>;
  };
}

export interface IStateSalesRepEmailRequirementDocument extends IAppSettingsDocument {
  value: {
    requiresSalesRepEmail: Array<StateRequirement>;
  };
}

export interface IInboundPaymentsCustomerTaxSettingsDocument extends IAppSettingsDocument {
  value: {
    state: {
      [key: StateCode]: {
        [key in LineItemCategories]: {
          [k in LineItemTypes]: InboundPaymentsCustomerTaxSetting;
        };
      };
    };
  };
}

export interface INetSuiteSettingsDocument extends IAppSettingsDocument {
  value: {
    invoices: {
      account: string;
      subsidiary: string;
      location: string;
      lineItems: NetSuiteInvoiceLineItemSetting[];
    };
  };
}

export interface NetSuiteInvoiceLineItemSetting {
  type: 'item' | 'tax';
  description: string;
  stripeId?: string;
  netSuiteId: string;
}

export interface InboundPaymentsCustomerTaxSetting {
  stripeTaxCode: StripeSolarTaxCode;
}

export enum StripeSolarTaxCode {
  // These are typically taxed
  TangibleGoods = 'txcd_99999999',

  // These are typically exempt from taxation
  Services = 'txcd_20030000',
}

export interface StateFieldRequirements {
  state: string;
  organizationRequiredFields: Array<string>;
  licenseRequiredFields: Array<string>;
}

export interface OrgContractRequirementsSettings extends IAppSettingsDocument {
  value: {
    stateFieldRequirements: Array<StateFieldRequirements>;
  };
}

export interface UnderwritingSettingsDocument extends IAppSettingsDocument {
  value: {
    minFicoScore: number;
    ficoScoreErrorThreshold: number;
    creditExpiryTerm: number;
    yearsSinceLastBankruptcy: number;
    minFicoToRerun: number;
  };
}

export enum VendorEquipmentTypes {
  inverter = 'inverter',
  panel = 'panel',
  mounting = 'mounting',
  storage = 'storage',
}

export enum ApprovedVendorAllocation {
  full = 'full',
  limited = 'limited',
  exception = 'exception',
}

export type ApprovedVendorSettingValue = {
  id: string;
  name: string;
  modelNumber: string;
  manufacturer: string;
  allocation: ApprovedVendorAllocation;
  needsApproval: boolean;
  maxDCkW?: number;
  maxACkW?: number;
  aliases?: string[];
  maxPrice?: number;
  requiresExtendedWarranty?: boolean;
  size?: {
    value: number;
    unit: 'kW' | 'kWh' | 'watts';
  };
};

export type ApprovedVendorSettings = {
  [key in VendorEquipmentTypes]: Array<ApprovedVendorSettingValue>;
};
export interface ApprovedVendorSettingsDocument extends IAppSettingsDocument {
  programType: ProgramType;
  value: ApprovedVendorSettings;
}

export type StipulationSettingValue = {
  /** Send stip emails */
  sendCommunication?: boolean;

  /** Time in seconds */
  webhookDelay?: number;
}

export type StipulationSettings = {
  [key in AccountStipulationReason]?: StipulationSettingValue;
};

export interface StipulationsSettingsDocument extends IAppSettingsDocument {
  value: StipulationSettings;
}

export interface PriceSheetMappingLockDocument extends IAppSettingsDocument {
  value: {
    days?: number;
  };
}

export interface CaptivateIQSettingsDocument extends IAppSettingsDocument {
  value: CaptivateIQSettings;
}

export enum SolarIncentiveUnit {
  dollars = 'dollars',
  dollarPerKWh = 'dollarPerKWh',
  dollarPerWattDC = 'dollarPerWattDC',
}

export interface StateSolarIncentivesDocument extends IAppSettingsDocument {
  value: {
    state: string;
    incentiveName: string;
    amount: number;
    unit: SolarIncentiveUnit;
    docusignFieldName: string;
    lseIds: number[];
  }[];
}

export interface UtilityNetMeteringSettingsDocument extends IAppSettingsDocument {
  value: {
    state: string;
    lseId: number;
    utilityName: string;
    fieldValue: string;
    docusignFieldName: string;
  }[];
}
