import { Badge, Box, Column, Icon, Table } from '@palmetto/palmetto-components';
import { Link, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { PriceSheetStatus } from 'types';
import { percentageFormatter } from '../../../../../helpers/percentageFormatter';
import { kwhPriceFormatter } from '../../../../../helpers/currencyFormatter';
import DateTimeDisplay from '../../../../DateTime';
import { useLazyGetOrgPriceSheetMappingsQuery } from '../../../../../services/organizations';
import { TableLoading } from '../../../../TableLoading';

const columnConfig: Column[] = [
  {
    heading: 'Status',
    dataKey: 'status',
    width: 125,
    render: (_cell: any, row: any) => (
      <Badge
        variant={
          row.status === PriceSheetStatus.active
            ? 'success'
            : row.status === PriceSheetStatus.archived
              ? 'warning'
              : 'danger'
        }
        message={row.status}
      />
    ),
  },
  { heading: 'org', dataKey: 'organizationName' },
  { heading: 'state', dataKey: 'state' },
  {
    heading: 'utility',
    dataKey: 'utility',
    render: (cell: any) => <Box>{cell.name}</Box>,
  },
  {
    heading: 'Price Sheet',
    dataKey: 'priceSheetName',
    render: (cell: any, row: any) => (
      <Link
        to={`/admin/pricing/${row.programType}/price-sheet/${row.priceSheetId}`}
        target="_blank"
        style={{ textDecoration: 'underline' }}
      >
        {cell}
      </Link>
    ),
  },
  {
    heading: 'esc min',
    dataKey: 'escalationRateMin',
    headerClassName: 'wrap',
    render: (cell: any) => {
      return <div>{percentageFormatter.format(cell)}</div>;
    },
  },
  {
    heading: 'esc max',
    dataKey: 'escalationRateMax',
    headerClassName: 'wrap',
    render: (cell: any) => {
      return <div>{percentageFormatter.format(cell)}</div>;
    },
  },
  {
    heading: 'pv-only min',
    dataKey: 'pvOnlyMin',
    headerClassName: 'wrap',
    render: (cell: any) => {
      return <div>{kwhPriceFormatter.format(cell)}</div>;
    },
  },
  {
    heading: 'pv-only max',
    dataKey: 'pvOnlyCeiling',
    headerClassName: 'wrap',
    render: (cell: any) => {
      return <div>{kwhPriceFormatter.format(cell)}</div>;
    },
  },
  {
    heading: 'zero escalator max',
    dataKey: 'zeroEscalatorCeiling',
    headerClassName: 'wrap',
    render: (cell: any) => {
      return cell && <div>{kwhPriceFormatter.format(cell)}</div>;
    },
  },
  {
    heading: 'absolute max',
    dataKey: 'absoluteCeiling',
    headerClassName: 'wrap',
    render: (cell: any) => {
      return <div>{kwhPriceFormatter.format(cell)}</div>;
    },
  },
  {
    heading: 'construction adders',
    dataKey: 'constructionAdders',
    headerClassName: 'wrap',
    render: (cell: any) => {
      return <Box childGap="xs">{cell?.map((adder: any) => <Box key={adder.name}>{adder.name}</Box>)}</Box>;
    },
  },
  {
    heading: 'modifiers',
    render: (_cell: any, row: any) => (
      <Box childGap="xs">
        {row.holdback && <Box>{row.holdback.name}</Box>}
        {row.energyCommunity && <Box>{row.energyCommunity.name}</Box>}
        {row.ppwModifier && <Box>{row.ppwModifier.name}</Box>}
      </Box>
    ),
  },
  {
    heading: 'Created',
    dataKey: 'createdDate',
    width: 150,
    render: (_cell: any, row: any) => <DateTimeDisplay value={row?.createdDate} dateFormat={'DD'} excludeTime />,
  },
  {
    render: (_cell: any, row: any) =>
      row.status !== 'archived' && (
        <Link
          to={`/admin/pricing/${row.programType}/mappings/edit/${row.id}`}
          style={{ textDecoration: 'underline' }}
          target="__blank"
        >
          <Icon color="body-primary" name="settings" size="sm" />
        </Link>
      ),
  },
];

export const Mappings = () => {
  const { alias } = useParams<{ alias: string }>();
  const [trigger, { data, isLoading, isFetching }] = useLazyGetOrgPriceSheetMappingsQuery();

  useEffect(() => {
    if (alias) {
      trigger({ alias });
    }
  }, [alias, trigger]);

  return (
    <Box margin="0 lg 0 lg">
      {isFetching || isLoading ? (
        <TableLoading rows={12} />
      ) : (
        <Table
          rowKey="id"
          columns={columnConfig as Column[]}
          rows={data ?? []}
          isLoading={isLoading || isFetching}
          isScrollable={{ x: true }}
          hasStickyHeader
        />
      )}
    </Box>
  );
};
