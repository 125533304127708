import { Box, FormikSelectInputNative, FormikTextInput } from '@palmetto/palmetto-components';
import { Field, useFormikContext } from 'formik';
import { mapAvlManufacturerOptions, mapAvlModelNumberOptions } from '../SystemDesign/AvlFormUtils';
import { ChangeEvent, useCallback, useMemo } from 'react';
import { useGetApprovedVendorsQuery } from '@/services/approvedVendors';
import { ApprovedVendorSettings, ProgramType } from 'types';

interface BatteryInputsProps {
  fieldName: string;
  isLoading: boolean;
  programType?: ProgramType;
}

export const BatteryInputs = ({ fieldName, isLoading, programType }: BatteryInputsProps) => {
  const { values, setFieldValue } = useFormikContext<any>();
  const { data: approvedVendorList, isLoading: isAppSettingsLoading } = useGetApprovedVendorsQuery({ programType });
  const { storage } = approvedVendorList ?? ({ storage: [] } as unknown as ApprovedVendorSettings);

  const storageManufacturerOptions = useMemo(() => {
    return isAppSettingsLoading ? [] : mapAvlManufacturerOptions(storage);
  }, [isAppSettingsLoading, storage]);

  const storageModelOptions = useMemo(() => {
    return !isAppSettingsLoading && values[fieldName]?.manufacturer
      ? mapAvlModelNumberOptions(storage, values[fieldName].manufacturer)
      : [];
  }, [fieldName, isAppSettingsLoading, storage, values]);

  const setManufacturer = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setFieldValue(`${fieldName}.manufacturer`, event.target.value);
      setFieldValue(`${fieldName}.model`, '');
    },
    [fieldName, setFieldValue],
  );

  return values?.[fieldName] ? (
    <Box
      direction={{ base: 'column', tablet: 'row' }}
      alignItems="center"
      childGap={{ base: 'lg', tablet: '3xl' }}
      width="100"
    >
      <Field
        label="Manufacturer"
        name={`${fieldName}.manufacturer`}
        id={`${fieldName}.manufacturer`}
        options={storageManufacturerOptions}
        component={FormikSelectInputNative}
        isDisabled={isLoading}
        onChange={setManufacturer}
      />
      <Field
        label="Model"
        name={`${fieldName}.model`}
        id={`${fieldName}.model`}
        options={storageModelOptions}
        component={FormikSelectInputNative}
        isDisabled={isLoading}
      />
      <Field
        label="Quantity"
        name={`${fieldName}.quantity`}
        id={`${fieldName}.quantity`}
        component={FormikTextInput}
        isDisabled={isLoading}
        type="number"
        maxWidth="lg"
      />
    </Box>
  ) : null;
};
