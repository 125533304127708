import React from 'react';
import { Box, Table, useBreakpoint } from '@palmetto/palmetto-components';
import { OrganizationNode } from '../../../../types/Organizations';
import {
  MilestoneType,
  MilestoneLabels,
  VendorPaymentSchedule,
  PaymentScheduleSetting,
  PaymentSettingsWithMetadata,
} from 'types';
import PaymentCard from '../components/PaymentCard';
import { getOrgNameFromAlias } from '../../../../helpers/getOrgNameFromAlias';
import { Link } from 'react-router-dom';

interface ViewPropertiesProps {
  paymentSchedule?: PaymentScheduleSetting[];
  scheduleOwnerAlias: string;
  inherited: boolean | undefined;
}

const ViewProperties = ({ paymentSchedule, scheduleOwnerAlias, inherited }: ViewPropertiesProps) => {
  const paymentScheduleColumns = [
    {
      heading: 'Milestone',
      dataKey: 'milestone',
      render: (cell: any) => (
        <Box fontWeight="medium" as="span" display="inline">
          {MilestoneLabels[cell as MilestoneType]}
        </Box>
      ),
    },
    {
      heading: 'Percentage',
      dataKey: 'paymentPercent',
      render: (cell: any) => (
        <Box fontWeight="medium" as="span" display="inline">
          {cell * 100}%
        </Box>
      ),
    },
    {
      heading: 'Modifier ($/watt)',
      dataKey: 'holdback',
      render: (cell: any) => (
        <Box fontWeight="medium" as="span" display="inline">
          {cell === 0 ? '-' : `$${cell}0`}
        </Box>
      ),
    },
  ];

  return (
    <Box padding="0 lg lg lg" childGap="xs">
      <Box
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        borderWidth="0 0 xs 0"
        borderColor="separator"
        padding="0 0 xs 0"
      >
        {inherited && (
          <>
            <Box fontWeight="medium">Inherited From</Box>
            <Box direction="row" childGap="xs" alignItems="center">
              <Link style={{ color: 'green' }} to={`/settings/organizations/${scheduleOwnerAlias}/payments`}>
                {getOrgNameFromAlias(scheduleOwnerAlias)}
              </Link>
            </Box>
          </>
        )}
      </Box>
      {paymentSchedule?.length && !inherited && (
        <Table rowKey="paymentSchedule" columns={paymentScheduleColumns} rows={paymentSchedule} />
      )}
    </Box>
  );
};
interface PaymentScheduleProps {
  vendorPaymentSchedule?: (VendorPaymentSchedule & PaymentSettingsWithMetadata) | undefined;
  isLoading?: boolean;
  organization?: OrganizationNode;
}

const PaymentSchedule = ({ vendorPaymentSchedule, isLoading, organization }: PaymentScheduleProps) => {
  const { isPhone } = useBreakpoint();

  return (
    <>
      {vendorPaymentSchedule ? (
        <PaymentCard
          isLoading={isLoading}
          organization={organization}
          isPhone={isPhone}
          cardTitle="Payment Schedule"
          href={`/settings/organizations/${organization?.alias}/payments/edit`}
        >
          <ViewProperties
            paymentSchedule={vendorPaymentSchedule.paymentSchedule}
            inherited={vendorPaymentSchedule.inherited}
            scheduleOwnerAlias={String(vendorPaymentSchedule.scheduleOwnerAlias)}
          />
        </PaymentCard>
      ) : (
        <PaymentCard
          isLoading={isLoading}
          organization={organization}
          isPhone={isPhone}
          cardTitle="Payment Schedule"
          href={`/settings/organizations/${organization?.alias}/payments/edit`}
        >
          <Box alignItems="center" justifyContent="space-evenly" height="300px">
            <Box>No Payment Schedule</Box>
          </Box>
        </PaymentCard>
      )}
    </>
  );
};

export default PaymentSchedule;
