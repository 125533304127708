import { Box, Column, Spinner, Table } from '@palmetto/palmetto-components';
import { useGetPriceSheetDetailsQuery } from '../../../services/pricing';
import { useParams } from 'react-router-dom';
import { useMemo } from 'react';
import { currencyFormatter, kwhPriceFormatter } from '../../../helpers/currencyFormatter';
import { percentageFormatter } from '../../../helpers/percentageFormatter';
import { priceSheetDetailColumns } from '../PriceSheets/priceSheetTableColumns';
import { ProgramType } from 'types';

export function PriceSheetDetails() {
  const { id, programType = ProgramType.solar } = useParams<{ id: any; programType: ProgramType }>();
  const { data: priceSheetDetails, isLoading: isPriceSheetDetailsLoading } = useGetPriceSheetDetailsQuery({ id });

  const tableData = useMemo(() => {
    if (isPriceSheetDetailsLoading) return [];
    if (!priceSheetDetails) return [];
    if (programType !== ProgramType.solar) return priceSheetDetails.prices;

    const priceSheetTableData: any[] = [];
    priceSheetDetails.prices.forEach((details: any, index: number) => {
      const row: any = {
        id: `${details.kwhRate}-${index}`,
        kwhRate: kwhPriceFormatter.format(details.kwhRate),
        escalationRate: `${percentageFormatter.format(details.escalationRate)}`,
      };
      details.ppwRateOptions.forEach((options: any) => {
        row[options.productionFactor.toString()] = `${currencyFormatter.format(options.ppw)}`;
      });

      priceSheetTableData.push(row);
    });
    return priceSheetTableData;
  }, [priceSheetDetails, isPriceSheetDetailsLoading]);

  const columnConfig = useMemo(() => {
    if (isPriceSheetDetailsLoading) return [];
    if (!priceSheetDetails) return [];
    if (programType !== ProgramType.solar) return priceSheetDetailColumns[programType];

    const productionFactors = priceSheetDetails.prices[0].ppwRateOptions.map((item: any) => ({
      heading: `${item.productionFactor}`,
      dataKey: `${item.productionFactor}`,
    }));
    return [
      {
        heading: 'KWH Rate',
        dataKey: 'kwhRate',
      },
      {
        heading: 'Escalator',
        dataKey: 'escalationRate',
      },
      ...productionFactors,
    ];
  }, [isPriceSheetDetailsLoading, priceSheetDetails]);

  return (
    <>
      {!isPriceSheetDetailsLoading && tableData && columnConfig ? (
        <Table
          rowKey="id"
          columns={columnConfig as Column[]}
          rows={tableData}
          isLoading={false}
          isScrollable={{
            x: true,
          }}
        />
      ) : (
        <Box display="block" textAlign="center">
          <Spinner size="lg" />
        </Box>
      )}
    </>
  );
}
