import { Field, useFormikContext } from 'formik';
import FormSection from '../Forms/FormSection';
import { Box, Button, FormikTextInput } from '@palmetto/palmetto-components';
import { ChangeEvent, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { formatKWHNumber } from '../../helpers/kwhFormatter';
import { percentageFormatter } from '../../helpers/percentageFormatter';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Origin } from '../../types/ProductionValueCheck';
import { isProductionWithinChangeToleranceFE } from '../../helpers/productionValueCheck';

interface EquipmentDetailsProps {
  isLoading: boolean;
  hasCurrentContract?: boolean;
  installMilestoneApproved: boolean;
  installed: boolean;
  accountId: string;
  firstYearContractedKwH: number;
  firsYearInstalledKwH: number;
}

interface InstalledViewInterface {
  firstYearContractedKwH: number;
  isSubmitting: boolean;
  isLoading: boolean;
  setNumericValue: (event: ChangeEvent<HTMLInputElement>) => void;
  installed: boolean;
  firsYearInstalledKwH: number;
  goToProdValueCheck: () => void;
  installMilestoneApproved: boolean;
}

export function InstalledView({
  firstYearContractedKwH,
  isSubmitting,
  isLoading,
  setNumericValue,
  installed,
  firsYearInstalledKwH,
  goToProdValueCheck,
  installMilestoneApproved,
}: InstalledViewInterface) {
  const diffResults = isProductionWithinChangeToleranceFE({
    newProductionKwh: firsYearInstalledKwH,
    originalProductionKwh: firstYearContractedKwH,
  });
  const percentDiff =
    firsYearInstalledKwH < firstYearContractedKwH
      ? diffResults.percentDifference * -1
      : diffResults.percentDifference;

  let label1YrTotalProd: string = 'New Year-1 Total Production Estimate';
  let ctrVsNew: string = 'Contracted Versus New Year-1 Production Difference';
  if (installed) {
    label1YrTotalProd = 'Installed Year-1 Total Production Estimate';
    ctrVsNew = 'Contracted Versus Install Year-1 Production Difference';
  }

  const canDisplayDiff = firstYearContractedKwH !== firsYearInstalledKwH;

  return (
    <>
      <FormSection title="Total Year-1 Production*" description="Required for NTP">
        <Box
          direction={{
            base: 'column',
            tablet: 'row',
          }}
          childGap={{ base: 'lg', desktop: 'xl' }}
        >
          <Box>
            <Box childGap="md" direction="column">
              <Box margin="0 0 0 0" childGap="xs">
                <Box fontWeight="medium">Contracted Year-1 Total Production Estimate</Box>
                <Box fontSize="sm" color="body-secondary" fontWeight={'regular'}>
                  An active contract is present on this account.
                </Box>
                <Box fontSize="lg" color="body-primary">
                  {' '}
                  {formatKWHNumber(firstYearContractedKwH)} kWh
                </Box>
              </Box>
              {canDisplayDiff && (
                <Box margin="0 0 0 0" childGap="xs">
                  <Box fontWeight="medium">{label1YrTotalProd}</Box>
                  <Box fontSize="lg" color="body-primary">
                    {' '}
                    {formatKWHNumber(firsYearInstalledKwH)} kWh
                  </Box>
                </Box>
              )}
              {canDisplayDiff && (
                <Box margin="0 0 0 0" childGap="xs">
                  <Box fontWeight="medium">{ctrVsNew}</Box>
                  <Box fontSize="lg" color="body-primary">
                    {' '}
                    {percentageFormatter.format(percentDiff)}{' '}
                  </Box>
                </Box>
              )}
              {!installed && !installMilestoneApproved && (
                <Box margin="0 0 0 0" childGap="xs">
                  <Button
                    variant="primary"
                    tone="neutral"
                    size={'sm'}
                    onClick={goToProdValueCheck}
                    data-testid="void-contract-action-button"
                    iconSuffix="arrow-right"
                    className="danger"
                  >
                    Year-1 Production Value Change Order Check
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </FormSection>
      <FormSection title="System Size*">
        <Box
          direction={{
            base: 'column',
            tablet: 'row',
          }}
          childGap={{ base: 'lg', desktop: 'xl' }}
        >
          <Field
            name="systemSizeKw"
            id="systemSizeKw"
            component={FormikTextInput}
            isDisabled={isSubmitting || isLoading}
            suffix="kW"
            maxWidth="2xl"
            onChange={setNumericValue}
          />
        </Box>
      </FormSection>
    </>
  );
}

export const SystemSizeAndFirstYearProductionSections = ({
  isLoading,
  hasCurrentContract,
  installMilestoneApproved,
  installed,
  accountId,
  firsYearInstalledKwH,
  firstYearContractedKwH,
}: EquipmentDetailsProps) => {
  const { isSubmitting, setFieldValue, setFieldTouched } = useFormikContext<any>();
  const navigate = useNavigate();
  const { useProductionConfirmation } = useFlags();

  const setNumericValue = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.value.endsWith('.')) {
        setFieldValue(event.target.name, event.target.value);
      } else {
        const numericValue = Number(event.target.value);
        if (!isNaN(numericValue)) {
          setFieldValue(event.target.name, numericValue);
        }
      }
    },
    [setFieldValue],
  );

  useEffect(() => {
    if (!isLoading) {
      setFieldTouched('systemFirstYearProductionKwh', true);
      setFieldTouched('systemSizeKw', true);
    }
  }, [isLoading, setFieldTouched]);

  function goToProdValueCheck() {
    navigate(`/accounts/${accountId}/production-value-check`, {
      state: {
        from: Origin.PV_SYSTEM_TAB,
      },
    });
  }

  if (useProductionConfirmation && hasCurrentContract) {
    return (
      <InstalledView
        installMilestoneApproved={installMilestoneApproved}
        firstYearContractedKwH={firstYearContractedKwH}
        isSubmitting={isSubmitting}
        isLoading={isLoading}
        setNumericValue={setNumericValue}
        installed={installed}
        firsYearInstalledKwH={firsYearInstalledKwH}
        goToProdValueCheck={goToProdValueCheck}
      />
    );
  }

  return (
    <>
      <FormSection title="Total Year-1 Production*">
        <Box
          direction={{
            base: 'column',
            tablet: 'row',
          }}
          childGap={{ base: 'lg', desktop: 'xl' }}
        >
          <Field
            name="systemFirstYearProductionKwh"
            id="systemFirstYearProductionKwh"
            component={FormikTextInput}
            isDisabled={isSubmitting || isLoading}
            suffix="kWh"
            maxWidth="2xl"
          />
        </Box>
      </FormSection>
      <FormSection title="System Size*">
        <Box
          direction={{
            base: 'column',
            tablet: 'row',
          }}
          childGap={{ base: 'lg', desktop: 'xl' }}
        >
          <Field
            name="systemSizeKw"
            id="systemSizeKw"
            component={FormikTextInput}
            isDisabled={isSubmitting || isLoading}
            suffix="kW"
            maxWidth="2xl"
          />
        </Box>
      </FormSection>
    </>
  );
};
