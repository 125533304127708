export enum ModifierType {
  energyCommunity = 'energyCommunity',
  lowAndMiddleIncome = 'lowAndMiddleIncome',
  ppwAdjustment = 'ppwAdjustment',
}

export const modifierMap: { [Key in ModifierType]?: string } = {
  [ModifierType.energyCommunity]: 'Energy Community',
  // [TaxCreditModifierType.lowAndMiddleIncome]: 'Low & Middle Income',
  [ModifierType.ppwAdjustment]: 'PPW Adjustment',
} as const;
