import React from 'react';
import { Box, Button } from '@palmetto/palmetto-components';
import RequirePermissions from '../../../auth/requirePermissions';
import { OrganizationNode } from '../../../../types/Organizations';

interface PaymentCardHeaderProps {
  href?: string;
  isPhone?: boolean;
  organization?: OrganizationNode;
  onClick?: () => void;
  cardTitle: string;
}

const PaymentCardHeader = ({ organization, isPhone, href, onClick, cardTitle }: PaymentCardHeaderProps) => {
  return (
    <Box childGap="lg" padding="lg" alignItems="center" direction="row" justifyContent="space-between">
      <Box as="h3" fontWeight="medium" fontSize="md">
        {cardTitle}
      </Box>
      {/* this will change to lightreachadmin or something else */}
      <RequirePermissions permissions={['admin']}>
        {organization && (
          <Button
            as="a"
            href={href}
            variant="secondary"
            tone="neutral"
            size="sm"
            aria-label="Edit Payment Schedule"
            iconPrefix="pencil"
            onClick={onClick}
          >
            {!isPhone && 'Edit'}
          </Button>
        )}
      </RequirePermissions>
    </Box>
  );
};

export default PaymentCardHeader;
