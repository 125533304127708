import { Box, Button, toast } from '@palmetto/palmetto-components';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { useCallback, useMemo } from 'react';
import { Sidebar } from '../Account/components/Sidebar';
import { SidebarSection } from '../Account/components/SidebarSection';
import { AdderType } from '../../types/Quotes';
import { useParams } from 'react-router-dom';
import { useGetAddersQuery, useUpdateAddersMutation } from '../../services/adders';
import { AdderDetails } from 'types';
import { AddersSidebarContent } from './AddersSidebarContent';
import { mapAddersValuesFromDocument, mapValuesToDocumentAdders } from '../../helpers/addersHelpers';
import { AddersList } from './AddersList';
import { useGetAccountQuery } from '@/services/accounts';

export type AddersTabType = {
  selectedAdders: AdderType[];
};

export type AdderValues = Record<AdderType, Omit<AdderDetails, 'type'>>;
export const adderItemSchema = yup.object({
  manufacturer: yup.string().nullable(),
  model: yup.string().nullable(),
  quantity: yup.number().nullable(),
});

export const addersSchema = yup.object({
  arbitrageBattery: adderItemSchema.optional().nullable(),
  backupBattery: adderItemSchema.optional().nullable(),
});

export const AddersTab = ({ selectedAdders }: AddersTabType) => {
  const { id } = useParams<{ id: string }>();
  const accountId = id as string;
  const { data: addersData, isLoading: isAddersLoading } = useGetAddersQuery(accountId);
  const { data: account, isLoading: isAccountLoading } = useGetAccountQuery(accountId);
  const isLoading = isAddersLoading || isAccountLoading;
  const [saveAdders] = useUpdateAddersMutation();
  const initialValues = useMemo(
    () => mapAddersValuesFromDocument(addersData?.adders, selectedAdders),
    [addersData?.adders, selectedAdders],
  );
  const selectedAddersMap = useMemo(
    () =>
      selectedAdders?.reduce(
        (acc, adder) => {
          acc[adder] = true;
          return acc;
        },
        {} as Record<AdderType, boolean>,
      ),
    [selectedAdders],
  );

  const handleSubmit = useCallback(
    async (values: AdderValues) => {
      try {
        await saveAdders({
          accountId,
          adders: { adders: mapValuesToDocumentAdders(values) },
        });
        toast.success('Adders Saved');
      } catch (e: any) {
        toast.error(e?.data?.message || 'Error saving adders');
      }
    },
    [accountId, saveAdders],
  );

  return (
    <Box
      direction={{ base: 'column', desktop: 'row' }}
      borderColor="separator"
      borderWidth="xs"
      overflow="hidden"
      radius="md"
    >
      <Sidebar title="Adders">
        <SidebarSection>
          <AddersSidebarContent selectedAddersMap={selectedAddersMap} />
        </SidebarSection>
      </Sidebar>

      <Box width={{ base: '100', desktop: '70' }} childGap="lg" padding={{ base: 'lg', desktop: 'xl' }}>
        <Formik
          initialValues={initialValues}
          validationSchema={addersSchema}
          validateOnChange={false}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({ isSubmitting }) => (
            <Form noValidate>
              {account && (
                <AddersList
                  selectedAdders={selectedAdders}
                  isLoading={isLoading}
                  programType={account.programType}
                />
              )}
              <Box
                direction={{
                  base: 'column',
                  tablet: 'row',
                }}
                alignItems={{
                  base: 'stretch',
                  tablet: 'flex-end',
                }}
                justifyContent={{
                  tablet: 'flex-end',
                }}
                childGap="sm"
                style={{ flexShrink: 0 }}
                padding="lg 0 0 0"
                borderColor="separator"
                borderWidth="xs 0 0 0"
              >
                <Button
                  as="a"
                  href={`/accounts/${accountId ? accountId : ''}`}
                  variant="secondary"
                  tone="neutral"
                  size="md"
                  isLoading={isSubmitting}
                >
                  Cancel
                </Button>
                <Button size="md" variant="primary" type="submit" isLoading={isSubmitting}>
                  Save Adders
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};
