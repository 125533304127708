import { AccountDetails, Locale, ApplicantType, DeepPartial, ExternalReferenceId, ExternalReferenceType } from 'types';
import { AccountFormSchema } from '../components/Account/AccountForm';

export const createAccountRequestBody = (account: AccountFormSchema): AccountDetails => {
  const programReferenceId = account.programReferenceId?.trim();
  const externalReferenceIds: ExternalReferenceId[] = programReferenceId ? [{ type: ExternalReferenceType.doe, id: programReferenceId }] : [];

  const applicants = [
    {
      type: ApplicantType.primary,
      firstName: account.primary.firstName.trim(),
      lastName: account.primary.lastName.trim(),
      middleName: account.primary.middleName?.trim() || undefined,
      phoneNumber: account.primary.phoneNumber,
      email: account.primary.email.trim(),
      address: {
        address1: account.applicantAddress1.trim(),
        address2: account?.applicantAddress2?.trim() || undefined,
        city: account.applicantCity.trim(),
        state: account.applicantState,
        zip: account.applicantZip.trim(),
      },
    },
  ];
  if (account.addAdditionalApplicant === true) {
    applicants.push({
      type: ApplicantType.secondary,
      firstName: account.secondary.firstName.trim(),
      lastName: account.secondary.lastName.trim(),
      middleName: account.secondary.middleName?.trim() || undefined,
      phoneNumber: account.secondary.phoneNumber,
      email: account.secondary.email.trim(),
      address: {
        address1: account.applicantAddress1.trim(),
        address2: account?.applicantAddress2?.trim() || undefined,
        city: account.applicantCity.trim(),
        state: account.applicantState,
        zip: account.applicantZip.trim(),
      },
    });
  }
  return {
    friendlyName: `${account.primary.firstName.trim()}-${account.primary.lastName.trim()}`,
    externalReference: account.reference?.trim() || undefined,
    externalReferenceIds,
    programType: account.programType?.trim() || undefined,
    address: {
      address1: account.address1.trim(),
      address2: account.address2?.trim() || undefined,
      city: account.city.trim(),
      state: account.state,
      zip: account.zip.trim(),
    },
    applicants,
    ...(account.lat &&
      account.lon && {
        coordinates: {
          lat: account.lat,
          lon: account.lon,
        },
      }),
    language: account.language || Locale.en,
    utility: {
      lseId: account.lseId,
      tariffId: account.tariffId || undefined,
      rate: account.rate || undefined,
    },
    salesRepName: account.salesRepName.trim(),
    salesRepLicenseNumber: account.salesRepLicenseNumber || undefined,
    salesRepEmail: account.salesRepEmail?.trim()?.toLocaleLowerCase() || undefined,
    salesRepPhoneNumber: account.salesRepPhoneNumber?.trim() || undefined,
  };
};

export const filterOutDisabledFields = (account: any, disabledFields: string[]): any => {
  const keys = Object.keys(account);
  const filteredKeys = keys.filter((key) => !disabledFields.includes(key));
  const filteredObject = filteredKeys.reduce((obj, key) => {
    return {
      ...obj,
      [key]: account[key as keyof AccountFormSchema],
    };
  }, {});
  return filteredObject;
};

export const createEditAccountRequestBody = (
  account: AccountFormSchema & { hasAdditionalApplicant: boolean },
): DeepPartial<AccountDetails> => {
  const friendlyName =
    account.primary.firstName && account.primary.lastName
      ? `${account.primary.firstName.trim()}-${account.primary.lastName.trim()}`
      : undefined;
  const externalReference = account.reference?.trim();
  
  const programReferenceId = account.programReferenceId?.trim();
  const externalReferenceIds: ExternalReferenceId[] = programReferenceId ? [{ type: ExternalReferenceType.doe, id: programReferenceId }] : [];

  const programType = account.programType?.trim();
  const address = {
    address1: account.address1?.trim(),
    address2: account.address2?.trim(),
    city: account.city?.trim(),
    state: account?.state,
    zip: account.zip?.trim(),
  };
  const applicantAddress = {
    address1: account.applicantAddress1?.trim(),
    address2: account?.applicantAddress2?.trim(),
    city: account.applicantCity?.trim(),
    state: account.applicantState,
    zip: account.applicantZip?.trim(),
  };
  const applicants = [
    {
      type: ApplicantType.primary,
      phoneNumber: account.primary.phoneNumber,
      email: account.primary.email?.trim()?.toLowerCase(),
      firstName: account.primary.firstName?.trim(),
      lastName: account.primary.lastName?.trim(),
      middleName: account.primary.middleName?.trim(),
      ...(Object.values(applicantAddress).some((val) => val !== undefined) && { address: applicantAddress }),
    },
  ];
  if (account.addAdditionalApplicant) {
    applicants.push({
      type: ApplicantType.secondary,
      phoneNumber: account.secondary.phoneNumber,
      email: account.secondary.email?.trim()?.toLowerCase(),
      firstName: account.secondary.firstName?.trim(),
      lastName: account.secondary.lastName?.trim(),
      middleName: account.secondary.middleName?.trim(),
      ...(Object.values(applicantAddress).some((val) => val !== undefined) && { address: applicantAddress }),
    });
  }
  const coordinates = {
    lat: account.lat,
    lon: account.lon,
  };
  const utility = {
    lseId: account.lseId,
    tariffId: account.tariffId || undefined,
    rate: account.rate || undefined,
  };
  return {
    friendlyName,
    externalReference,
    externalReferenceIds,
    programType,
    language: account.language,
    ...(Object.values(address).some((val) => val !== undefined) && { address }),
    applicants,
    ...(Object.values(coordinates).some((val) => val !== undefined) && { coordinates }),
    ...(Object.values(utility).some((val) => val !== undefined && val !== null) && { utility }),
    salesRepName: account.salesRepName?.trim(),
    salesRepLicenseNumber: account.salesRepLicenseNumber,
    salesRepEmail: account.salesRepEmail?.trim()?.toLocaleLowerCase(),
    salesRepPhoneNumber: account.salesRepPhoneNumber?.trim(),
  };
};
