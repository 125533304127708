import React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Box, Spinner, toast } from '@palmetto/palmetto-components';
import { Helmet } from 'react-helmet';
import PageHeader from '../../../PageHeader';
import {
  useGetOrganizationDetailsQuery,
  useGetOrganizationPaymentScheduleQuery,
  usePutOrganizationPaymentSettingMutation,
} from '../../../../services/organizations';
import { useGetOrganizationPaymentSchedulesQuery } from '../../../../services/vendorPaymentSchedules';
import { PaymentForm } from './PaymentForm';

export const EditPaymentSchedule = () => {
  const { alias } = useParams<{ alias: any }>();
  const { data: org, isLoading: isLoadingOrg } = useGetOrganizationDetailsQuery({ alias });
  const { data: paymentSetting, isLoading: isLoadingPaymentSchedule } = useGetOrganizationPaymentScheduleQuery({
    alias,
  });
  const { data: paymentScheduleOptions, isLoading: isLoadingPaymentScheduleOptions } =
    useGetOrganizationPaymentSchedulesQuery();

  const isLoading = isLoadingOrg || isLoadingPaymentSchedule || isLoadingPaymentScheduleOptions;

  const initialValues = {
    shouldHavePaymentSchedule: !paymentSetting || paymentSetting?.inherited ? 'no' : 'yes',
    paymentScheduleId: paymentSetting?.vendorPaymentScheduleId ?? undefined,
    legalEntity: !paymentSetting ? 'no' : 'yes',
    setUpBankAccount: !paymentSetting || paymentSetting?.inheritBankAccount ? 'no' : 'yes',
    netSuiteVendorId: paymentSetting?.netSuiteVendorId ?? undefined,
  };

  const [setPaymentSetting] = usePutOrganizationPaymentSettingMutation();

  const navigate = useNavigate();
  const handleSubmit = async (values: any, { setSubmitting }: any) => {
    try {
      const savePayload = {
        alias,
        paymentSettings: {
          vendorPaymentScheduleId: values.paymentScheduleId ? values.paymentScheduleId : null,
          netSuiteVendorId: values.netSuiteVendorId ? values.netSuiteVendorId : null,
          inheritBankAccount: values.setUpBankAccount === 'yes' ? false : true,
          inheritNetSuiteVendorId: values.legalEntity === 'yes' ? false : true,
          inheritVendorPaymentSchedule: values.paymentScheduleId ? false : true,
        },
      };

      const result = await setPaymentSetting(savePayload);
      if ('error' in result === false) {
        toast.success('Settings submitted successfully');
        navigate(`/settings/organizations/${org?.alias}/payments`);
      }
      if ('error' in result) {
        toast.error('Error submitting settings');
      }
    } catch (_e) {
      toast.error('Error submitting settings');
    }
    setSubmitting(false);
  };

  return (
    <>
      <Helmet>
        <title>Edit Organization</title>
      </Helmet>

      <Box
        childGap={{
          base: 'lg',
          desktop: '2xl',
          hd: '3xl',
        }}
        padding={{
          base: 'lg',
          desktop: '0',
        }}
        width="100%"
        maxWidth="5xl"
        style={{
          margin: '0 auto 0 0',
        }}
        display="block"
      >
        <PageHeader
          title={isLoading ? 'loading' : `${org?.name} Payment Schedule` || 'organization'}
          eyebrow={
            <Link to={`/settings/organizations/${org?.alias}/payments`}>Organizations \ {org?.name} \ Payments</Link>
          }
        />
        {!isLoading ? (
          <PaymentForm
            initialValues={initialValues}
            handleSubmit={handleSubmit}
            isLoading={isLoading}
            disabledFields={[]}
            organizationName={org?.alias}
            paymentScheduleOptions={paymentScheduleOptions}
          />
        ) : (
          <Spinner size="lg" />
        )}
      </Box>
    </>
  );
};
