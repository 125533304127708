import {
  Badge,
  Box,
  Button,
  Card,
  Column,
  Table,
  toast,
  useBreakpoint,
  useOpenClose,
} from '@palmetto/palmetto-components';
import { Helmet } from 'react-helmet';
import PageHeader from '../PageHeader';
import { useMemo } from 'react';
import { useCreateUtilityMutation, useLazyGetUtilitiesQuery } from '../../services/utilities';
import { AddMarketModal, MarketSchema } from './AddMarketModal';
import { isErrorWithData } from '../../services/helpers';
import { MarketFilter } from './MarketFilter';
import { MainContainer } from '../MainContainer';
import RequirePricingAdminUser from '../auth/RequirePricingAdminUser';

export function Markets() {
  const [trigger, { data: utilityData = [], isLoading: isUtilityDataLoading, isFetching: isUtilityDataFetching }] =
    useLazyGetUtilitiesQuery();
  const {
    isOpen: isAddMarketModalOpen,
    handleClose: handleAddMarketModalClose,
    handleOpen: handleOpenAddMarketModal,
  } = useOpenClose();
  const [createUtility] = useCreateUtilityMutation();
  const { isPhone } = useBreakpoint();
  const columnConfig = [
    {
      heading: 'Status',
      dataKey: 'status',
      width: 125,
      render: (_cell: any, row: any) => (
        <Badge
          variant={row.status === 'active' ? 'success' : row.status === 'inactive' ? 'warning' : 'danger'}
          message={row.status}
        />
      ),
    },
    {
      heading: 'State',
      dataKey: 'state',
      width: 125,
    },
    {
      heading: 'Utility',
      dataKey: 'name',
    },
    {
      heading: 'lseId',
      dataKey: 'lseId',
      width: 125,
    },
    {
      heading: 'Contract Type',
      dataKey: 'contractType',
      width: 125,
      render: (_cell: any, row: any) => (
        <Box>
          {row.contractType === 'ppa'
            ? row.contractType.toUpperCase()
            : row.contractType.charAt(0).toUpperCase() + row.contractType.slice(1)}
        </Box>
      ),
    },
  ];
  const sortedUtilities = useMemo(() => {
    if (isUtilityDataLoading || isUtilityDataFetching) return [];
    if (utilityData === null) return [];
    if (utilityData && utilityData?.length === 0) return [];
    return [...(utilityData as any[])].sort((a, b) => {
      if (a['state'] === b['state']) {
        return a['name'] > b['name'] ? 1 : -1;
      }
      return a['state'] > b['state'] ? 1 : -1;
    });
  }, [utilityData, isUtilityDataLoading, isUtilityDataFetching]);
  const handleSubmit = async (values: MarketSchema) => {
    try {
      // an active field will be added to the form later
      await createUtility({ marketData: { ...values, status: 'active' } }).unwrap();
      toast.success('Market created');
    } catch (e) {
      if (isErrorWithData(e)) {
        toast.error(e.data.message);
      } else {
        console.error(e);
        toast.error('Error creating market');
      }
    }
  };
  return (
    <>
      <AddMarketModal
        isOpen={isAddMarketModalOpen}
        handleClose={handleAddMarketModalClose}
        handleSubmit={handleSubmit}
      />
      <Helmet>
        <title>Markets</title>
      </Helmet>
      <MainContainer>
        <PageHeader title="Markets"></PageHeader>
        <Card padding="sm">
          <Card.Header>
            <Box direction="row" justifyContent="space-between">
              <Box>States</Box>
              <RequirePricingAdminUser>
                <Button
                  as="button"
                  variant="primary"
                  iconPrefix="add"
                  size={{ base: 'sm', desktop: 'md' }}
                  aria-label="create market"
                  onClick={handleOpenAddMarketModal}
                >
                  {!isPhone && 'Market'}
                </Button>
              </RequirePricingAdminUser>
            </Box>
          </Card.Header>
          <MarketFilter trigger={trigger} />
          {sortedUtilities.length > 0 ? (
            <Table
              rowKey="id"
              columns={columnConfig as Column[]}
              rows={sortedUtilities || []}
              isLoading={isUtilityDataLoading || isUtilityDataFetching}
              isScrollable={{
                x: true,
              }}
            />
          ) : (
            <Box padding="xl" textAlign="center">
              No markets found
            </Box>
          )}
        </Card>
      </MainContainer>
    </>
  );
}
