import { Link, useParams } from 'react-router-dom';
import { isPalmettoFinanceUser } from '../auth/RequirePalmettoFinanceUser';
import { useGetAccountQuery, useGetAccountCedDetailsQuery } from '../../services/accounts';
import { useGetCurrentDesignQuery } from '../../services/systemDesign';
import { PropertyListBox } from '../PropertyListBox/PropertyListBox';
// import { GlideECEligibility, energyCommunityMap } from 'types';
// import { FontColor, IconName } from '@palmetto/palmetto-components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useGetAddersQuery } from '../../services/adders';
import { useGetQuotesQuery } from '../../services/quotes';
import { QuoteStatus } from '../../types/Quotes';
import RequirePermissions from '../auth/requirePermissions';
import { Button, useOpenClose } from '@palmetto/palmetto-components';
import { AccountPaymentMethod } from './modals/AccountPaymentMethod';
import { useGetPaymentMethodsByAccountIdQuery } from '@/services/paymentMethods';
import {
  ExternalReferenceType,
  FinancialAccountPaymentMethodType,
  PaymentMethod,
  ProgramType,
  ProgramTypeLabels,
} from 'types';
import { useAuth } from '../auth/authProvider';
import { convertIsoToDateMed } from '../Underwriting/CreditApplications';
import { currencyFormatter } from '@/helpers/currencyFormatter';
import { CedProgramNameLabels, CedProgramTypes } from 'types';
import { formatPhoneNumber } from '@/helpers/formatPhoneNumber';

export function AccountDetails() {
  const { id } = useParams<{ id: any }>();
  const { data: item, isLoading }: any = useGetAccountQuery(id);
  const { data: paymentMethod = [] } = useGetPaymentMethodsByAccountIdQuery({ id });

  const { data: cedDetails }: any = useGetAccountCedDetailsQuery(id);
  const { data: systemDesign }: any = useGetCurrentDesignQuery(id);
  const { data: quotes } = useGetQuotesQuery(id);
  const activeQuote = quotes?.find((quote) => quote.status === QuoteStatus.active);
  const { data: addersData } = useGetAddersQuery(id);
  const { isCosignerEnabled } = useFlags();
  const { isOpen: isModalOpen, handleOpen: openModal, handleClose: closeModal } = useOpenClose();

  const programReferenceId = item?.externalReferenceIds?.find(
    (ref: any) => ref.type === ExternalReferenceType.doe,
  )?.id;
  const primaryAccountApplicant = item?.applicants.find((applicant: any) => applicant.type === 'primary');
  const secondaryAccountApplicant = item?.applicants.find((applicant: any) => applicant.type === 'secondary');
  const showAdditionalApplicant = isCosignerEnabled && secondaryAccountApplicant !== undefined;
  const {
    address1: primeAddress1,
    address2: primeAddress2 = '',
    city: primeCity,
    state: primeState,
    zip: primeZip,
  } = primaryAccountApplicant?.address || {};
  const { address1, address2 = '', city, state, zip } = item?.address || {};
  // const energyCommunity = item?.itcAdderQualifications?.ecEligibilityCriteria
  //   ? energyCommunityMap[item.itcAdderQualifications?.ecEligibilityCriteria as GlideECEligibility].map(
  //       (v: string) => v,
  //     )
  //   : false;
  const { claims } = useAuth();
  const accountDetailsProperties = {
    heading: 'Account Details',
    editPath: `/accounts/${id}/edit`,
    values: [
      { label: 'Account Id', value: id },
      { label: 'External Reference', value: item?.externalReference },
      { label: 'Program Type', value: ProgramTypeLabels[item?.programType as ProgramType] },
      { label: 'Program Reference ID', value: programReferenceId },
      { label: 'Language', value: item?.language },
      {
        label: 'Primary Contact',
        value: [
          `${primaryAccountApplicant?.firstName} ${primaryAccountApplicant?.lastName}`,
          primaryAccountApplicant?.email,
          primaryAccountApplicant?.phoneNumber ? formatPhoneNumber(primaryAccountApplicant.phoneNumber) : '',
        ],
      },
      ...(showAdditionalApplicant
        ? [
            {
              label: 'Additional Contact',
              value: [
                `${secondaryAccountApplicant?.firstName} ${secondaryAccountApplicant?.lastName}`,
                secondaryAccountApplicant?.email,
                secondaryAccountApplicant?.phoneNumber
                  ? formatPhoneNumber(secondaryAccountApplicant.phoneNumber)
                  : '',
              ],
            },
          ]
        : []),
      {
        label: 'Mailing Address',
        value: `${primeAddress1} ${primeAddress2} ${primeCity}, ${primeState} ${primeZip}`,
      },
      { label: 'Property Address', value: `${address1} ${address2} ${city}, ${state} ${zip}` },
      // {
      //   label: 'Energy Community',
      //   value: energyCommunity || 'No',
      //   icon: 'nature' as IconName,
      //   iconColor: energyCommunity ? ('primary' as FontColor) : ('grey-300' as FontColor),
      // },
      { label: 'Utility', value: item?.utility?.utilityName },
      { label: 'Status', value: item?.status },
    ],
  };

  const supportDetailsProperties = {
    heading: 'Account Support',
    values: [
      { label: 'Payment Provider', value: item?.inboundPayments ? item.inboundPayments.provider : 'Stripe' },
      {
        label: 'Provider ID',
        value: item?.inboundPayments ? (
          <Link to={item.inboundPayments.providerUrl}>{item.inboundPayments.providerId}</Link>
        ) : (
          item?.stripeCustomerId
        ),
      },
      {
        label: 'Payment Method',
        value:
          paymentMethod.length > 0 && paymentMethod[0].bankAccount ? (
            <Button variant="tertiary" tone="neutral" size="xs" onClick={() => openModal()}>
              {FinancialAccountPaymentMethodType.bankAccount}
            </Button>
          ) : paymentMethod.length > 0 && paymentMethod[0].card ? (
            <Button variant="tertiary" tone="neutral" size="xs" onClick={() => {}}>
              {FinancialAccountPaymentMethodType.card}
            </Button>
          ) : (
            <Button variant="primary" size="xs" onClick={() => openModal()}>
              Add
            </Button>
          ),
      },
    ],
  };

  const pvSystemProperties = {
    heading: 'PV System',
    editPath: `/accounts/${id}/pv-system`,
    values: [
      { label: 'System Size (kW)', value: systemDesign?.systemSizeKw ?? item?.systemDetails?.systemSizeKw ?? '--' },
      {
        label: 'Year-1 Production (kWh)',
        value:
          systemDesign?.systemFirstYearProductionKwh?.toFixed(0) ??
          item?.systemDetails?.systemFirstYearProductionKwh?.toFixed(0) ??
          '--',
      },
      {
        label: 'Panel Count',
        value:
          systemDesign?.totalPanelCount > 0
            ? systemDesign?.totalPanelCount
            : item?.systemDetails?.panelCount ?? '--',
      },
      {
        label: 'Panel Manufacturer',
        value: systemDesign?.panelManufacturer ?? item?.systemDetails?.panelManufacturer ?? '--',
      },
      { label: 'Panel Model', value: systemDesign?.panelModel ?? item?.systemDetails?.panelModel ?? '--' },
      {
        label: 'Inverter Manufacturer',
        value: systemDesign?.inverters?.[0]?.manufacturer ?? item?.systemDetails?.inverterManufacturer ?? '--',
      },
      { label: 'Mounting Type', value: systemDesign?.mountingType ?? item?.systemDetails?.mountingType ?? '--' },
      {
        label: 'Hardware Manufacturer',
        value: systemDesign?.mountingManufacturer ?? item?.systemDetails?.mountingManufacturer ?? '--',
      },
    ],
    shouldFold: true,
  };

  const addersMap = addersData?.adders?.reduce((acc: any, adder: any) => {
    if (activeQuote?.adders?.includes(adder.type)) {
      acc[adder.type] = adder;
    }
    return acc;
  }, {});
  const addersProperties = {
    heading: 'Adders',
    editPath: `/accounts/${id}/adders`,
    values: [
      ...(addersMap?.arbitrageBattery
        ? [
            {
              label: 'Arbitrage Battery',
              value: [
                `${addersMap?.arbitrageBattery?.manufacturer ?? ''} ${addersMap?.arbitrageBattery?.model ?? ''}`,
                addersMap?.arbitrageBattery?.quantity ? `x${addersMap.arbitrageBattery.quantity}` : null,
              ],
            },
          ]
        : []),
      ...(addersMap?.backupBattery
        ? [
            {
              label: 'Backup Battery',
              value: [
                `${addersMap?.backupBattery?.manufacturer ?? ''} ${addersMap?.backupBattery?.model ?? ''}`,
                addersMap?.backupBattery?.quantity ? `x${addersMap.backupBattery.quantity}` : null,
              ],
            },
          ]
        : []),
      ...(addersMap?.electricalUpgrade ? [{ label: 'Electrical Upgrade', value: 'Included' }] : []),
      ...(!activeQuote?.adders?.length ? [{ label: 'None', value: '' }] : []),
    ],
  };

  const salesRepProperties = {
    heading: 'Sales Rep',
    editPath: `/accounts/${id}/edit`,
    values: [
      { label: 'Name', value: item?.salesRepName },
      { label: 'License', value: item?.salesRepLicenseNumber },
      { label: 'Email', value: item?.salesRepEmail },
      { label: 'Phone', value: item?.salesRepPhoneNumber },
    ],
  };

  const partnerOrgProperties = {
    heading: 'Partner Org',
    values: [
      { label: 'Name', value: item?.organizationId?.toUpperCase() },
      { label: 'Licensed Partner Org', value: item?.licensedOrganizationId?.toUpperCase() || '' },
    ],
  };

  const cedSplitPayProperties = {
    heading: 'Greentech Direct Pay',
    values: [
      { label: 'Greentech Direct Pay Enabled', value: cedDetails?.cedSplitPayEnabled ? 'Yes' : 'No' },
      { label: 'Greentech Project Id', value: cedDetails?.cedProjectId || '' },
      ...(isPalmettoFinanceUser(claims)
        ? [
            {
              label: 'Greentech Program Name',
              value: cedDetails?.programName ? CedProgramNameLabels[cedDetails?.programName as CedProgramTypes] : '',
            },
            {
              label: 'Parallel Payment Amount',
              value: currencyFormatter.format(cedDetails?.parallelPaymentAmount ?? 0),
            },
            { label: 'Parallel Payment Status', value: cedDetails?.parallelPaymentStatus || '' },
            {
              label: 'Ship Confirmation Date',
              value: cedDetails?.shipConfirmationDate
                ? convertIsoToDateMed(cedDetails?.shipConfirmationDate, { zone: 'UTC' })
                : '',
            },
          ]
        : []),
    ],
  };

  return (
    <>
      {!isLoading && item && cedDetails && (
        <>
          <PropertyListBox {...accountDetailsProperties} />
          <RequirePermissions permissions={['lightReachSupport']}>
            <AccountPaymentMethod isModalOpen={isModalOpen} closeModal={closeModal} paymentMethods={paymentMethod} />
            <PropertyListBox {...supportDetailsProperties} />
          </RequirePermissions>
          <PropertyListBox {...pvSystemProperties} />
          <PropertyListBox {...addersProperties} />
          <PropertyListBox {...salesRepProperties} />
          <PropertyListBox {...partnerOrgProperties} />
          {item?.ced?.cedSplitPayEnabled && <PropertyListBox {...cedSplitPayProperties} />}
        </>
      )}
    </>
  );
}
