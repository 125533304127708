import { AdderDetails } from 'types';
import { AdderValues } from '../../components/Adders/AddersTab';
import { AdderType } from '../../types/Quotes';

export const mapValuesToDocumentAdders = (values: AdderValues) => {
  return Object.entries(values).reduce((acc, [adderType, value]) => {
    if (value) {
      acc.push({
        type: adderType as AdderType,
        ...value,
      });
    }
    return acc;
  }, [] as any[]);
};

export const mapAddersValuesFromDocument = (adders?: AdderDetails[], selectedAdders?: AdderType[]): AdderValues => {
  return (
    selectedAdders?.reduce((acc, selectedAdder) => {
      const adder = adders?.find((adder) => adder.type === selectedAdder);
      if (adder) {
        const { type, ...rest } = adder;
        acc[type] = rest;
      } else {
        acc[selectedAdder] = { manufacturer: '', model: '', quantity: 0 };
      }
      return acc;
    }, {} as AdderValues) ?? ({} as AdderValues)
  );
};


const adderTypeToLabel: Partial<Record<AdderType, string>> = {
  [AdderType.arbitrageBattery]: 'Arbitrage Battery',
  [AdderType.backupBattery]: 'Backup Battery (cost)',
  [AdderType.backupBatteryPriceCap]: 'Backup Battery Price Cap',
  [AdderType.backupBatteryKwh]: 'Backup Battery ($/kWh)',
  [AdderType.electricalUpgrade]: 'Electrical Upgrade',
};

export const getAdderTypeLabel = (type: AdderType) => {
  return adderTypeToLabel[type]
}