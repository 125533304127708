import { Box, Button, FormikSelectInputNative, FormikTextInput, Modal } from '@palmetto/palmetto-components';
import { useMemo } from 'react';
import { Form } from 'react-router-dom';
import * as yup from 'yup';
import { getStateOptions } from '../../helpers/getStateOptions';
import { Field, Formik, FormikValues } from 'formik';

const marketSchema = yup.object().shape({
  state: yup.string().required('State is a required field.'),
  name: yup.string().required('Utility Name is a required field.'),
  lseId: yup.number().required('LSE ID is a required field.'),
  contractType: yup.string().required('Contract Type is a required field.'),
});
export type MarketSchema = yup.InferType<typeof marketSchema>;
export const AddMarketModal = ({
  isOpen,
  handleClose,
  handleSubmit,
}: {
  isOpen: boolean;
  handleClose: () => void;
  handleSubmit: (values: any) => void;
}) => {
  const initialValues: FormikValues = useMemo(() => ({ name: '', state: '', contractType: '', lseId: '' }), []);
  return (
    <Formik
      validationSchema={marketSchema}
      initialValues={initialValues}
      validateOnChange={false}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      {({ isSubmitting, resetForm, submitForm }) => (
        <Form noValidate>
          <Modal
            isOpen={isOpen}
            maxWidth="4xl"
            ariaLabelledBy="addMarketHeader"
            onDismiss={() => {
              resetForm();
              handleClose();
            }}
          >
            <Modal.Header
              id="addMarketHeader"
              title="Add Market"
              onDismiss={() => {
                resetForm();
                handleClose();
              }}
            />
            <Modal.Body background="secondary" childGap="lg">
              <Box childGap={{ base: 'lg', desktop: 'xl' }}>
                <Field
                  type="text"
                  label="State"
                  name="state"
                  id="state"
                  options={getStateOptions(true)}
                  component={FormikSelectInputNative}
                  isRequired={true}
                />

                <Field
                  type="text"
                  label="Utility Name"
                  name="name"
                  id="name"
                  component={FormikTextInput}
                  isRequired={true}
                  placeholder="eg. Pacific Gas & Electric"
                />

                <Field
                  maxLength={5}
                  type="number"
                  label="Load Serving Entity (LSE) ID"
                  name="lseId"
                  id="lseId"
                  component={FormikTextInput}
                  isRequired={true}
                  placeholder="eg. 734"
                />

                <Field
                  type="text"
                  label="Contract Type"
                  name="contractType"
                  id="contractType"
                  options={[
                    { value: 'ppa', label: 'PPA' },
                    { value: 'lease', label: 'Lease' },
                  ]}
                  component={FormikSelectInputNative}
                  isRequired={true}
                />
              </Box>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                tone="neutral"
                isLoading={isSubmitting}
                isDisabled={isSubmitting}
                onClick={() => {
                  resetForm();
                  handleClose();
                }}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                isLoading={isSubmitting}
                isDisabled={isSubmitting}
                type="submit"
                onClick={submitForm}
              >
                Add Market
              </Button>
            </Modal.Footer>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};
