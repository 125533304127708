import { useState } from 'react';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { Box, Button, FormikSelectInputNative, FormikTextInput, Toggle } from '@palmetto/palmetto-components';
import FormSection from '../Forms/FormSection';
import * as yup from 'yup';

import { AddressFormFields } from '../AddressFormFields/AddressFormFields';
import { UtilityNameSelectInput } from '../Account/UtilityNameSelectInput';
import { useParams } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ApplicantInformation } from './ApplicantInformation';
import { Locale, ProgramType, ProgramTypeLabels } from 'types';

const applicantSchema = yup.object().shape({
  firstName: yup.string().required('First Name is a required field.'),
  middleName: yup.string(),
  lastName: yup.string().required('Last Name is a required field.'),
  phoneNumber: yup.string().required('Phone Number is a required field.'),
  email: yup.string().required('Email is a required field.').email('Email is not a valid email.'),
});

const accountSchema = yup.object().shape({
  addAdditionalApplicant: yup.boolean().optional().nullable(),
  address1: yup.string().required('Address 1 is a required field.'),
  address2: yup.string(),
  city: yup.string().required('City is a required field.'),
  state: yup.string().required('State is a required field.'),
  zip: yup.string().required('Zip Code is a required field.'),
  lat: yup.number(),
  lon: yup.number(),
  // applicant info
  primary: applicantSchema,
  secondary: yup.object().when('addAdditionalApplicant', {
    is: true,
    then: () => applicantSchema,
  }),
  applicantAddress1: yup.string().required('Address 1 is a required field.'),
  applicantAddress2: yup.string(),
  applicantCity: yup.string().required('City is a required field.'),
  applicantState: yup.string().required('State is a required field.'),
  applicantZip: yup.string().required('Zip Code is a required field.'),
  // sales rep info
  salesRepName: yup.string().required('Sales Representative Name is a required field.'),
  salesRepLicenseNumber: yup.string(),
  salesRepEmail: yup.string().email('Sales Rep Email is not a valid email.'),
  salesRepPhoneNumber: yup.string(),
  reference: yup.string(),
  programReferenceId: yup.string(),
  programType: yup.string(),
  language: yup.string().oneOf(Object.values(Locale)),
  // utility info
  lseId: yup.number().required('Utility Name is a required field.'),
  tariffId: yup.number().nullable(),
  rate: yup.number().nullable(),
});

export type AccountFormSchema = yup.InferType<typeof accountSchema> & {
  secondary?: yup.InferType<typeof applicantSchema>;
};
type SubmitHandler = (values: any, formikHelpers: FormikHelpers<any>) => void | Promise<any>;

export const AccountForm = ({
  initialValues,
  handleSubmit,
  disabledFields = [],
  hasAdditionalApplicantData,
  programTypes = [],
}: {
  initialValues: any;
  handleSubmit: SubmitHandler;
  disabledFields?: string[] | undefined;
  hasAdditionalApplicantData: boolean;
  programTypes: ProgramType[];
}) => {
  const { id } = useParams<{ id: any }>();
  const { isCosignerEnabled, hvacProgramType = false, doePrProgramType = false } = useFlags();
  const [showAdditionalApplicant, setShowAdditionalApplicant] = useState(hasAdditionalApplicantData);

  const availableProgramTypes = programTypes.filter((t) => {
    switch (t) {
      case ProgramType.hvac:
        return hvacProgramType;
      case ProgramType.doePr:
        return doePrProgramType;
      default:
        return true;
    }
  });

  let programTypeDisabled = false;
  const shownProgramTypes = availableProgramTypes.map((t) => ({ label: ProgramTypeLabels[t], value: t }));
  if (shownProgramTypes.length === 1) {
    initialValues.programType = shownProgramTypes[0].value;
    programTypeDisabled = true;
  }

  return (
    <>
      <Formik
        validationSchema={accountSchema}
        initialValues={initialValues}
        validateOnChange={false}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({ isSubmitting, setFieldValue }) => (
          <Form noValidate>
            <FormSection title="Account">
              <Box
                direction={{
                  base: 'column',
                  tablet: 'row',
                }}
                childGap={{ base: 'lg', desktop: 'xl' }}
              >
                <Field
                  label="Language"
                  name="language"
                  id="language"
                  options={Object.values(Locale).map((l) => ({ label: l, value: l }))}
                  component={FormikSelectInputNative}
                  autoComplete="off"
                  isDisabled={isSubmitting || disabledFields.includes('language')}
                />
                <Field
                  type="text"
                  label="Reference"
                  name="reference"
                  id="reference"
                  component={FormikTextInput}
                  autoComplete="off"
                  isDisabled={isSubmitting || disabledFields.includes('reference')}
                />
              </Box>
              <Box
                direction={{
                  base: 'column',
                  tablet: 'row',
                }}
                childGap={{ base: 'lg', desktop: 'xl' }}
              >
                <Field
                  type="text"
                  label="Program Reference ID"
                  name="programReferenceId"
                  id="programReferenceId"
                  component={FormikTextInput}
                  autoComplete="off"
                  isDisabled={isSubmitting || disabledFields.includes('programReferenceId')}
                />
                <Field
                  label="Program Type"
                  name="programType"
                  id="programType"
                  options={shownProgramTypes}
                  component={FormikSelectInputNative}
                  autoComplete="off"
                  isDisabled={programTypeDisabled || isSubmitting || disabledFields.includes('programType')}
                />
              </Box>
            </FormSection>
            <FormSection title="Property Address">
              <AddressFormFields
                isSubmitting={isSubmitting}
                placeHolderId="propertyAddress"
                disabledFields={disabledFields}
                showLatLon
                showAddress2
                includePR={programTypes.includes(ProgramType.doePr)}
              />
            </FormSection>
            <FormSection title="Applicant">
              <Box childGap={{ base: 'lg', desktop: 'xl' }}>
                <ApplicantInformation isSubmitting={isSubmitting} disabledFields={disabledFields} prefix="primary" />
              </Box>
              <Box childGap={{ base: 'lg', desktop: 'xl' }}>
                <AddressFormFields
                  isSubmitting={isSubmitting}
                  prefix="applicant"
                  placeHolderId="primaryApplicantAddress"
                  showAddress2
                  disabledFields={disabledFields}
                  includePR={programTypes.includes(ProgramType.doePr)}
                />
              </Box>
            </FormSection>
            {isCosignerEnabled && (
              <FormSection
                title="Additional Applicant"
                description="If more than one person will be applying for credit approval. This can be done later. At least one applicant must be on property title"
              >
                {!hasAdditionalApplicantData && (
                  <Toggle
                    id="addAdditionalApplicant"
                    label="Additional Applicant"
                    isChecked={showAdditionalApplicant}
                    onChange={() => {
                      setShowAdditionalApplicant(!showAdditionalApplicant);
                      setFieldValue('addAdditionalApplicant', !showAdditionalApplicant);
                    }}
                  />
                )}
                {showAdditionalApplicant && (
                  <ApplicantInformation
                    isSubmitting={isSubmitting}
                    disabledFields={disabledFields}
                    prefix="secondary"
                  />
                )}
              </FormSection>
            )}
            <FormSection title="Sales Representative">
              <Box
                direction={{
                  base: 'column',
                  tablet: 'row',
                }}
                childGap={{ base: 'lg', desktop: 'xl' }}
              >
                <Field
                  type="text"
                  label="Sales Rep Name"
                  name="salesRepName"
                  id="salesRepName"
                  component={FormikTextInput}
                  isRequired
                  autoComplete="off"
                  isDisabled={isSubmitting || disabledFields.includes('salesRepName')}
                />
                <Field
                  type="text"
                  label="License Number"
                  name="salesRepLicenseNumber"
                  id="salesRepLicenseNumber"
                  component={FormikTextInput}
                  autoComplete="off"
                  isDisabled={isSubmitting || disabledFields.includes('salesRepLicenseNumber')}
                />
              </Box>
              <Box
                direction={{
                  base: 'column',
                  tablet: 'row',
                }}
                childGap={{ base: 'lg', desktop: 'xl' }}
              >
                <Field
                  type="email"
                  label="Sales Rep Email"
                  name="salesRepEmail"
                  id="salesRepEmail"
                  component={FormikTextInput}
                  autoComplete="off"
                  isDisabled={isSubmitting || disabledFields.includes('salesRepEmail')}
                />
                <Field
                  type="text"
                  label="Sales Rep Phone Number"
                  name="salesRepPhoneNumber"
                  id="salesRepPhoneNumber"
                  component={FormikTextInput}
                  inputMask="phone"
                  autoComplete="off"
                  isDisabled={isSubmitting || disabledFields.includes('salesRepPhoneNumber')}
                />
              </Box>
            </FormSection>
            <FormSection title="Utility Information">
              <Box
                direction={{
                  base: 'column',
                  tablet: 'row',
                }}
                childGap={{ base: 'lg', desktop: 'xl' }}
              >
                <UtilityNameSelectInput isDisabled={disabledFields.includes('lseId')} />
                <Field
                  type="number"
                  label="Tariff Id"
                  name="tariffId"
                  id="tariffId"
                  component={FormikTextInput}
                  autoComplete="off"
                  isDisabled={isSubmitting || disabledFields.includes('tariffId')}
                />
                <Field
                  type="number"
                  label="Rate"
                  name="rate"
                  id="rate"
                  component={FormikTextInput}
                  autoComplete="off"
                  isDisabled={isSubmitting || disabledFields.includes('rate')}
                />
              </Box>
            </FormSection>
            <Box
              direction={{
                base: 'column',
                tablet: 'row',
              }}
              alignItems={{
                base: 'stretch',
                tablet: 'flex-end',
              }}
              justifyContent={{
                tablet: 'flex-end',
              }}
              childGap="sm"
              style={{ flexShrink: 0 }}
              padding="lg 0 0 0"
            >
              <Button
                as="a"
                href={`/accounts/${id ? id : ''}`}
                variant="secondary"
                tone="neutral"
                size="md"
                isLoading={isSubmitting}
              >
                Cancel
              </Button>
              <Button size="md" variant="primary" type="submit" isLoading={isSubmitting}>
                Submit
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};
