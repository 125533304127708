import { VendorPaymentSchedule } from 'types';
import { api } from './api';

export const vendorPaymentSchedulesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getOrganizationPaymentSchedules: build.query<VendorPaymentSchedule[], void>({
      query: () => ({ url: `vendor-payment-schedules` }),
      providesTags: () => {
        return [{ type: 'VendorPaymentSchedules', id: 'LIST' }];
      },
    }),
  }),
});

export const { useGetOrganizationPaymentSchedulesQuery } = vendorPaymentSchedulesApi;
