import { Box } from '@palmetto/palmetto-components';
import { useGetAddersQuery } from '../../services/adders';
import { useParams } from 'react-router-dom';
import { useGetCurrentDesignQuery } from '../../services/systemDesign';
import { InverterAndAddersList } from './InverterAndAddersList';
import FormSection from '../Forms/FormSection';
import { SkeletonBox } from '../SkeletonBox';
import { AdderDetails, Quote, SystemComponent } from 'types';
import { useGetQuotesQuery } from '@/services/quotes';
import { QuoteStatus } from '@/types/Quotes';

export const InverterAndAddersInformation = () => {
  const { id } = useParams<{ id: any }>();
  const { data: adders } = useGetAddersQuery(id);
  const { data: systemDesign, isLoading: isSystemLoading } = useGetCurrentDesignQuery(id);

  const { data: quotes } = useGetQuotesQuery(id);
  const activeQuote = quotes?.find((quote) => quote.status === QuoteStatus.active);
  const hasAdders = activeQuote?.adders?.length > 0;

  return (
    <Box>
      <FormSection title="Inverter Information" className={(adders?.adders.length ?? 0) > 0 ? 'border-out' : ''}>
        {!systemDesign && isSystemLoading ? (
          <FormSection title="Loading...">
            <SkeletonBox height="25px" width="100" />
          </FormSection>
        ) : (
          systemDesign?.inverters?.map((inverter: SystemComponent, index: number) => (
            <InverterAndAddersList key={index} position={index} inverter={inverter} />
          ))
        )}
      </FormSection>
      {hasAdders && (adders?.adders?.length ?? 0) > 0 && (
        <FormSection title="Adders Information">
          {adders?.adders?.map((adder: AdderDetails, index: number) => (
            <InverterAndAddersList key={index} position={index} adders={adder} />
          ))}
        </FormSection>
      )}
    </Box>
  );
};
