import { OrganizationPricingTabType } from "types";
import { Mappings } from "./Mappings";
import { EnergyCommunity } from "./EnergyCommunity";
import { Holdbacks } from "./Holdbacks";
import { PpwModifiers } from "./PpwModifiers";

export const SectionOption = {
    [OrganizationPricingTabType.mappings]: <Mappings />,
    [OrganizationPricingTabType.energyCommunity]: <EnergyCommunity />,
    [OrganizationPricingTabType.ppwModifiers]: <PpwModifiers />,
    [OrganizationPricingTabType.holdbacks]: <Holdbacks />,
}