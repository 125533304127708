import { Box, Button, Card, Column, useBreakpoint } from '@palmetto/palmetto-components';
import { useNavigate, useParams } from 'react-router-dom';
import { ProgramType } from 'types';
import './mappings.css';
import { useFlags } from 'launchdarkly-react-client-sdk';
import RequirePalmettoFinanceUser from '../../auth/RequirePalmettoFinanceUser';
import RequirePermissions from '../../auth/requirePermissions';
import { FilteredMappingsList } from './FilteredMappingsList';
import { mappingsColumnConfig } from './MappingTables';

export const Mappings = () => {
  const { isPhone } = useBreakpoint();
  const navigate = useNavigate();
  const { canCreatePriceSheetMappings } = useFlags();
  const { programType = ProgramType.solar } = useParams<{ programType: ProgramType }>();

  return (
    <Card padding="sm">
      <Card.Header>
        <Box direction="row" justifyContent="space-between">
          <Box fontSize="md" fontWeight="medium">
            Org-Price Mappings
          </Box>
          {canCreatePriceSheetMappings && (
            <Box direction="row" gap="sm">
              <RequirePalmettoFinanceUser>
                <RequirePermissions permissions={['pricingAdmin']}>
                  {programType === ProgramType.solar && (
                    <Button
                      as="button"
                      variant="secondary"
                      tone="neutral"
                      size={{ base: 'sm', desktop: 'md' }}
                      aria-label="create mapping"
                      onClick={() =>
                        navigate(
                          `/admin/pricing/${programType}/mappings/bulk-edit?advancedFilters=${encodeURIComponent('[{"id": "status", "operation": "Equal", "selectedValues": ["active"]}]')}`,
                        )
                      }
                    >
                      Bulk Edit
                    </Button>
                  )}
                  <Button
                    as="button"
                    variant="primary"
                    iconPrefix="add"
                    size={{ base: 'sm', desktop: 'md' }}
                    aria-label="create mapping"
                    onClick={() => navigate(`/admin/pricing/${programType}/mappings/create`)}
                  >
                    {!isPhone && 'Mapping'}
                  </Button>
                </RequirePermissions>
              </RequirePalmettoFinanceUser>
            </Box>
          )}
        </Box>
      </Card.Header>
      <FilteredMappingsList
        columnConfig={
          [
            ...mappingsColumnConfig.baseStart,
            ...mappingsColumnConfig[programType],
            ...mappingsColumnConfig.baseEnd,
          ] as Column[]
        }
      />
    </Card>
  );
};
